import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors } from 'millbrook-core';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { MasterPropertyOwnershipType } from '../../masterLists/masterProperties/masterProperties.slice';
import { mapContractPropertyOwnershipTypesFromServer } from '../fetch-mappers';
import { clearContractState } from '../overview/overview.slice';

/* types */
export interface ContractPropertyOwnershipType extends MasterPropertyOwnershipType {
    propertyOwnershipTypeId: ApiId; // this is the master id
    contractId: ApiId;
    masterName: string;
    onContract: boolean;
    modified?: boolean;
}

export type ContractPropertyOwnershipTypeId = ApiId;

export type ContractPropertyOwnershipTypesRequest = ContractPropertyOwnershipType;
export type ContractPropertyOwnershipTypesResponse = IApiResponse<ContractPropertyOwnershipType[]>;
export type ContractPropertyOwnershipTypeResponse = IApiResponse<ContractPropertyOwnershipType>;


/* state */
export interface ContractPropertyOwnershipTypesState {
    contractPropertyOwnershipTypes: ContractPropertyOwnershipType[];
}

const initialState: ContractPropertyOwnershipTypesState = { contractPropertyOwnershipTypes: [] };

/* slice */
const contractPropertyOwnershipTypesSlice = createSlice({
    name: 'serviceUserAlerts',
    initialState,
    reducers: {
        setContractPropertyOwnershipTypes(state, action: PayloadAction<ContractPropertyOwnershipType[]>) {
            state.contractPropertyOwnershipTypes = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearContractState, () => {
            return initialState;
        });
    }
});

/* thunks */
export const fetchContractPropertyOwnershipTypes =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            // HACK for some reason on fetch from POST, PUT, DELETE some items were being duplicated, this stops from happening
            // requires investigation
            await dispatch(setContractPropertyOwnershipTypes([]));

            return getItems<ContractPropertyOwnershipTypesResponse>(
                `${ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_PROPERTY_OWNERSHIP_TYPES}?contractId=${contractId}&includeMasterList=true`
            ).then((response) => {
                const mappedResults = mapContractPropertyOwnershipTypesFromServer(response.result || [], contractId);
                dispatch(setContractPropertyOwnershipTypes(mappedResults));

                return mappedResults;
            });
        };

export const createContractPropertyOwnershipType =
    (contractPropertyOwnershipType: ContractPropertyOwnershipType): AppThunk =>
        async (dispatch) => {
            return postItems<ContractPropertyOwnershipTypesRequest, ContractPropertyOwnershipTypeResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_PROPERTY_OWNERSHIP_TYPES,
                contractPropertyOwnershipType
            ).then(
                () => {
                    dispatch<any>(fetchContractPropertyOwnershipTypes(contractPropertyOwnershipType.contractId));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateContractPropertyOwnershipType =
    (contractPropertyOwnershipType: ContractPropertyOwnershipType): AppThunk =>
        async (dispatch) => {
            return putItem<ContractPropertyOwnershipTypesRequest, ContractPropertyOwnershipTypeResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_PROPERTY_OWNERSHIP_TYPES,
                contractPropertyOwnershipType,
                contractPropertyOwnershipType.id
            ).then(
                () => {
                    dispatch<any>(fetchContractPropertyOwnershipTypes(contractPropertyOwnershipType.contractId));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const deleteContractPropertyOwnershipType =
    (contractPropertyOwnershipType: ContractPropertyOwnershipType): AppThunk =>
        async (dispatch) => {
            return deleteItem<ApiId, ContractPropertyOwnershipTypeResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_PROPERTY_OWNERSHIP_TYPES,
                contractPropertyOwnershipType.id
            ).then(
                () => {
                    dispatch<any>(fetchContractPropertyOwnershipTypes(contractPropertyOwnershipType.contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

/* actions */
export const { setContractPropertyOwnershipTypes } = contractPropertyOwnershipTypesSlice.actions;

/* selectors */
export const selectContractPropertyOwnershipTypes = (state: RootState) => state.contract.serviceUser.propertyOwnershipTypes.contractPropertyOwnershipTypes;

/* reducers */
export default contractPropertyOwnershipTypesSlice.reducer;
