import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
  [ContractSectionEnum.ServiceChanges]: 'Service charges'
};

const route = ROUTES.CONTRACT.pricing;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavPricingSteps = (contractId: ApiId): ContractSection[] => [
  {
    step: ContractSectionEnum.ServiceChanges,
    name: stepDisplayName[ContractSectionEnum.ServiceChanges],
    href: route.serviceCharges(contractId)
  }
];
