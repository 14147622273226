import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddContractProfessionalAssessmentQuestion,
  ApiId,
  ContractProfessionalAssessmentOverview,
  ContractProfessionalAssessmentQuestion,
  ContractProfessionalAssessmentQuestionProduct,
  ContractProfessionalAssessmentQuestionSetActiveModel,
  ContractProfessionalAssessmentQuestionsOverview,
  ContractProfessionalAssessmentSetActiveModel,
  ErrorResponse,
  IApiResponse,
  mapApiErrors
} from 'millbrook-core';
import { ENDPOINTS } from '../../../constants';
import { ContractProfessionalAssessmentAddProductAnswerFormData } from '../../../pages/Contract/PrescriberPortal/ProfessionalAssessment/components/ContractProfessionalAssessmentAddProductAnswerForm/ContractProfessionalAssessmentAddProductAnswerForm';
import { deleteItems, getItem, getItems, postItems, putItem } from '../../../services/api.service';
import { AppThunk, RootState } from '../../../store/store';

/* types */
export type ContractProfessionalAssessmentOverviewListResponse = IApiResponse<ContractProfessionalAssessmentOverview[]>;
export type ContractProfessionalAssessmentResponse = IApiResponse<ContractProfessionalAssessmentOverview>;
export type ContractProfessionalAssessmentQuestionsOverviewResponse =
  IApiResponse<ContractProfessionalAssessmentQuestionsOverview>;
export type ContractProfessionalAssessmentQuestionResponse = IApiResponse<ContractProfessionalAssessmentQuestion>;

/* thunks */
export const fetchContractProfessionalAssessmentsOverview =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ContractProfessionalAssessmentOverviewListResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENTS_BY_CONTRACT_ID(contractId)
    ).then((response) => {
      dispatch(setContractProfessionalAssessmentsOverview(response.result || []));
    });
  };

export const changeContractProfessionalAssessmentActiveStatus =
  (contractProfessionalAssessment: ContractProfessionalAssessmentSetActiveModel): AppThunk =>
  async (dispatch) => {
    return putItem<ContractProfessionalAssessmentSetActiveModel, ContractProfessionalAssessmentOverviewListResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENT_CHANGE_ACTIVE_STATUS,
      contractProfessionalAssessment
    ).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentsOverview(response.result || []));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchContractProfessionalAssessmentQuestionsOverview =
  (contractId: ApiId, professionalAssesmentId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ContractProfessionalAssessmentQuestionsOverviewResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENTS_BY_CONTRACT_ID_AND_PROFESSIONAL_ASSESSMENT_ID(
        contractId,
        professionalAssesmentId
      )
    ).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentQuestionsOverview(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const addContractProfessionalAssessmentQuestion =
  (contractId: AddContractProfessionalAssessmentQuestion): AppThunk =>
  async (dispatch) => {
    return postItems<
      AddContractProfessionalAssessmentQuestion,
      ContractProfessionalAssessmentQuestionsOverviewResponse
    >(ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION, contractId).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentQuestionsOverview(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const changeContractProfessionalAssessmentQuestionActiveStatus =
  (contractProfessionalAssessmentQuestion: ContractProfessionalAssessmentQuestionSetActiveModel): AppThunk =>
  async (dispatch) => {
    return putItem<
      ContractProfessionalAssessmentQuestionSetActiveModel,
      ContractProfessionalAssessmentQuestionsOverviewResponse
    >(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION,
      contractProfessionalAssessmentQuestion
    ).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentQuestionsOverview(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchContractProfessionalAssessmentQuestion =
  (contractProfessionalAssesmentQuestionId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ContractProfessionalAssessmentQuestionResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION,
      contractProfessionalAssesmentQuestionId
    ).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentQuestion(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const addContractProfessionalAssessmentQuestionProduct =
  (data: ContractProfessionalAssessmentAddProductAnswerFormData): AppThunk =>
  async (dispatch) => {
    return postItems<
      ContractProfessionalAssessmentAddProductAnswerFormData,
      ContractProfessionalAssessmentQuestionResponse
    >(ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_PRODUCT, data).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentQuestion(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractProfessionalAssessmentQuestionProduct =
  (data: ContractProfessionalAssessmentQuestionProduct[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);
    return deleteItems<ApiId, ContractProfessionalAssessmentQuestionResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_PRODUCT,
      ids,
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(setContractProfessionalAssessmentQuestion(response.result || undefined));
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

interface ContractProfessionalAssessmentsState {
  contractProfessionalAssessmentsOverview: ContractProfessionalAssessmentOverview[];
  contractProfessionalAssessmentQuestionsOverview?: ContractProfessionalAssessmentQuestionsOverview;
  contractProfessionalAssessmentQuestion?: ContractProfessionalAssessmentQuestion;
}

const initialState: ContractProfessionalAssessmentsState = {
  contractProfessionalAssessmentsOverview: []
};

const contractProfessionalAssessmentsSlice = createSlice({
  name: 'contractProfessionalAssessments',
  initialState,
  reducers: {
    setContractProfessionalAssessmentsOverview(state, action: PayloadAction<ContractProfessionalAssessmentOverview[]>) {
      state.contractProfessionalAssessmentsOverview = action.payload;
    },
    setContractProfessionalAssessmentQuestionsOverview(
      state,
      action: PayloadAction<ContractProfessionalAssessmentQuestionsOverview | undefined>
    ) {
      state.contractProfessionalAssessmentQuestionsOverview = action.payload;
    },
    setContractProfessionalAssessmentQuestion(
      state,
      action: PayloadAction<ContractProfessionalAssessmentQuestion | undefined>
    ) {
      state.contractProfessionalAssessmentQuestion = action.payload;
    }
  }
});

export const {
  setContractProfessionalAssessmentsOverview,
  setContractProfessionalAssessmentQuestionsOverview,
  setContractProfessionalAssessmentQuestion
} = contractProfessionalAssessmentsSlice.actions;

export const selectContractProfessionalAssessmentsOverview = (state: RootState) =>
  state.contract.prescriberPortal.contractProfessionalAssessment.contractProfessionalAssessmentsOverview;
export const selectContractProfessionalAssessmentQuestionsOverview = (state: RootState) =>
  state.contract.prescriberPortal.contractProfessionalAssessment.contractProfessionalAssessmentQuestionsOverview;
export const selectContractProfessionalAssessmentQuestion = (state: RootState) =>
  state.contract.prescriberPortal.contractProfessionalAssessment.contractProfessionalAssessmentQuestion;

export default contractProfessionalAssessmentsSlice.reducer;
