import { ApiId, IServiceCentreSummary, ColorType } from 'millbrook-core';

export interface ChargingMechanism {
  id: number;
  description: string;
}

export interface CommissioningAuthority {
  name: string;
  code: string;
}

export interface ContractType {
  id: number;
  name: string;
  description?: string;
}

export interface ContractSubType extends ContractType {}

export enum ContractStateEnum {
  Archive,
  Draft,
  Live
}

export const ContractStateColor: { [key: string]: ColorType } = {
  [ContractStateEnum.Live]: 'jungleGreen',
  [ContractStateEnum.Archive]: 'black',
  [ContractStateEnum.Draft]: 'scienceBlue'
};

export interface BaseContract {
  id: ApiId;
  moniker: string;
  name: string;
}
export interface ContractDraft extends BaseContract {
  chargingMechanism: number;
  contractType: string;
  contractSubType: string;
  serviceCentreIds: ApiId[];
  commissioningAuthorities: CommissioningAuthority[];
}

// FIX - waiting on BE to remove depotIds and just use serviceCentreIds
export interface ContractDraftCreateRequest
  extends Omit<ContractDraft, 'id' | 'location' | 'contractType' | 'contractSubType' | 'serviceCentreIds'> {
  depotIds: ApiId[];
}
export type ContractDraftCreateResponse = BaseContract;

export interface ContractListItemModel extends BaseContract {
  contractState: ContractStateEnum;
  startDate: string;
  endDate: string;
  serviceCentres: IServiceCentreSummary[];
  commissioningAuthorities: CommissioningAuthority[];
  contractStatusColour: ColorType;
}
