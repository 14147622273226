import { Box, Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import millbrookLogo from 'assets/svg/millbrook-logo.svg';
import clsx from 'clsx';
import { ROUTES } from 'constants/routes';
import {
  COLORS,
  selectUserIsLoggedIn,
  selectUserName,
  SpacingContainer,
  StyledDockedDrawerAnchor
} from 'millbrook-core';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import MainMenuDrawer from './MainMenuDrawer';

const useStyles = makeStyles((theme) => ({
  logo: {
    flexGrow: 1,
    padding: theme.spacing(2, 2, 2, '4%'),
    '& a': {
      display: 'inline-block'
    },
    '& img': {
      display: 'block',
      [theme.breakpoints.down('xs')]: {
        height: '30px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, '1%')
    }
  },
  logoNotLoggedIn: {
    paddingLeft: 0
  },
  toolbar: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '100px'
    }
  },
  mobileNavLink: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '11px',
    color: COLORS.white
  },
  secondaryNav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
    minHeight: theme.spacing(5),
    flexWrap: 'wrap'
  }
}));

const Header = () => {
  const classes = useStyles();
  const [mainOpen, setMainOpen] = useState(false);
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);
  const loggedInUserName = useSelector(selectUserName);

  const handleMainDrawerOpen = () => {
    setMainOpen(true);
  };

  const handleMainDrawerClose = () => {
    setMainOpen(false);
  };

  return (
    <Fragment>
      <AppBar position="fixed">
        <SpacingContainer>
          <Toolbar className={classes.toolbar} disableGutters>
            {userIsLoggedIn && (
              <IconButton edge="start" aria-label="open drawer" onClick={handleMainDrawerOpen}>
                <MenuIcon />
              </IconButton>
            )}
            <Box className={clsx(classes.logo, !userIsLoggedIn && classes.logoNotLoggedIn)}>
              <Link component={RouterLink} to={ROUTES.HOME} title="Millbrook home">
                <img src={millbrookLogo} alt="Millbrook home" />
              </Link>
            </Box>
          </Toolbar>
        </SpacingContainer>
        {userIsLoggedIn && (
          <Box bgcolor="secondary.main">
            <SpacingContainer>
              <Box className={classes.secondaryNav}>
                <Box>{loggedInUserName}</Box>
              </Box>
            </SpacingContainer>
          </Box>
        )}
        {/* This is a placeholder to measure against for the header height and the positioning that can be used for the right hand sticky nav */}
        <StyledDockedDrawerAnchor />
      </AppBar>

      <MainMenuDrawer open={mainOpen} onClose={handleMainDrawerClose} />
    </Fragment>
  );
};

export default Header;
