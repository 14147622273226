import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IDeleteRequest, mapApiErrors, PinTeam } from 'millbrook-core';
import { deleteItems, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { fetchAreas } from './areas.slice';
import { PinTeamRequest, PinTeamResponse } from './budgetPin.types';
import { fetchOrganisations } from './organisations.slice';

interface TeamsState {
  teamList: PinTeam[];
}

const initialState: TeamsState = { teamList: [] };

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeamList(state, action: PayloadAction<PinTeam[]>) {
      state.teamList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

const cacheName = 'pin-teams';

// thunks for teams
export const initTeamsData =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return Promise.all([
      dispatch<any>(fetchTeams(contractId)),
      dispatch<any>(fetchAreas(contractId)),
      dispatch<any>(fetchOrganisations(contractId))
    ]).catch((error) => {
      throw new Error(error);
    });
  };

export const fetchTeams =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PinTeamResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.TEAMS(contractId), { cacheName }).then(
      (response) => {
        dispatch(setTeamList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createTeam =
  (data: PinTeam): AppThunk =>
  async (dispatch) => {
    const { id, ...mappedData } = data;
    return postItems<PinTeamRequest, PinTeamResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.TEAMS(), mappedData, {
      cacheName
    }).then(
      () => {
        dispatch<any>(fetchTeams(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateTeam =
  (data: PinTeam): AppThunk =>
  async (dispatch) => {
    return putItem<PinTeamRequest, PinTeamResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.TEAMS(), data, data.id, {
      cacheName
    }).then(
      () => {
        dispatch<any>(fetchTeams(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteTeams =
  (data: PinTeam[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.BUDGET_PIN.TEAMS(), ids, {
      enableGlobalErrorDialog: true,
      cacheName
    }).then(
      () => {
        dispatch<any>(fetchTeams(data[0].contractId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

/* actions */
export const { setTeamList } = teamsSlice.actions;

/* selectors */
export const selectTeamList = (state: RootState) => state.contract.budgetPin.teams.teamList;

export const getTeamSelectOptions = createSelector([selectTeamList], (teamList) => {
  return teamList.map((team) => {
    return { label: team.name, value: team.id };
  });
});

/* reducers */
export default teamsSlice.reducer;
