import { ContractNavStates, ContractSection } from 'features/contract/builder/sectionSteps/contractNav.types';
import { ChargingMechanism } from 'features/contract/contract.types';
import { MasterService, MasterServiceRequest } from 'features/masterLists/masterServices/masterServices.slice';
import { ISelectOption } from 'millbrook-core';

export const mapChargingMechanismsToSelectOptions = (chargingMechanisms: ChargingMechanism[]): ISelectOption[] => {
  return chargingMechanisms?.map((chargingMechanism) => ({
    value: chargingMechanism.id.toString(),
    label: chargingMechanism.description
  }));
};

export const setupContractSections = (steps: ContractSection[], next: string) => {
  // using a backwards loops, to get the next items easier. Maybe, it only has one pass on the array, and doesn't spread to copy
  for (let x = steps.length - 1; x >= 0; x--) {
    const step = steps[x];

    step.next = next;

    // Contracct Nav State can be disabled here if necessary step.state = ContractNavStates.DISABLED
    step.state = ContractNavStates.READY;

    const subSections = step.subSections;
    if (subSections) {
      setupContractSections(subSections, next);
    }

    next = step.href;
  }
};

export const mapMasterServiceToFormData = (data: MasterService | MasterServiceRequest) => {
  const formData = new FormData();

  for (const [key, value] of Object.entries(data)) {
    formData.append(key, value);
  }

  return formData;
};
