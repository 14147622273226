import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse } from 'millbrook-core';
import { deleteItem, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { ContractProductField } from './scope.types';

// State
interface State {
  fields: ContractProductField[];
}

const initialState: State = {
  fields: []
};

// Slice
const slice = createSlice({
  name: 'contractProductFields',
  initialState,
  reducers: {
    setContractProductFields(state, action: PayloadAction<ContractProductField[]>) {
      state.fields = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// Actions
export const { setContractProductFields } = slice.actions;

// Selectors
export const selectContractProductFields = (state: RootState) => state.contract.scope.productFields.fields;

// Thunks
export const fetchContractProductFields =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractProductField[]>>(ENDPOINTS.CONTRACT.SCOPE.PRODUCT_FIELDS(contractId), {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(setContractProductFields(response.result || initialState.fields));
      },
      () => {
        // Handled by global error dialog
      }
    );
  };

export const createContractProductField =
  (contractId: ApiId, data: ContractProductField): AppThunk =>
  async (dispatch) => {
    if (!data.id) {
      return postItems<ContractProductField, ContractProductField>(
        ENDPOINTS.CONTRACT.SCOPE.PRODUCT_FIELDS(contractId),
        data,
        {
          enableGlobalErrorDialog: true
        }
      ).then(
        () => dispatch(fetchContractProductFields(contractId)),
        () => {
          // Handled by global error dialog
        }
      );
    }
  };

export const deleteContractProductField =
  (contractId: ApiId, data: ContractProductField): AppThunk =>
  async (dispatch) => {
    if (data.id) {
      return deleteItem<string, null>(ENDPOINTS.CONTRACT.SCOPE.PRODUCT_FIELDS(contractId), data.id, {
        enableGlobalErrorDialog: true
      }).then(
        () => dispatch(fetchContractProductFields(contractId)),
        () => {
          // Handled by global error dialog
        }
      );
    }
  };

export default slice.reducer;
