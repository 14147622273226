import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse } from 'millbrook-core';
import { ContractTimeSlotFormData } from 'pages/Contract/Scope/TimeSlots/ContractTimeSlotForm.validation';
import { getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { ContractTimeSlot, ContractTimeSlotData, ContractTimeSlotWorkingDay } from './scope.types';

/* state */
interface State {
  quantityPerTimeSlot: number;
  workingDays?: ContractTimeSlotWorkingDay[];
}

const initialState: State = { quantityPerTimeSlot: 0, workingDays: undefined };

const slice = createSlice({
  name: 'timeSlots',
  initialState,
  reducers: {
    setTimeSlotData(state, action: PayloadAction<ContractTimeSlotData>) {
      state.quantityPerTimeSlot = action.payload.quantityPerTimeSlot;
      state.workingDays = action.payload.workingDays;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* selectors */
export const selectWorkingDays = (state: RootState) => state.contract.scope.timeSlots.workingDays;
export const selectQuantityPerTimeSlot = (state: RootState) => state.contract.scope.timeSlots.quantityPerTimeSlot;

/* actions */
export const { setTimeSlotData } = slice.actions;

/* thunks */
export const fetchTimeSlotData =
  (contractId: ApiId, contractServiceTypeId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractTimeSlotData>>(
      ENDPOINTS.CONTRACT.SCOPE.TIME_SLOTS_GET(contractId, contractServiceTypeId),
      { enableGlobalErrorDialog: true }
    ).then(
      (response) => {
        dispatch(setTimeSlotData(response.result as ContractTimeSlotData));
        return response.result;
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const updateTimeSlotData =
  (data: ContractTimeSlotFormData, contractId: ApiId, contractServiceTypeId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const workingDays = getState().contract.scope.timeSlots.workingDays;

    if (!data || !contractId || !contractServiceTypeId || !workingDays) {
      return;
    }

    // Format data before sending to API
    const formattedData: ContractTimeSlotData = {
      contractServiceTypeId: contractServiceTypeId,
      quantityPerTimeSlot: data.quantityPerTimeSlot || 0,
      workingDays: []
    };

    workingDays.forEach((workingDay) => {
      const formattedTimeSlots: ContractTimeSlot[] = [];

      // Note: The time slot ID is not neccesarily unique (as previously unsaved time slots will always
      // have a value of "00000000-0000-0000-0000-000000000000"). For this reason, the time slot index
      // is used as the unique form value.
      workingDay.timeSlots.forEach((timeSlot, index) => {
        formattedTimeSlots.push({
          id: timeSlot.id,
          fromTime: timeSlot.fromTime,
          toTime: timeSlot.toTime,
          slotName: timeSlot.slotName,
          isSelected: (data as any)[`workingDay${workingDay.day}`].includes(index.toString())
        });
      });

      formattedData.workingDays.push({
        day: workingDay.day,
        dayName: workingDay.dayName,
        timeSlots: formattedTimeSlots
      });
    });

    return postItems<ContractTimeSlotData, any>(ENDPOINTS.CONTRACT.SCOPE.TIME_SLOTS_UPDATE(contractId), formattedData, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch(fetchTimeSlotData(contractId, contractServiceTypeId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

/* reducers */
export default slice.reducer;
