import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  IApiResponse,
  IDeleteRequest,
  ImportCsvFormData,
  mapApiErrors,
  PinUser,
  PinUserAuditModel,
  PinUserAuthoriserModel,
  showGenericErrorDialog
} from 'millbrook-core';
import { deleteItems, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import {
  CsvUserUploadResponse,
  PinUserRequest,
  PinUserResponse,
  PinUserSearchRequest,
  PinUserSearchResponse
} from './budgetPin.types';
import { fetchGroups } from './groups.slice';
import { fetchTeams } from './teams.slice';

interface UsersState {
  userList: PinUser[];
  authoriserList: PinUserAuthoriserModel[];
  userAudit: PinUserAuditModel[];
}

const initialState: UsersState = { userList: [], authoriserList: [], userAudit: [] };

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserList(state, action: PayloadAction<PinUser[]>) {
      state.userList = action.payload;
    },
    setAuthoriserList(state, action: PayloadAction<PinUserAuthoriserModel[]>) {
      state.authoriserList = action.payload;
    },
    setPinUserAudit(state, action: PayloadAction<PinUserAuditModel[]>) {
      state.userAudit = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// thunks for contract users
export const initUserFormData =
  (contractId: ApiId, userId?: ApiId): AppThunk =>
  async (dispatch) => {
    return Promise.all([
      dispatch<any>(fetchTeams(contractId)), // 0
      dispatch<any>(fetchGroups(contractId)), // 1
      dispatch<any>(getContractAuthorisers(contractId)), // 2
      userId && dispatch<any>(fetchUser(userId)) // 3
    ])
      .then((responses) => {
        // only return the user data. The rest will be reduxified
        // THINK - is there a better way to get the bits of the response? Promise.all guarantees the order of the responses, but it feels weird using the index this way
        return responses[3];
      })
      .catch((error) => {
        const errorMessage = mapApiErrors(error);
        dispatch(showGenericErrorDialog(errorMessage));
        throw new Error(error);
      });
  };

export const fetchUsers =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<PinUser[]>>(ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER(contractId)).then(
      (response) => {
        dispatch(setUserList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchUser =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<PinUser>>(ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER(), id).then(
      (response) => response.result || {}
    );
  };

export const fetchUserAudit =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<PinUserAuditModel[]>>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER_AUDIT(id)
    ).then((response) => {
      dispatch(setPinUserAudit(response.result || []));
    });
  };

export const fetchUserByEmail =
  (email: string): AppThunk =>
  async (dispatch) => {
    return postItems<PinUserSearchRequest, IApiResponse<PinUserSearchResponse>>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.SEARCH_USERS,
      {
        email
      }
    )
      .then((response) => {
        return response?.result?.data;
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const createUser =
  (data: PinUserRequest): AppThunk =>
  async (dispatch) => {
    return postItems<PinUserRequest, PinUserResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER(), data).catch(
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateUser =
  (data: PinUserRequest): AppThunk =>
  async (dispatch) => {
    return putItem<PinUserRequest, PinUserResponse>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER(),
      data,
      data.id
    ).catch((response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    });
  };

export const deleteUsers =
  (data: PinUser[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER(), ids, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchUsers(data[0].contractId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const getContractAuthorisers =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<PinUserAuthoriserModel[]>>(ENDPOINTS.CONTRACT.BUDGET_PIN.AUTHORISERS(contractId), {
      enableGlobalErrorDialog: true
    }).then((response) => {
      dispatch(setAuthoriserList(response.result || []));
    });
  };

export const importContractPinUsers =
  (contractId: ApiId, data: ImportCsvFormData): AppThunk =>
  async (dispatch) => {
    const { files } = data;
    var formData = new FormData();

    formData.append('contractId', contractId);
    for (let i = 0; i < files.length; i++) {
      formData.append('csvFile', files[i]);
    }

    return postItems<FormData, IApiResponse<CsvUserUploadResponse>>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.CONTRACT_PIN_USER_IMPORT,
      formData
    ).then(
      (response) => {
        dispatch<any>(fetchUsers(contractId));
        return response.result || {};
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setUserList, setAuthoriserList, setPinUserAudit } = usersSlice.actions;

/* selectors */
export const selectUserList = (state: RootState) => state.contract.budgetPin.users.userList;

export const selectAuthoriserList = (state: RootState) => state.contract.budgetPin.users.authoriserList;

export const selectPinUserAudit = (state: RootState) => state.contract.budgetPin.users.userAudit;

/* reducers */
export default usersSlice.reducer;
