import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiId, ContractRetailerModel, deleteItems, getItem, getItems, IApiResponse, IDeleteRequest, mapApiErrors, PartialBy, postItems, putItem } from "millbrook-core";
import { ENDPOINTS } from "../../../constants";
import { AppThunk, RootState } from "../../../store/store";

/* types */
export type ContractRetailerOverviewListResponse = IApiResponse<ContractRetailerModel[]>;

export type ContractRetailerRequest = PartialBy<ContractRetailerModel, 'id' | 'dateCreated' >;

export type ContractRetailerResponse = IApiResponse<ContractRetailerModel[]>;

const cacheName = 'contract-retailer';

/* thunks */
export const fetchContractRetailers =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return getItems<ContractRetailerOverviewListResponse>(
                ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILERS_BY_CONTRACT_ID(contractId)
            ).then((response) => {
                dispatch(setContractRetailersOverview(response.result || []));
            });
        };

export const fetchContractRetailer =
    (id: ApiId): AppThunk =>
        async (dispatch) => {
            return getItem<ApiId, ContractRetailerResponse>(
                ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER(), id
            ).then((response) => {
                return response.result || {};
            });
        };

export const createContractRetailer =
    (data: ContractRetailerRequest): AppThunk =>
        async (dispatch) => {
            return postItems<ContractRetailerRequest, ContractRetailerResponse>(ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER(), data).then(
                (response) => {
                    dispatch<any>(fetchContractRetailers(data.contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateContractRetailer =
    (data: ContractRetailerModel): AppThunk =>
        async (dispatch) => {
            return putItem<ContractRetailerRequest, ContractRetailerResponse>(ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER(), data, data.id).then(
                (response) => {
                    dispatch<any>(fetchContractRetailers(data.contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const deleteContractRetailers =
    (data: ContractRetailerModel[]): AppThunk =>
        async (dispatch) => {
            const ids = data.map((d) => d.id);

            return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER(), ids, {
                enableGlobalErrorDialog: true,
                cacheName
            }).then(
                () => {
                    dispatch<any>(fetchContractRetailers(data[0].contractId));
                },
                () => {
                    // handled with global error handler
                }
            );
        };

interface ContractRetailersState {
    contractRetailersOverview: ContractRetailerModel[];
}

const initialState: ContractRetailersState = {
    contractRetailersOverview: []
};

const contractRetailersSlice = createSlice({
    name: 'contractRetailers',
    initialState,
    reducers: {
        setContractRetailersOverview(state, action: PayloadAction<ContractRetailerModel[]>) {
            state.contractRetailersOverview = action.payload;
        }
    }
});

export const {
    setContractRetailersOverview
} = contractRetailersSlice.actions;

export const selectContractRetailersOverview = (state: RootState) =>
    state.contract.prescriptionPortal.contractRetailers.contractRetailersOverview;

export default contractRetailersSlice.reducer;
