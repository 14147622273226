import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiId, GrantAccess, IApiResponse, selectUserPermissions, userHasPermissionToAccess } from 'millbrook-core';
import { AppThunk, RootState } from 'store/store';
import { ENDPOINTS } from '../../constants';
import { getItems } from '../../services/api.service';
import {
  LocationLevels,
  LocationProductLevels,
  PurchaseOrder,
  PurchaseOrderMedia,
  PurchaseOrderStatus,
  RecommendedOrder,
  UnallocatedProducts
} from './purchaseOrders/purchaseOrder.slice';
import {
  SpecialOrderPoolSupplier,
  SpecialOrderProcessed,
  SpecialPurchaseOrder
} from './specialPurchaseOrders/specialPurchaseOrder.slice';
import { Supplier, SupplierMedia, SupplierProduct, SupplierProductPrice } from './suppliers/suppliers.slice';

export interface OrdersRequiringAction {
  locationId: ApiId;
  locationName: string;
  lowStockCount: number;
  specialsCount: number;
}

interface ProcurementState {
  suppliers: Supplier[];
  supplier?: Supplier;
  suppliersCount: number;
  purchaseOrders: PurchaseOrder[];
  purchaseOrder?: PurchaseOrder;
  locationlevels?: LocationLevels;
  locationProductLevels: LocationProductLevels[];
  unallocatedProducts: UnallocatedProducts[];
  recommendedOrders: RecommendedOrder[];
  specialPurchaseOrders: SpecialPurchaseOrder[];
  supplierProducts: SupplierProduct[];
  purchaseOrdersCount: number;
  supplierProductsUnmappedCount: number;
  ordersRequiringAction: OrdersRequiringAction[];
  specialPurchaseOrder?: SpecialPurchaseOrder;
  supplierProductPrices: SupplierProductPrice[];
  specialOrderPool: SpecialOrderPoolSupplier[];
  specialPurchaseOrderDrafts: PurchaseOrder[];
  purchaseOrdersArchive: PurchaseOrder[];
  specialPurchaseOrdersCount: number;
  specialOrderProcessed?: SpecialOrderProcessed;
  purchaseOrderMedias: PurchaseOrderMedia[];
  supplierMedias: SupplierMedia[];
  searchedSpecialPurchaseOrders: SpecialPurchaseOrder[];
}

const initialState: ProcurementState = {
  suppliers: [],
  purchaseOrders: [],
  recommendedOrders: [],
  locationProductLevels: [],
  unallocatedProducts: [],
  supplierProducts: [],
  suppliersCount: 0,
  purchaseOrdersCount: 0,
  supplierProductsUnmappedCount: 0,
  ordersRequiringAction: [],
  specialPurchaseOrders: [],
  supplierProductPrices: [],
  specialOrderPool: [],
  specialPurchaseOrderDrafts: [],
  purchaseOrdersArchive: [],
  specialPurchaseOrdersCount: 0,
  purchaseOrderMedias: [],
  supplierMedias: [],
  searchedSpecialPurchaseOrders: []
};

const procurementSlice = createSlice({
  name: 'procurement',
  initialState,
  reducers: {
    setSuppliers(state, action: PayloadAction<Supplier[]>) {
      state.suppliers = action.payload;
    },
    setSupplier(state, action: PayloadAction<Supplier | undefined>) {
      state.supplier = action.payload;
    },
    setPurchaseOrders(state, action: PayloadAction<PurchaseOrder[]>) {
      state.purchaseOrders = action.payload;
    },
    setRecommendedOrders(state, action: PayloadAction<RecommendedOrder[]>) {
      state.recommendedOrders = action.payload;
    },
    setLocationStockLevels(state, action: PayloadAction<LocationLevels | undefined>) {
      state.locationlevels = action.payload;
    },
    setPurchaseOrder(state, action: PayloadAction<PurchaseOrder | undefined>) {
      state.purchaseOrder = action.payload;
    },
    setSupplierProducts(state, action: PayloadAction<SupplierProduct[]>) {
      state.supplierProducts = action.payload;
    },
    setSuppliersCount(state, action: PayloadAction<number>) {
      state.suppliersCount = action.payload;
    },
    setPurchaseOrdersCount(state, action: PayloadAction<number>) {
      state.purchaseOrdersCount = action.payload;
    },
    setSupplierProductsUnmappedCount(state, action: PayloadAction<number>) {
      state.supplierProductsUnmappedCount = action.payload;
    },
    setOrdersRequiringaction(state, action: PayloadAction<OrdersRequiringAction[]>) {
      state.ordersRequiringAction = action.payload;
    },
    setSpecialPurchaseOrders(state, action: PayloadAction<SpecialPurchaseOrder[]>) {
      state.specialPurchaseOrders = action.payload;
    },
    setSpecialPurchaseOrder(state, action: PayloadAction<SpecialPurchaseOrder | undefined>) {
      state.specialPurchaseOrder = action.payload;
    },
    setSupplierProductPrices(state, action: PayloadAction<SupplierProductPrice[]>) {
      state.supplierProductPrices = action.payload;
    },
    setSpecialOrderPool(state, action: PayloadAction<SpecialOrderPoolSupplier[]>) {
      state.specialOrderPool = action.payload;
    },
    setSpecialPurchaseOrderDrafts(state, action: PayloadAction<PurchaseOrder[]>) {
      state.specialPurchaseOrderDrafts = action.payload;
    },
    setPurchaseOrdersArchive(state, action: PayloadAction<PurchaseOrder[]>) {
      state.purchaseOrdersArchive = action.payload;
    },
    setSpecialPurchaseOrdersCount(state, action: PayloadAction<number>) {
      state.specialPurchaseOrdersCount = action.payload;
    },
    setSpecialOrderProcessed(state, action: PayloadAction<SpecialOrderProcessed | undefined>) {
      state.specialOrderProcessed = action.payload;
    },
    setPurchaseOrderMedias(state, action: PayloadAction<PurchaseOrderMedia[]>) {
      state.purchaseOrderMedias = action.payload;
    },
    setSupplierMedias(state, action: PayloadAction<SupplierMedia[]>) {
      state.supplierMedias = action.payload;
    },
    clearPurchaseOrderMedias(state) {
      state.purchaseOrderMedias.length = 0;
    },
    clearSupplierMedias(state) {
      state.supplierMedias.length = 0;
    },
    setSearchedSpecialPurchaseOrders(state, action: PayloadAction<SpecialPurchaseOrder[]>) {
      state.searchedSpecialPurchaseOrders = action.payload;
    }
  }
});

// actions
export const {
  setSuppliers,
  setSuppliersCount,
  setSupplier,
  setPurchaseOrders,
  setPurchaseOrdersCount,
  setPurchaseOrder,
  setRecommendedOrders,
  setLocationStockLevels,
  setSupplierProducts,
  setSupplierProductsUnmappedCount,
  setOrdersRequiringaction,
  setSpecialPurchaseOrders,
  setSpecialPurchaseOrder,
  setSupplierProductPrices,
  setSpecialOrderPool,
  setSpecialPurchaseOrderDrafts,
  setPurchaseOrdersArchive,
  setSpecialPurchaseOrdersCount,
  setSpecialOrderProcessed,
  setPurchaseOrderMedias,
  clearPurchaseOrderMedias,
  setSupplierMedias,
  clearSupplierMedias,
  setSearchedSpecialPurchaseOrders
} = procurementSlice.actions;

export type OrdersRequiringActionListResponse = IApiResponse<OrdersRequiringAction[]>;

export const fetchOrdersRequiringAction = (): AppThunk => async (dispatch) => {
  return getItems<OrdersRequiringActionListResponse>(ENDPOINTS.PROCUREMENT.PROCUREMENT.PROCUREMENT).then((response) => {
    dispatch(setOrdersRequiringaction(response.result || []));
    return response;
  });
};

// selectors
export const selectSuppliers = (state: RootState) => state.procurement && state.procurement.suppliers;
export const selectSupplier = (state: RootState) => state.procurement && state.procurement.supplier;
export const selectSuppliersCount = (state: RootState) => state.procurement && state.procurement.suppliersCount;
export const selectPurchaseOrder = (state: RootState) => state.procurement && state.procurement.purchaseOrder;
export const selectPurchaseOrders = (state: RootState) => state.procurement && state.procurement.purchaseOrders;
export const selectRecommendedOrders = (state: RootState) => state.procurement && state.procurement.recommendedOrders;
export const selectPurchaseOrdersCount = (state: RootState) =>
  state.procurement && state.procurement.purchaseOrdersCount;
export const selectSupplierProductsUnmappedCount = (state: RootState) =>
  state.procurement && state.procurement.supplierProductsUnmappedCount;
export const selectOrdersRequiringAction = (state: RootState) =>
  state.procurement && state.procurement.ordersRequiringAction;
export const selectSupplierProducts = (state: RootState) => state.procurement && state.procurement.supplierProducts;
export const selectSpecialPurchaseOrders = (state: RootState) =>
  state.procurement && state.procurement.specialPurchaseOrders;
export const selectSpecialPurchaseOrder = (state: RootState) =>
  state.procurement && state.procurement.specialPurchaseOrder;
export const selectSupplierProductPrices = (state: RootState) =>
  state.procurement && state.procurement.supplierProductPrices;
export const selectSpecialOrderPool = (state: RootState) => state.procurement && state.procurement.specialOrderPool;
export const selectSpecialPurchaseOrderDrafts = (state: RootState) =>
  state.procurement && state.procurement.specialPurchaseOrderDrafts;
export const selectPurchaseOrdersArchive = (state: RootState) =>
  state.procurement && state.procurement.purchaseOrdersArchive;
export const selectSpecialPurchaseOrdersCount = (state: RootState) =>
  state.procurement && state.procurement.specialPurchaseOrdersCount;
export const selectSpecialOrderProcessed = (state: RootState) =>
  state.procurement && state.procurement.specialOrderProcessed;
export const selectPurchaseOrderMedias = (state: RootState) =>
  state.procurement && state.procurement.purchaseOrderMedias;
export const selectSuppliersMedias = (state: RootState) => state.procurement && state.procurement.supplierMedias;
export const selectLocationStockLevels = (state: RootState) => state.procurement && state.procurement.locationlevels;
export const getActiveSupplierOptions = createSelector([selectSuppliers], (supplierList) => {
  return supplierList.filter((x) => x.inActive == false);
});
export const selectSearchedSpecialPurchaseOrders = (state: RootState) =>
  state.procurement && state.procurement.searchedSpecialPurchaseOrders;

export const getActiveStandardSupplierProductOptions = createSelector(
  [selectSupplierProducts],
  (supplierProductList) => {
    return supplierProductList.filter((x) => x.inActive == false && x.isSpecial == false);
  }
);

// Procurement permissions

export const selectCanEditSupplier = createSelector([selectUserPermissions], (userPermissions) => {
  return userHasPermissionToAccess(userPermissions, GrantAccess(['Supplier', 'Edit', 'Add']));
});

export const selectCanAccessSpecialOrder = createSelector([selectUserPermissions], (userPermissions) => {
  return userHasPermissionToAccess(userPermissions, GrantAccess(['SpecialOrder', 'View']));
});

export const selectCanCreatePurchaseOrder = createSelector([selectUserPermissions], (userPermissions) => {
  return userHasPermissionToAccess(userPermissions, GrantAccess(['PurchaseOrder', 'Add']));
});

export const selectPurchaseOrderFieldEditPermissions = createSelector(
  [selectPurchaseOrder, selectCanAccessSpecialOrder, selectUserPermissions],
  (po, viewSpecialOrder, userPermissions) => {
    // special po can only edit if user has edit special carriage cost feature
    // standard po can only edit if user has edit standard carriage cost feature

    const generalEditPurchaseOrderPermissions = userHasPermissionToAccess(
      userPermissions,
      GrantAccess(['EditGeneralFields', 'Edit'])
    );
    const topLevelEditPurchaseOrderPermissions = userHasPermissionToAccess(
      userPermissions,
      GrantAccess(['PurchaseOrder', 'Edit'])
    );
    const statusIsAwaitingAuth = po && po.statusId === PurchaseOrderStatus.RequiresAuthorisation;

    // you can edit the status and submit the order if
    // - You have general edit permissions AND (the order is standard OR if you have special order permissions and the order is special)
    // - OR you have Purchase order edit permissions AND the status is awaiting authorisation

    return {
      canEditCarriageCost:
        (po?.isSpecial && userHasPermissionToAccess(userPermissions, GrantAccess(['EditSpecialCarriageCost']))) ||
        (!po?.isSpecial && userHasPermissionToAccess(userPermissions, GrantAccess(['EditStandardCarriageCost']))),
      canEditSkuPrice:
        (po?.isSpecial && userHasPermissionToAccess(userPermissions, GrantAccess(['EditSpecialStockPrice']))) ||
        (!po?.isSpecial && userHasPermissionToAccess(userPermissions, GrantAccess(['EditStandardStockPrice']))),
      canEditStatusAndSubmit:
        (po && po.isSpecial ? viewSpecialOrder : true) &&
        ((topLevelEditPurchaseOrderPermissions && statusIsAwaitingAuth) || generalEditPurchaseOrderPermissions),
      canEditPurchaseOrder: (po && po.isSpecial ? viewSpecialOrder : true) && generalEditPurchaseOrderPermissions
    };
  }
);

export default procurementSlice.reducer;
