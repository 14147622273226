import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { partition } from 'lodash';
import {
    CareOutcomeModel,
    ISelectOption,
    IServiceCentre,
    MasterEventType,
    MasterPPMType,
    MasterReasonCode,
    MasterReviewPeriod,
    MasterSpeed,
    ReasonCodeLevelEnum
} from 'millbrook-core';
import { RootState } from 'store/store';
import { MasterCleaningCode } from './masterCleaningCodes/masterCleaningCodes.slice';
import { MasterClientAlert } from './masterClientAlerts/masterClientAlerts.slice';
import { MasterInactiveReason } from './masterInactiveReasons/masterInactiveReasons.slice';
import { MasterPropertyOwnershipType, MasterPropertyType } from './masterProperties/masterProperties.slice';
import { MasterReviewType } from './masterReviews/masterReviews.slice';
import { MasterService } from './masterServices/masterServices.slice';
import { MasterServiceUserGroup } from './masterServiceUserGroups/masterServiceUserGroups.slice';

interface MasterListsState {
    serviceCentres?: IServiceCentre[];
    serviceUserGroups?: MasterServiceUserGroup[];
    clientAlerts?: MasterClientAlert[];
    clientInactiveReasons?: MasterInactiveReason[];
    reviewTypes?: MasterReviewType[];
    reviewPeriods?: MasterReviewPeriod[];
    propertyTypes?: MasterPropertyType[];
    propertyOwnershipTypes?: MasterPropertyOwnershipType[];
    services: MasterService[];
    speeds: MasterSpeed[];
    itemReasonCodes: MasterReasonCode[];
    serviceReasonCodes: MasterReasonCode[];
    cleaningCodes?: MasterCleaningCode[];
    ppmTypes?: MasterPPMType[];
    eventTypes?: MasterEventType[];
    careOutcomes: CareOutcomeModel[];
}

const initialState: MasterListsState = {
    services: [],
    speeds: [],
    itemReasonCodes: [],
    serviceReasonCodes: [],
    careOutcomes: []
};

const masterListsSlice = createSlice({
    name: 'masterLists',
    initialState,
    reducers: {
        setMasterServiceCentres(state, action: PayloadAction<IServiceCentre[]>) {
            state.serviceCentres = action.payload;
        },
        setMasterServiceUserGroups(state, action: PayloadAction<MasterServiceUserGroup[]>) {
            state.serviceUserGroups = action.payload;
        },
        setMasterClientAlerts(state, action: PayloadAction<MasterClientAlert[]>) {
            state.clientAlerts = action.payload;
        },
        setMasterInactiveReasons(state, action: PayloadAction<MasterInactiveReason[]>) {
            state.clientInactiveReasons = action.payload;
        },
        setMasterReviewTypes(state, action: PayloadAction<MasterReviewType[]>) {
            state.reviewTypes = action.payload;
        },
        setMasterReviewPeriods(state, action: PayloadAction<MasterReviewPeriod[]>) {
            state.reviewPeriods = action.payload;
        },
        setMasterPropertyTypes(state, action: PayloadAction<MasterPropertyType[]>) {
            state.propertyTypes = action.payload;
        },
        setMasterPropertyOwnershipTypes(state, action: PayloadAction<MasterPropertyOwnershipType[]>) {
            state.propertyOwnershipTypes = action.payload;
        },
        setMasterServices(state, action: PayloadAction<MasterService[]>) {
            state.services = action.payload;
        },
        setMasterSpeeds(state, action: PayloadAction<MasterSpeed[]>) {
            state.speeds = action.payload;
        },
        setMasterReasonCodes(state, action: PayloadAction<MasterReasonCode[]>) {
            const reasonCodes = partition(action.payload, { reasonCodeLevelId: ReasonCodeLevelEnum.Item });
            state.itemReasonCodes = reasonCodes[0];
            state.serviceReasonCodes = reasonCodes[1];
        },
        setMasterCleaningCodes(state, action: PayloadAction<MasterCleaningCode[]>) {
            state.cleaningCodes = action.payload;
        },
        setMasterPPMTypes(state, action: PayloadAction<MasterPPMType[]>) {
            state.ppmTypes = action.payload;
        },
        setMasterCareOutcomes(state, action: PayloadAction<CareOutcomeModel[]>) {
            state.careOutcomes = action.payload;
        },
        setMasterEventTypes(state, action: PayloadAction<MasterEventType[]>) {
            state.eventTypes = action.payload;
        }
    }
});

// actions
export const {
    setMasterServiceCentres,
    setMasterServiceUserGroups,
    setMasterClientAlerts,
    setMasterInactiveReasons,
    setMasterReviewTypes,
    setMasterReviewPeriods,
    setMasterPropertyTypes,
    setMasterPropertyOwnershipTypes,
    setMasterServices,
    setMasterSpeeds,
    setMasterReasonCodes,
    setMasterCleaningCodes,
    setMasterPPMTypes,
    setMasterEventTypes,
    setMasterCareOutcomes
} = masterListsSlice.actions;

// selectors
export const selectMasterListsState = (state: RootState) => state.masterLists;
export const selectMasterServiceCentres = (state: RootState) => state.masterLists && state.masterLists.serviceCentres;
export const selectMasterServiceUserGroups = (state: RootState) =>
    state.masterLists && state.masterLists.serviceUserGroups;
export const selectMasterClientAlerts = (state: RootState) => state.masterLists && state.masterLists.clientAlerts;
export const selectMasterInactiveReasons = (state: RootState) => state.masterLists && state.masterLists.clientInactiveReasons;
export const selectMasterReviewTypes = (state: RootState) => state.masterLists && state.masterLists.reviewTypes;
export const selectMasterReviewPeriods = (state: RootState) => state.masterLists && state.masterLists.reviewPeriods;
export const selectMasterPropertyTypes = (state: RootState) => state.masterLists && state.masterLists.propertyTypes;
export const selectMasterPropertyOwnershipTypes = (state: RootState) => state.masterLists && state.masterLists.propertyOwnershipTypes;
export const selectMasterServices = (state: RootState) => state.masterLists && state.masterLists.services;
export const selectMasterSpeeds = (state: RootState) => state.masterLists && state.masterLists.speeds;
export const selectMasterServiceReasonCodes = (state: RootState) =>
    state.masterLists && state.masterLists.serviceReasonCodes;
export const selectMasterItemReasonCodes = (state: RootState) => state.masterLists && state.masterLists.itemReasonCodes;
export const selectMasterCleaningCodes = (state: RootState) => state.masterLists && state.masterLists.cleaningCodes;
export const selectMasterPPMTypes = (state: RootState) => state.masterLists && state.masterLists.ppmTypes;
export const selectMasterEventTypes = (state: RootState) => state.masterLists && state.masterLists.eventTypes;
export const selectCareOutcomes = (state: RootState) => state.masterLists && state.masterLists.careOutcomes;

export const getMasterServiceOptions = createSelector([selectMasterServices], (list): ISelectOption[] => {
    return list?.map((item) => {
        return { label: item.name, value: item.id };
    });
});

export default masterListsSlice.reducer;
