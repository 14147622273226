import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { MasterClientAlert } from 'features/masterLists/masterClientAlerts/masterClientAlerts.slice';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors } from 'millbrook-core';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractServiceUserAlertsFromServer } from '../fetch-mappers';
import { clearContractState } from '../overview/overview.slice';

/* types */
export interface ContractServiceUserAlert extends MasterClientAlert {
  serviceUserAlertId: ApiId; // this is the master id
  contractId: ApiId;
  masterName: string;
  onContract: boolean;
  modified?: boolean;
}

export type ContractServiceUserAlertId = ApiId;

export type ContractServiceUserAlertsRequest = ContractServiceUserAlert;
export type ContractServiceUserAlertsResponse = IApiResponse<ContractServiceUserAlert[]>;
export type ContractServiceUserAlertResponse = IApiResponse<ContractServiceUserAlert>;

/* state */
export interface ContractServiceUserAlertsState {
  alerts: ContractServiceUserAlert[];
}
const initialState: ContractServiceUserAlertsState = { alerts: [] };

/* slice */
const contractContractServiceUserAlertsSlice = createSlice({
  name: 'serviceUserAlerts',
  initialState,
  reducers: {
    setContractServiceUserAlerts(state, action: PayloadAction<ContractServiceUserAlert[]>) {
      state.alerts = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchContractServiceUserAlerts =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    // HACK for some reason on fetch from POST, PUT, DELETE some items were being duplicated, this stops from happening
    // requires investigation
    await dispatch(setContractServiceUserAlerts([]));

    return getItems<ContractServiceUserAlertsResponse>(
      `${ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_ALERTS}?contractId=${contractId}&includeMasterList=true`
    ).then((response) => {
      const mappedResults = mapContractServiceUserAlertsFromServer(response.result || [], contractId);
      dispatch(setContractServiceUserAlerts(mappedResults));

      return mappedResults;
    });
  };

export const createContractServiceUserAlert =
  (serviceUserAlert: ContractServiceUserAlert): AppThunk =>
  async (dispatch) => {
    return postItems<ContractServiceUserAlertsRequest, ContractServiceUserAlertResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_ALERTS,
      serviceUserAlert
    ).then(
      () => {
        dispatch<any>(fetchContractServiceUserAlerts(serviceUserAlert.contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractServiceUserAlert =
  (serviceUserAlert: ContractServiceUserAlert): AppThunk =>
  async (dispatch) => {
    return putItem<ContractServiceUserAlertsRequest, ContractServiceUserAlertResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_ALERTS,
      serviceUserAlert,
      serviceUserAlert.id
    ).then(
      () => {
        dispatch<any>(fetchContractServiceUserAlerts(serviceUserAlert.contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractServiceUserAlert =
  (serviceUserAlert: ContractServiceUserAlert): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, ContractServiceUserAlertResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_ALERTS,
      serviceUserAlert.id
    ).then(
      () => {
        dispatch<any>(fetchContractServiceUserAlerts(serviceUserAlert.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractServiceUserAlerts } = contractContractServiceUserAlertsSlice.actions;

/* selectors */
export const selectServiceUserAlertsList = (state: RootState) => state.contract.serviceUser.serviceUserAlerts.alerts;

/* reducers */
export default contractContractServiceUserAlertsSlice.reducer;
