import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { fetchMasterServices } from 'features/masterLists/masterServices/masterServices.slice';
import { ApiId, IApiResponse, IDeleteRequest, mapApiErrors, showGenericErrorDialog } from 'millbrook-core';
import { deleteItems, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractReasonCodesFromServer } from '../fetch-mappers';
import { clearContractState } from '../overview/overview.slice';
import { ContractReasonCode, ContractReasonCodeRequest, ContractReasonCodeResponse } from './pricing.types';

interface ReasonCodesState {
  reasonCodeList: ContractReasonCode[];
}

const initialState: ReasonCodesState = { reasonCodeList: [] };

const contractReasonCodesSlice = createSlice({
  name: 'reasoncodes',
  initialState,
  reducers: {
    setContractReasonCodeList(state, action: PayloadAction<ContractReasonCode[]>) {
      state.reasonCodeList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// thunks for organisations
export const initContractReasonCodes =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return Promise.all([
      dispatch<any>(fetchContractReasonCodes(contractId)),
      // FIX: I think this should be the contract services, but is currently taking master services
      dispatch<any>(fetchMasterServices())
    ]).catch((error) => {
      const errorMessage = mapApiErrors(error);
      dispatch(showGenericErrorDialog(errorMessage));
      throw new Error(error);
    });
  };

export const fetchContractReasonCodes =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractReasonCode[]>>(ENDPOINTS.CONTRACT.PRICING.REASON_CODES(contractId)).then(
      (response) => {
        dispatch(setContractReasonCodeList(mapContractReasonCodesFromServer(response.result || [], contractId)));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createContractReasonCode =
  (data: ContractReasonCode): AppThunk =>
  async (dispatch) => {
    return postItems<ContractReasonCodeRequest, ContractReasonCodeResponse>(
      ENDPOINTS.CONTRACT.PRICING.REASON_CODE,
      data
    ).then(
      () => {
        dispatch<any>(fetchContractReasonCodes(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractReasonCode =
  (data: ContractReasonCode): AppThunk =>
  async (dispatch) => {
    return putItem<ContractReasonCode, ContractReasonCodeResponse>(
      ENDPOINTS.CONTRACT.PRICING.REASON_CODE,
      data,
      data.id
    ).then(
      () => {
        dispatch<any>(fetchContractReasonCodes(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractReasonCode =
  (data: ContractReasonCode): AppThunk =>
  async (dispatch) => {
    return deleteItems<IDeleteRequest, ContractReasonCodeResponse>(ENDPOINTS.CONTRACT.PRICING.REASON_CODE, [
      data.id
    ]).then(
      () => {
        dispatch<any>(fetchContractReasonCodes(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractReasonCodeList } = contractReasonCodesSlice.actions;

/* selectors */
export const selectContractReasonCodeList = (state: RootState) => state.contract.pricing.reasonCodes.reasonCodeList;

/* reducers */
export default contractReasonCodesSlice.reducer;
