import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IDeleteRequest, mapApiErrors } from 'millbrook-core';
import { deleteItems, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { PinOrganisation, PinOrganisationRequest, PinOrganisationResponse } from './budgetPin.types';

interface OrganisationsState {
  organisationList: PinOrganisation[];
}

const initialState: OrganisationsState = { organisationList: [] };

const organisationsSlice = createSlice({
  name: 'organisations',
  initialState,
  reducers: {
    setOrganisationList(state, action: PayloadAction<PinOrganisation[]>) {
      state.organisationList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

const cacheName = 'pin-organisations';

// thunks for organisations
export const fetchOrganisations =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PinOrganisationResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.ORGANISATIONS(contractId), {
      cacheName
    }).then(
      (response) => {
        dispatch(setOrganisationList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createOrganisation =
  (data: PinOrganisation): AppThunk =>
  async (dispatch) => {
    const { id, ...mappedData } = data;
    return postItems<PinOrganisationRequest, PinOrganisationResponse>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.ORGANISATIONS(data.contractId),
      mappedData,
      { cacheName }
    ).then(
      () => {
        dispatch<any>(fetchOrganisations(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateOrganisation =
  (data: PinOrganisation): AppThunk =>
  async (dispatch) => {
    return putItem<PinOrganisationRequest, PinOrganisationResponse>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.ORGANISATIONS(),
      data,
      data.id,
      { cacheName }
    ).then(
      () => {
        dispatch<any>(fetchOrganisations(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteOrganisations =
  (data: PinOrganisation[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.BUDGET_PIN.ORGANISATIONS(), ids, {
      enableGlobalErrorDialog: true,
      cacheName
    }).then(
      () => {
        dispatch<any>(fetchOrganisations(data[0].contractId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

/* actions */
export const { setOrganisationList } = organisationsSlice.actions;

/* selectors */
export const selectOrganisationList = (state: RootState) => state.contract.budgetPin.organisations.organisationList;

export const getOrganistionSelectOptions = createSelector([selectOrganisationList], (organisationList) => {
  return organisationList.map((organisation) => {
    return { label: organisation.name, value: organisation.id };
  });
});

/* reducers */
export default organisationsSlice.reducer;
