import { ENDPOINTS } from 'constants/api';
import { ApiId, HelpDocumentCategoryTreeResponseModel, IApiResponse, mapApiErrors } from 'millbrook-core';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk } from 'store/store';

/**
 * Thunks
 */

const cacheName = 'help-doc-cats';

export const fetchDocumentCategories = (): AppThunk => async (dispatch, getState) => {
  return getItems<IApiResponse<HelpDocumentCategoryTreeResponseModel[]>>(ENDPOINTS.HELP.HELP_DOCUMENT_CATEGORIES_TREE, {
    enableGlobalErrorDialog: true,
    cacheName
  }).then(
    (response) => {
      return response.result;
    }
    // enableGlobalErrorDialog is handling error message
    //    (response) => {
    //    }
  );
};

export const createDocumentCategory =
  (name: string, parentId?: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<{ name: string; parentId?: ApiId }, number>(
      ENDPOINTS.HELP.HELP_DOCUMENT_CATEGORIES_TREE,
      {
        name,
        parentId
      },
      { cacheName }
    ).catch((response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    });
  };

export const updateDocumentCategory =
  (id: ApiId, name: string): AppThunk =>
  async (dispatch, getState) => {
    return putItem<{ id: ApiId; name: string }, number>(
      ENDPOINTS.HELP.HELP_DOCUMENT_CATEGORIES_TREE,
      { id, name },
      id,
      { cacheName }
    ).catch((response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    });
  };

export const deleteDocumentCategory =
  (id: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ApiId, number>(ENDPOINTS.HELP.HELP_DOCUMENT_CATEGORIES_TREE, id, {
      enableGlobalErrorDialog: true,
      cacheName
    }).catch((response) => {
      // enableGlobalErrorDialog is handling error message
    });
  };

export const sortDocumentCategories =
  (ids: ApiId[]): AppThunk =>
  async (dispatch, getState) => {
    return putItem<ApiId[], number>(ENDPOINTS.HELP.HELP_DOCUMENT_CATEGORIES_TREE_SORT, ids, '', {
      enableGlobalErrorDialog: true,
      cacheName
    }).catch((response) => {
      // enableGlobalErrorDialog is handling error message
    });
  };
