import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
  [ContractSectionEnum.ManagePeripheralStore]: 'Manage peripheral stores',
  [ContractSectionEnum.ManagePeripheralStoreStock]: 'Manage peripheral store stock'
};

const route = ROUTES.CONTRACT.peripheralStores;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavPeripheralStoresSteps = (contractId: ApiId): ContractSection[] => [
  {
    step: ContractSectionEnum.LogoUpload,
    name: stepDisplayName[ContractSectionEnum.ManagePeripheralStore],
    href: route.stores(contractId)
  },
  {
    step: ContractSectionEnum.LogoUpload,
    name: stepDisplayName[ContractSectionEnum.ManagePeripheralStoreStock],
    href: route.manageStock(contractId, '')
  }
];
