import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
    [ContractSectionEnum.Retailers]: 'Retailers',
    [ContractSectionEnum.RetailerUsers]: 'Users'
};

const route = ROUTES.CONTRACT.prescriptionPortal;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavPrescriptionPortalSteps = (contractId: ApiId): ContractSection[] => [
    {
        step: ContractSectionEnum.Retailers,
        name: stepDisplayName[ContractSectionEnum.Retailers],
        href: route.retailers(contractId)
    },
    {
        step: ContractSectionEnum.RetailerUsers,
        name: stepDisplayName[ContractSectionEnum.RetailerUsers],
        href: route.retailUsers(contractId)
    }
];
