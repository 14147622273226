import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
    ApiId,
    BaseSearchPaging,
    CsvImportItem,
    CsvUploadResponse,
    DEFAULT_GUID,
    IApiResponse,
    IContractProduct,
    IContractProductNote,
    ImportCsvFormData,
    ISearchPagination,
    mapApiErrors,
    PagedContractProduct,
    PartialBy,
    ShelfTypeEnum,
    showGenericErrorDialog
} from 'millbrook-core';
import { ContractProductNoteFormData } from 'pages/Contract/Products/components/ContractProductNotes/ContractProductNoteForm.validation';
import { ContractProductFormData } from 'pages/Contract/Products/ContractProductDetails/ContractProductDetails.validation';
import qs from 'query-string';
import { getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractProductFormToRequest, mapRelatedProductTableToContractProduct } from '../mappers';
import { clearContractState } from '../overview/overview.slice';
import { fetchContractCleaningCodes } from '../scope/cleaningCodes.slice';

const CONTRACT_PRODUCT_CACHE = 'contract-product-cache';

/* types */
export interface ContractProductFilters extends ISearchPagination {
  searchTerm?: string; // partial match on sku and name. Could be extended to description as well if required
  sku?: string;
  name?: string;
  description?: string;
  tag?: string;
  minimumPrice?: number;
  maximumPrice?: number;
  categoryId?: ApiId;
  contractId: ApiId;
  includeMasterList?: boolean;
  onlyProductsOnContract?: boolean;
  exactSKU?: boolean;
  shelfTypes?: ShelfTypeEnum[];
  notAvailableToOrder?: boolean;
}

export type ContractProductRequest = PartialBy<IContractProduct, 'contractProductId'>;

export class ContractProductFiltersPaging extends BaseSearchPaging {
  // Put other parameters that will be exposed in the querystring here
}

export type ContractProductResponse = IApiResponse<IContractProduct>;
export type ContractProductsResponse = IApiResponse<PagedContractProduct>;
export type ContractProductNoteResponse = IApiResponse<IContractProductNote>;
export type ContractProductNotesResponse = IApiResponse<IContractProductNote[]>;

// csv upload
interface CSVProductUploadResponse extends Omit<CsvUploadResponse, 'imports'> {
  productImports: CsvImportItem[];
}

interface ContractProductsState {
  contractProducts: IContractProduct[];
  //  pinGroupFilters?: ContractProductFilters;
  categoryFilters?: ContractProductFilters;
  contractProductSearchResults?: IContractProduct[];
  //csvUploadResponse?: CsvUploadResponse;
}

const initialState: ContractProductsState = {
  contractProducts: []
};

const contractProductsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setContractProductsList(state, action: PayloadAction<IContractProduct[]>) {
      state.contractProducts = action.payload;
    },
    // updateContractProuctSearchFilters(state, action: PayloadAction<ContractProductFilters>) {
    //   state.pinGroupFilters = action.payload;
    // },
    // clearContractProuctSearchFilters(state) {
    //   state.pinGroupFilters = initialState.pinGroupFilters;
    // },
    setContractSearchFilters(state, action: PayloadAction<ContractProductFilters>) {
      state.categoryFilters = action.payload;
    },
    setContractSearchResults(state, action: PayloadAction<IContractProduct[]>) {
      state.contractProductSearchResults = action.payload;
    },
    invalidateContractSearchResults(state) {
      state.contractProductSearchResults = undefined;
    }
    // setContractProductCsvUpload(state, action: PayloadAction<CsvUploadResponse>) {
    //   state.csvUploadResponse = action.payload;
    // },
    // clearContractProductCsvUpload(state) {
    //   delete state.csvUploadResponse;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// actions
export const {
  setContractProductsList,
  setContractSearchResults,
  setContractSearchFilters,
  invalidateContractSearchResults
  //setContractProductCsvUpload,
  //clearContractProductCsvUpload
} = contractProductsSlice.actions;

// thunks
export const initContractProduct =
  (contractId: ApiId, contractProductId: ApiId): AppThunk =>
  async (dispatch) => {
    return Promise.all([
      dispatch<any>(fetchContractProduct(contractProductId, contractId)),
      dispatch<any>(fetchContractCleaningCodes(contractId, false))
    ])
      .then((responses) => {
        const product = responses[0];

        const { installationInformation, contractCleaningCodeId, defaultPurchasePrice, issueCost, ...rest } = product;

        const inContract = product.contractProductId !== DEFAULT_GUID;

        return {
          installationInformation: installationInformation ?? '',
          // set the issue cost to the master price if the product isn't in contract yet
          issueCost: inContract ? issueCost : defaultPurchasePrice,
          // check to see if the cleaning code is the default guid and set to '' if it is
          contractCleaningCodeId:
            !contractCleaningCodeId || contractCleaningCodeId === DEFAULT_GUID ? '' : contractCleaningCodeId,
          ...rest
        };
      })
      .catch((error) => {
        const errorMessage = mapApiErrors(error);
        dispatch(showGenericErrorDialog(errorMessage));
        throw new Error(error);
      });
  };

export const fetchContractProduct =
  (contractProductId: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ContractProductResponse>(
      ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT_DETAILS(contractProductId, contractId)
    ).then((response) => response.result || {});
  };

export const fetchContractProductNotes =
  (contractProductId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ContractProductNotesResponse>(
      ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT_NOTES(contractProductId)
    ).then((response) => response.result || {});
  };

export const fetchContractProducts =
  (filters: ContractProductFilters): AppThunk =>
  async (dispatch) => {
    const URL = `${ENDPOINTS.CONTRACT.PRODUCTS.PRODUCTS_FILTER}?${qs.stringify(
      { ...filters },
      {
        skipEmptyString: true
      }
    )}`;

    return getItems<ContractProductsResponse>(URL, { cacheName: CONTRACT_PRODUCT_CACHE }).then((response) => {
      // If we are fetching all, then you just want the data, if not, then you want to deal with the paging.
      return filters.fetchAll ? response.result?.data || [] : response.result;
    });
  };

export const createContractProduct =
  (data: ContractProductFormData, product: IContractProduct, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const mergedProductAndFormData = {
      ...product,
      ...data,
      closeTechnicalEquivalents: mapRelatedProductTableToContractProduct(product?.closeTechnicalEquivalents || []),
      relatedProducts: mapRelatedProductTableToContractProduct(product?.relatedProducts || [])
    };

    return postItems<ContractProductRequest, ContractProductResponse>(
      ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT,
      mapContractProductFormToRequest(mergedProductAndFormData, contractId),
      {
        enableGlobalErrorDialog: true,
        cacheName: CONTRACT_PRODUCT_CACHE
      }
    )
      .then(() => {
        dispatch(invalidateContractSearchResults());
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const updateContractProduct =
  (data: ContractProductFormData, product: IContractProduct, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const mergedProductAndFormData = {
      ...product,
      ...data,
      closeTechnicalEquivalents: mapRelatedProductTableToContractProduct(product?.closeTechnicalEquivalents || []),
      relatedProducts: mapRelatedProductTableToContractProduct(product?.relatedProducts || [])
      };

    return putItem<ContractProductRequest, ContractProductResponse>(
      ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT,
      mapContractProductFormToRequest(mergedProductAndFormData, contractId),
      product.contractProductId,
      { cacheName: CONTRACT_PRODUCT_CACHE }
    )
      .then(() => {
        dispatch(invalidateContractSearchResults());
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const importContractProducts =
  (contractId: ApiId, data: ImportCsvFormData): AppThunk =>
  async (dispatch) => {
    const { files } = data;
    var formData = new FormData();

    formData.append('contractId', contractId);
    for (let i = 0; i < files.length; i++) {
      formData.append('csvFile', files[i]);
    }

    return postItems<FormData, IApiResponse<CSVProductUploadResponse>>(
      ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT_IMPORT,
      formData
    ).then(
      (response) => {
        return response.result || {};
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createContractProductNote =
  (contractProductId: ApiId, data: ContractProductNoteFormData): AppThunk =>
  async () => {
    const postData = {
      contractProductId,
      ...data
    };

    return postItems<ContractProductNoteFormData, ContractProductNoteResponse>(
      ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT_NOTE,
      postData,
      {
        enableGlobalErrorDialog: true
      }
    )
      .then((response) => {
        return response.result || {};
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

// selectors
export const selectContractProductsList = (state: RootState) => state.contract.products.contractProducts;

export const selectContractSearchProducts = (state: RootState) => state.contract.products.contractProductSearchResults;

export const selectContractProductFilters = (state: RootState) => state.contract.products.categoryFilters;

//export const selectCsvUploadResponse = (state: RootState) => state.contract.products.csvUploadResponse;

//export const selectCsvUploadFeedback = createSelector([selectCsvUploadResponse], (response) => {});

export default contractProductsSlice.reducer;
