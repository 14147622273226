import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  ContractServiceChargePropertyTypeEnum,
  DEFAULT_GUID,
  IApiResponse,
  mapApiErrors,
  ServiceTypeCodeEnum
} from 'millbrook-core';
import { MultipleActivityFeesFormData } from 'pages/Contract/Pricing/ServiceCharges/Components/ContractServiceChargeForm/contractServiceCharge.validation';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import {
  ContractServiceCharge,
  ContractServiceCharges,
  MultipleActivityFees,
  ServiceChargeFormFieldFormatTypeEnum,
  SparePartsServiceChargeTypeEnum
} from './contractServiceCharges.types';

interface ServiceChargesState {
  serviceChargeList: ContractServiceCharge[];
  chargeMultipleActivityFees: boolean;
}

const initialState: ServiceChargesState = { serviceChargeList: [], chargeMultipleActivityFees: false };

const contractServiceChargesSlice = createSlice({
  name: 'serviceCharges',
  initialState,
  reducers: {
    setContractServiceCharges(state, action: PayloadAction<ContractServiceCharges>) {
      // work out the enabled flag
      const serviceCharges = action.payload.serviceCharges.map((serviceCharge) => {
        // this is somewhat a hack. The charges aren't actually "enabled", it's just that they have been saved/
        // previously it was only based on the id not being the default guid, but cleaning codes and ppm types need to be saved up front and the values are null.
        // so we are finding out which services have null or empty charges as well (zero isn't empty for normal charges). Speeds are coming back with "0" as the value and that is not counted as enabled.

        let enabled = false;

        // remove "0" from speeds
        serviceCharge.speedChargeFields.forEach((s) => (s.value = Number(s.value) ? s.value : ''));

        // Check the values if there is a Guid, if something has a value i.e. not empty or null, then it's considered enabled
        if (serviceCharge.id !== DEFAULT_GUID) {
          enabled = [...serviceCharge.normalChargeFields, ...serviceCharge.speedChargeFields].some(
            (charge) => charge.value !== null && charge.value !== ''
          );
        }

        // total hack. Put the speeds back from '' to zero if it is enabled
        enabled && serviceCharge.speedChargeFields.forEach((s) => (s.value = s.value || '0'));

        // do some specific things to certain fields
        // SPARE PARTS. Charge type is a radio
        if (serviceCharge.serviceTypeCode === ServiceTypeCodeEnum.SpareParts) {
          const { normalChargeFields } = serviceCharge;
          const chargeTypeField = normalChargeFields.find(
            (x) => x.propertyType === ContractServiceChargePropertyTypeEnum.SparePartsChargeType
          );

          if (chargeTypeField) {
            chargeTypeField.formatType = ServiceChargeFormFieldFormatTypeEnum.Radio;
            chargeTypeField.value = chargeTypeField.value || SparePartsServiceChargeTypeEnum.ChargeOnReceipt;
            chargeTypeField.options = [
              { label: 'Charge on receipt', value: SparePartsServiceChargeTypeEnum.ChargeOnReceipt },
              { label: 'Charge on issue', value: SparePartsServiceChargeTypeEnum.ChargeOnIssue }
            ];
          }
        }

        return { ...serviceCharge, enabled };
      });

      state.serviceChargeList = serviceCharges;
      state.chargeMultipleActivityFees = action.payload.chargeMultipleActivityFees;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// thunks
export const fetchContractServiceCharges =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractServiceCharges>>(ENDPOINTS.CONTRACT.PRICING.SERVICE_CHARGES(contractId)).then(
      (response) => {
        response.result && dispatch(setContractServiceCharges(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractServiceCharge =
  (contractId: ApiId, data: ContractServiceCharge): AppThunk =>
  async (dispatch) => {
    return postItems<ContractServiceCharge, IApiResponse<ContractServiceCharge[]>>(
      ENDPOINTS.CONTRACT.PRICING.SERVICE_CHARGES(contractId),
      data
    ).then(
      () => {
        dispatch<any>(fetchContractServiceCharges(contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractServiceChargeMultipleActivityFees =
  (contractId: ApiId, data: MultipleActivityFeesFormData): AppThunk =>
  async (dispatch) => {
    return putItem<MultipleActivityFees, IApiResponse<MultipleActivityFees>>(
      ENDPOINTS.CONTRACT.PRICING.SERVICE_CHARGES_MULTIPLE_ACTIVITY_FEES(contractId),
      {
        ...data,
        contractId
      }
    ).catch((response) => {
      const error = mapApiErrors(response);
      throw new Error(error);
    });
  };

/* actions */
export const { setContractServiceCharges } = contractServiceChargesSlice.actions;

/* selectors */
export const selectContractServiceChargeList = (state: RootState) =>
  state.contract.pricing.serviceCharges.serviceChargeList;
export const selectChargeMultipleActivityFees = (state: RootState) =>
  state.contract.pricing.serviceCharges.chargeMultipleActivityFees;

/* reducers */
export default contractServiceChargesSlice.reducer;
