import format from 'date-fns/format';
import { ContractReviewTypesRequest, ContractService } from 'features/contract/scope/scope.types';
import {
  ApiId,
  ContractProductLinkModel,
  ContractProductStatusDisplayName,
  ContractProductStatusEnum,
  UTC_DATE_FORMAT
} from 'millbrook-core';
import { WorkingHoursFormData } from 'pages/Contract/KeyDates/WorkingHours/components/WorkingDaysForm/WorkingHoursForm.validation';
import { ContractProductFormData } from 'pages/Contract/Products/ContractProductDetails/ContractProductDetails.validation';
import {
  HoldTimeFormData,
  HoldTimesSectionFormData
} from 'pages/Contract/RecycledSpecialProducts/HoldTimesSection/components/HoldTimesSectionForm';
import {
  PooledRspFormData,
  PooledRspContract
} from 'pages/Contract/RecycledSpecialProducts/PooledRspsSection/components/PooledRspsSectionForm';
import {
  ContractReviewTypeFormData,
  ContractReviewTypesFormData
} from 'pages/Contract/Scope/ReviewTypes/components/ContractReviewTypesForm';
import {
  ContractServiceUserGroupFormData,
  ContractServiceUserGroupsFormData
} from 'pages/Contract/ServiceUser/ServiceUserGroups/components/ContractServiceUserGroupsForm';
import { ContractDraftFormData } from 'pages/Contracts/ContractDraftPage/components/ContractDraftForm/ContractDraftForm.validation';
import { ContractInactiveReasonFormData, ContractInactiveReasonsFormData } from '../../pages/Contract/ServiceUser/InactiveReasons/components/ContractInactiveReasonsForm';
import { ContractDraftCreateRequest } from './contract.types';
import { WorkingHoursRequest } from './keyDates/workingHours.slice';
import { ContractProductRequest } from './products/contractProducts.slice';
import { HoldTimesRequest } from './recycledSpecialProducts/holdTimes.slice';
import { ContractServiceUserGroupsRequest } from './serviceUser/serviceUserGroups.slice';
import { ContractInactiveReasonsRequest } from './serviceUser/serviceUserInactiveReasons.slice';

// SAMPLE - FIND ITEMS ON CONTRACT
// generic function to find items of a specific type in an array, that have onContract flag
export function getItemsOnContract<T>(items?: T[]): T[] | undefined {
  return items?.filter((item: any) => item.onContract === true);
}

export const mapContractDraftFormToRequest = (contractDraft: ContractDraftFormData): ContractDraftCreateRequest => {
  return {
    ...contractDraft,
    // FIX - waiting on BE to remove depotIds and just use serviceCentreIds
    depotIds: contractDraft.serviceCentreIds,
    chargingMechanism: parseInt(contractDraft.chargingMechanism)
  };
};

export const mapWorkingHoursFormToRequest = (
  workingHours: WorkingHoursFormData,
  contractId: ApiId
): WorkingHoursRequest => {
  return workingHours.days.map<any>((day) => {
    return {
      ...day,
      start: format(new Date(day.start), UTC_DATE_FORMAT),
      stop: format(new Date(day.stop), UTC_DATE_FORMAT),
      contractId
    };
  });
};

export const mapContractServiceUserGroupsFormToRequest = (
  serviceUserGroupsList: ContractServiceUserGroupsFormData,
  contractId: ApiId
): ContractServiceUserGroupsRequest => {
  const selectedServiceUserGroups = getItemsOnContract<ContractServiceUserGroupFormData>(serviceUserGroupsList.groups);

  return {
    serviceUserGroupIds: (selectedServiceUserGroups || []).map((serviceUserGroup) => {
      return serviceUserGroup.serviceUserGroupId;
    }),
    contractId
  };
};

export const mapContractInactiveReasonsFormToRequest = (
    inactiveReasonsList: ContractInactiveReasonsFormData,
    contractId: ApiId
): ContractInactiveReasonsRequest => {
    const selectedInactiveReasons = getItemsOnContract<ContractInactiveReasonFormData>(inactiveReasonsList.reasons);

    return {
        inactiveReasonIds: (selectedInactiveReasons || []).map((inactiveReason) => {
            return inactiveReason.inactiveReasonId;
        }),
        contractId
    };
};

export const mapContractHoldTimesFormToRequest = (holdTimesList: HoldTimesSectionFormData): HoldTimesRequest => {
  const selectedHoldTimes = getItemsOnContract<HoldTimeFormData>(holdTimesList.holdTimes);

  return {
    holdTimeIds: (selectedHoldTimes || []).map((holdTime) => {
      return holdTime.id;
    }),
    hasHoldTimes: selectedHoldTimes ? selectedHoldTimes.length > 0 : false
  };
};

export const mapContractReviewTypesFormToRequest = (
  reviewTypesList: ContractReviewTypesFormData,
  contractId: ApiId
): ContractReviewTypesRequest => {
  const selectedReviewTypes = getItemsOnContract<ContractReviewTypeFormData>(reviewTypesList.reviewTypes);

  return {
    reviewTypeIds: (selectedReviewTypes || []).map((reviewType) => {
      return reviewType.masterReviewTypeId;
    }),
    contractId
  };
};

export const mapContractProductFormToRequest = (
  contractProduct: ContractProductFormData,
  contractId: ApiId
): ContractProductRequest => {
  const { isAvailableToOrder, deactivated } = contractProduct;
  let productStatus: ContractProductStatusEnum = ContractProductStatusEnum.Added;

  if (isAvailableToOrder) {
    productStatus = ContractProductStatusEnum.Orderable;
  } else if (deactivated) {
    productStatus = ContractProductStatusEnum.Inactive;
  }

  return {
    ...(contractProduct as any),
    contractProductStatus: {
      id: productStatus,
      description: ContractProductStatusDisplayName[productStatus]
    },
    contractId
  };
};

export const mapContractServiceFormDataToRequest = (data: ContractService) => {
  const { labels, selectedTrustedAssessmentTypeList, ...restOfData } = data;
  return {
    selectedTrustedAssessmentTypeList:
      selectedTrustedAssessmentTypeList === null ? [] : selectedTrustedAssessmentTypeList,
    ...restOfData
  };
};

export const mapRelatedProductTableToContractProduct = (products: ContractProductLinkModel[]) => {
  return (products || []).map((product) => ({
    contractProductId: product.contractProductId,
    priority: product.priority
  }));
};

export const mapPooledRspFormDataToRequest = (sharedContracts: PooledRspFormData, contractId: ApiId) => {
  const selectedContracts = getItemsOnContract<PooledRspContract>(sharedContracts.sharedWithContractIds);

  return {
    contractId,
    sharedWithContractIds: selectedContracts?.map((c) => c.id) || []
  };
};
