import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, mapApiErrors } from 'millbrook-core';
import { getItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { ContractAudit, ContractAuditResponse } from './auditHistory.types';

interface AuditHistoryState {
  auditHistoryList: ContractAudit[];
}

const initialState: AuditHistoryState = { auditHistoryList: [] };

const auditHistorySlice = createSlice({
  name: 'auditHistory',
  initialState,
  reducers: {
    setAuditHistoryList(state, action: PayloadAction<ContractAuditResponse[]>) {
      state.auditHistoryList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const fetchAuditHistory =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractAuditResponse[]>>(
      ENDPOINTS.CONTRACT.AUDIT_HISTORY.AUDIT_HISTORY_GET(contractId)
    ).then(
      (response) => {
        const mappedResults = (response.result || []).map((item) => ({
          ...item
        }));
        dispatch(setAuditHistoryList(mappedResults));
        return response;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setAuditHistoryList } = auditHistorySlice.actions;

/* selectors */
export const selectAuditHistory = (state: RootState) => state.contract.auditHistory.auditHistoryList;

/* reducers */
export const auditHistory = auditHistorySlice.reducer;
