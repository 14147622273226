import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, DEFAULT_GUID, IApiResponse, ISelectOption } from 'millbrook-core';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractServiceFormDataToRequest } from '../mappers';
import { clearContractState } from '../overview/overview.slice';
import { ContractService, ContractServiceRequest, ContractServiceResponse } from './scope.types';

interface ServicesState {
  serviceList: ContractService[];
  currentServiceId?: ApiId; // used in the speeds
}

const initialState: ServicesState = { serviceList: [] };

const contractServicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setContractServiceList(state, action: PayloadAction<ContractServiceResponse[]>) {
      state.serviceList = action.payload;
    },
    setCurrentServiceId(state, action: PayloadAction<ApiId | undefined>) {
      state.currentServiceId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

const cacheName = 'contract-services';

// thunks for organisations
export const fetchContractServices =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractServiceResponse[]>>(ENDPOINTS.CONTRACT.SCOPE.SERVICES_GET(contractId), {
      enableGlobalErrorDialog: true,
      cacheName
    }).then(
      (response) => {
        // REFACTOR - turn this in a map, although this is the only place it is used.
        const mappedResults = (response.result || []).map((item) => ({
          ...item,
          contractId,
          name: item.name || item.masterName || '',
          description: item.description || item.masterDescription || '',
          masterName: item.masterName || '',
          masterDescription: item.masterDescription || '',
          modified:
            (item.onContract || item.id !== DEFAULT_GUID) &&
            (item.name !== item.masterName || item.description !== item.masterDescription)
        }));
        dispatch(setContractServiceList(mappedResults));
        return mappedResults;
      },
      (response) => {
        // handled in global error dialog
        //const error = mapApiErrors(response);
        //throw new Error(error);
      }
    );
  };

export const createContractService =
  (data: ContractService): AppThunk =>
  async (dispatch) => {
    return postItems<ContractServiceRequest, ContractServiceResponse>(
      ENDPOINTS.CONTRACT.SCOPE.SERVICES,
      mapContractServiceFormDataToRequest(data),
      {
        enableGlobalErrorDialog: true,
        cacheName
      }
    ).then(
      () => {
        dispatch<any>(fetchContractServices(data.contractId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const updateContractService =
  (data: ContractService): AppThunk =>
  async (dispatch) => {
    if (data) {
      return putItem<ContractServiceRequest, ContractServiceResponse>(
        ENDPOINTS.CONTRACT.SCOPE.SERVICES,
        mapContractServiceFormDataToRequest(data),
        data.id,
        {
          enableGlobalErrorDialog: true,
          cacheName
        }
      ).then(
        () => {
          dispatch<any>(fetchContractServices(data.contractId));
        },
        () => {
          // handled with global error handler
        }
      );
    }
  };

/* actions */
export const { setContractServiceList, setCurrentServiceId } = contractServicesSlice.actions;

/* selectors */
export const selectContractServiceList = (state: RootState) => state.contract.scope.services.serviceList;
export const getContractServicesEnabled = createSelector([selectContractServiceList], (serviceList) => {
  return serviceList.filter((sl) => sl.onContract);
});

export const selectCurrentServiceId = (state: RootState) => state.contract.scope.services.currentServiceId;

export const getCurrentService = createSelector(
  [getContractServicesEnabled, selectCurrentServiceId],
  (list, selected) => {
    return list && selected ? list.find((item) => item.id === selected) : null;
  }
);

export const getContractServiceOptions = createSelector([getContractServicesEnabled], (list): ISelectOption[] => {
  return list?.map((item) => {
    return { label: item.name, value: item.id };
  });
});

/* reducers */
export default contractServicesSlice.reducer;
