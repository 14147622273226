import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors, PartialBy } from 'millbrook-core';
import { ContractServiceUserFieldsFormData } from 'pages/Contract/ServiceUser/ServiceUserFields/components/ContractServiceUserFieldsForm/ContractServiceUserFieldsForm';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

// types
export interface ContractServiceUserFields {
    id: ApiId;
    contractId?: ApiId;
    landlineMandatory: boolean;
    mobileMandatory: boolean;
    emailAddressMandatory: boolean;
    additionalContactsMandatory: boolean;
    languageMandatory: boolean;
    heightMandatory: boolean;
    weightMandatory: boolean;
    clientAlertsMandatory: boolean;
    gpCodeMandatory: boolean;
    nhsNumberMandatory: boolean;
    socialCareNumberMandatory: boolean;
    healthCareNumberMandatory: boolean;
    tenancyMandatory: boolean;
    propertyOwnershipTypeMandatory: boolean;
}

export type ContractServiceUserFieldsRequest = PartialBy<ContractServiceUserFields, 'id'>;

export type ContractServiceUserFieldsResponse = IApiResponse<ContractServiceUserFields>;

export type ContractServiceUserFieldsState = ContractServiceUserFields;

export interface AdditionalServiceUserField {
    id: ApiId;
    contractId: ApiId;
    fieldName: string;
    mandatory: boolean;
}

export interface AdditionalServiceUserFieldsRequest extends Omit<AdditionalServiceUserField, 'id'> {
    id?: ApiId;
}
export type AdditionalServiceUserFieldsResponse = IApiResponse<AdditionalServiceUserField[]>;

const initialState: ContractServiceUserFieldsState = {
    id: '',
    landlineMandatory: false,
    mobileMandatory: false,
    emailAddressMandatory: false,
    additionalContactsMandatory: false,
    languageMandatory: false,
    heightMandatory: false,
    weightMandatory: false,
    clientAlertsMandatory: false,
    gpCodeMandatory: false,
    nhsNumberMandatory: false,
    socialCareNumberMandatory: false,
    healthCareNumberMandatory: false,
    tenancyMandatory: false,
    propertyOwnershipTypeMandatory: false
};

const contractServiceUserFieldsSlice = createSlice({
    name: 'serviceUserFields',
    initialState,
    reducers: {
        setContractServiceUserFields(state, action: PayloadAction<ContractServiceUserFields | undefined>) {
            if (action.payload) {
                state.id = action.payload.id;
                state.landlineMandatory = action.payload.landlineMandatory;
                state.mobileMandatory = action.payload.mobileMandatory;
                state.emailAddressMandatory = action.payload.emailAddressMandatory;
                state.additionalContactsMandatory = action.payload.additionalContactsMandatory;
                state.languageMandatory = action.payload.languageMandatory;
                state.heightMandatory = action.payload.heightMandatory;
                state.weightMandatory = action.payload.weightMandatory;
                state.clientAlertsMandatory = action.payload.clientAlertsMandatory;
                state.gpCodeMandatory = action.payload.gpCodeMandatory;
                state.nhsNumberMandatory = action.payload.nhsNumberMandatory;
                state.socialCareNumberMandatory = action.payload.socialCareNumberMandatory;
                state.healthCareNumberMandatory = action.payload.healthCareNumberMandatory;
                state.tenancyMandatory = action.payload.tenancyMandatory;
                state.propertyOwnershipTypeMandatory = action.payload.propertyOwnershipTypeMandatory;
            }
        },
        // TO DO add payload action type
        setAdditionalServiceUserFields(state, action: PayloadAction<any>) {
            /*
              AK - hit error typescript error while adding additionalServiceUserFields state
            */
            //state.additionalServiceUserFields = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearContractState, () => {
            return initialState;
        });
    }
});

// actions
export const { setContractServiceUserFields, setAdditionalServiceUserFields } = contractServiceUserFieldsSlice.actions;

// thunks
export const fetchContractServiceUserFields =
    (contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return getItems<ContractServiceUserFieldsResponse>(
                `${ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_FIELDS}/${contractId}`
            ).then((response) => {
                dispatch(setContractServiceUserFields(response.result));
            });
        };

export const fetchAdditionalServiceUserFields =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return getItems<AdditionalServiceUserFieldsResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.ADDITIONAL_SERVICE_USER_FIELDS(contractId)
            ).then(
                (response) => {
                    dispatch(setAdditionalServiceUserFields(response.result || []));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const createContractServiceUserFields =
    (serviceUserFields: ContractServiceUserFieldsFormData, contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return postItems<ContractServiceUserFieldsRequest, ContractServiceUserFieldsResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_FIELDS,
                { ...(serviceUserFields as any), contractId }
            ).then(
                () => {
                    dispatch<any>(fetchContractServiceUserFields(contractId));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateContractServiceUserFields =
    (serviceUserFields: ContractServiceUserFieldsFormData, id: ApiId, contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return putItem<ContractServiceUserFieldsRequest, ContractServiceUserFieldsResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_FIELDS,
                { ...(serviceUserFields as any), contractId, id },
                id
            ).then(
                () => {
                    dispatch<any>(fetchContractServiceUserFields(contractId));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

// selectors
export const selectContractServiceUserFields = (state: RootState) => state.contract.serviceUser.serviceUserFields;

export default contractServiceUserFieldsSlice.reducer;
