import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, mapApiErrors, ContractPPMType } from 'millbrook-core';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractPPMTypesFromServer } from '../fetch-mappers';
import { clearContractState } from '../overview/overview.slice';
import { ContractPPMTypeRequest, ContractPPMTypeResponse } from './scope.types';

interface PPMTypesState {
  ppmTypesList: ContractPPMType[];
}

const initialState: PPMTypesState = { ppmTypesList: [] };

const contractPPMTypesSlice = createSlice({
  name: 'ppmTypes',
  initialState,
  reducers: {
    setContractPPMTypesList(state, action: PayloadAction<ContractPPMTypeResponse[]>) {
      state.ppmTypesList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const fetchContractPPMTypes =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ContractPPMTypeResponse[]>>(ENDPOINTS.CONTRACT.SCOPE.PPM_TYPES_GET(contractId)).then(
      (response) => {
        const mappedResults = mapContractPPMTypesFromServer(response.result || [], contractId);

        dispatch(setContractPPMTypesList(mappedResults));
        return mappedResults;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createContractPPMType =
  (data: ContractPPMType): AppThunk =>
  async (dispatch) => {
    return postItems<ContractPPMTypeRequest, ContractPPMTypeResponse>(ENDPOINTS.CONTRACT.SCOPE.PPM_TYPES, data).then(
      (response: any) => {
        dispatch<any>(fetchContractPPMTypes(data.contractId));
      },
      (response: any) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractPPMType =
  (data: ContractPPMType): AppThunk =>
  async (dispatch) => {
    return putItem<ContractPPMTypeRequest, ContractPPMTypeResponse>(
      ENDPOINTS.CONTRACT.SCOPE.PPM_TYPES,
      data,
      data.id
    ).then(
      (response) => {
        dispatch<any>(fetchContractPPMTypes(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractPPMType =
  (data: ContractPPMType): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, ContractPPMTypeResponse>(ENDPOINTS.CONTRACT.SCOPE.PPM_TYPES, data.id).then(
      (response) => {
        dispatch<any>(fetchContractPPMTypes(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractPPMTypesList } = contractPPMTypesSlice.actions;

/* selectors */
export const selectContractPPMTypeList = (state: RootState) => state.contract.scope.ppmTypes.ppmTypesList;

/* reducers */
export default contractPPMTypesSlice.reducer;
