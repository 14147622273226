import { ApiId, ContractServiceChargePropertyTypeEnum, ISelectOption, ServiceTypeCodeEnum } from 'millbrook-core';

//#region types

export interface MultipleActivityFees {
  contractId: ApiId;
  chargeMultipleActivityFees: boolean;
}

export interface ServiceChargeField {
  id: ApiId;
  propertyType: ContractServiceChargePropertyTypeEnum;
  propertyTypeName: string;
  label: string;
  value: string;
  formatType: ServiceChargeFormFieldFormatTypeEnum;

  // these are specific fields used for cleaning codes and ppm types. They will be null, or should contain the contract id for those specific types in the services
  contractCleaningCodeId: ApiId | null;
  contractPPMTypeId: ApiId | null;

  // This is not used, just using the order as it comes down the wire. It may get refactored on backend to not include it in the response
  displayOrder: number;

  // options. FE only, will be used for specific fields (like spare parts issue charge). ISelectOption will cover checkbox, radio and dropdown.
  options?: ISelectOption[];
}

export interface ServiceChargeNormalField extends ServiceChargeField {
  infoText: string;
  serviceChargePropertyDefinitionId: ApiId;
}

export interface ServiceChargeSpeedField extends ServiceChargeField {
  contractServiceSpeedId: ApiId;
}

export interface ContractServiceCharge {
  id: ApiId;
  contractServiceTypeId: ApiId;
  serviceTypeCode: ServiceTypeCodeEnum;
  serviceTypeName: string;
  normalChargeFields: ServiceChargeNormalField[];
  speedChargeFields: ServiceChargeSpeedField[];
  // REFACTOR: Charge type array is included in the api, but don't think it will be needed? Refactor BE if not required
  chargeType: ContractServiceChargeTypeEnum;

  // derived, not from api
  enabled: boolean;
}

export interface ContractServiceCharges {
  chargeMultipleActivityFees: boolean;
  serviceCharges: ContractServiceCharge[];
}

//#endregion types

//#region enums

// special enums for selected services
// everything is coming down and going up the wire as string, so may as well keep it that way
// These are enums here, but using them as values in the form radio groups
export enum PPMServiceChargeTypeEnum {
  PerPpmType = '1',
  PerPpmTypeAndSpeed = '2',
  PerPpmTypeWithDiscount = '3'
}

export enum SparePartsServiceChargeTypeEnum {
  ChargeOnReceipt = '1',
  ChargeOnIssue = '2'
}

export enum ProcurementServiceChargeTypeEnum {
  Fixed = '1',
  Percentage = '2'
}

// other enums

// This is not direct copy from backend, it is sending the description, so will map to that
export enum ServiceChargeFormFieldFormatTypeEnum {
  None = 'None',
  Text = 'Text',
  Numeric = 'Numeric',
  Money = 'Money',
  Percentage = 'Percentage',
  Boolean = 'Boolean',

  // added for FE only, is set in the slice
  Radio = 'Radio'
}

// REFACTOR: If this isn't used then remove it
export enum ContractServiceChargeTypeEnum {
  //    [Description("None")]
  None = 0,

  //    [Description("Normal charge and charge by speed")]
  NormalChargeAndChargeBySpeed = 1,

  //    [Description("Normal charge and discount for multiple")]
  NormalChargeAndDiscountForMultiple = 2,

  //    [Description("Normal charge")]
  NormalCharge = 3,

  //    [Description("Charge by speed")]
  ChargeBySpeed = 4,

  //    [Description("Charge set at product level")]
  ChargeSetAtProductLevel = 5
}

//#endregion enums
