import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { IApiResponse, mapApiErrors } from 'millbrook-core';
import { ManageUserFormData } from 'pages/ManageUsersPage/components/UserForm/ManageUserForm.validation';
import { getItems, postItems, putItem } from 'services/api.service';
import { User } from 'services/user.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export type UsersListResponse = IApiResponse<User[]>;

export interface ManageUserRequest extends Omit<ManageUserFormData, 'lockoutEnabled'> {
    lockoutEnabled: boolean;
}

/* state */
interface ManageUsersState {
    users: User[];
}

const initialState: ManageUsersState = {
    users: []
};

/* slice */
const ManageUsersSlice = createSlice({
    name: 'ManageUsers',
    initialState,
    reducers: {
        updateUserList(state, action: PayloadAction<User[]>) {
            state.users = action.payload;
        }
    }
});

/* thunks */
export const getUserList =
    (showRevoked?: boolean): AppThunk => async (dispatch) => {
        return getItems<UsersListResponse>(
            `${ENDPOINTS.USERS}?showRevoked=${showRevoked == null ? false : showRevoked}`,
            { enableGlobalErrorDialog: true }
        ).then(
            (response) => {
                dispatch(updateUserList(response.result || []));
            },
            (response) => {
                const error = mapApiErrors(response);
                throw new Error(error);
            }
        );
    };

export const saveUserAction =
    (newUser: ManageUserRequest): AppThunk =>
        async (dispatch, getState) => {
            return postItems<ManageUserRequest, number>(ENDPOINTS.USERS, newUser).then(
                (response) => {
                    dispatch(getUserList());
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateUserAction =
    (user: ManageUserRequest): AppThunk =>
        async (dispatch, getState) => {
            return putItem<ManageUserRequest, number>(ENDPOINTS.USERS, user, user.id).then(
                (response) => {
                    dispatch(getUserList());
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

/* actions */
export const { updateUserList } = ManageUsersSlice.actions;

/* selectors */
export const selectUsers = (state: RootState) => state.manageUsers.users;

/* reducers */
export default ManageUsersSlice.reducer;
