import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, mapApiErrors, IMediaModel, PrescriberLogoResponse } from 'millbrook-core';
import { getItems, postItems, putItem, deleteItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { PrescriberLogoFormData } from 'pages/Contract/PrescriberPortal/PrescriberLogo/Components/PrescriberLogoForm/prescriberLogoForm.validation';
import { clearContractState } from '../overview/overview.slice';

interface PrescriberLogoState {
  uploadedPrescriberLogo?: IMediaModel[];
}

const initialState: PrescriberLogoState = {
  uploadedPrescriberLogo: []
};

const prescriberLogoSlice = createSlice({
  name: 'prescriberLogo',
  initialState,
  reducers: {
    setPrescriberLogo(state, action: PayloadAction<IMediaModel[]>) {
      state.uploadedPrescriberLogo = action.payload;
    },
    removePrescriberLogo(state) {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// thunks
export const fetchPrescriberLogo =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PrescriberLogoResponse>(ENDPOINTS.PRESCRIBER_LOGO_GET(contractId), {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(setPrescriberLogo(response.result || []));
      },
      () => {
        // handled with global error handler
      }
    );
  };

// thunks
export const uploadPrescriberLogo =
  (contractId: ApiId, data: PrescriberLogoFormData): AppThunk =>
  async (dispatch, getState) => {
    let formData = new FormData();
    formData.append('FormFile', data.prescriberLogo[0]);
    formData.append('contractId', contractId);

    return postItems<FormData, PrescriberLogoResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.PRESCRIBER_LOGO,
      formData
    ).then(
      (response) => {
        dispatch(setPrescriberLogo(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updatePrescriberLogo =
  (data: PrescriberLogoFormData, uploadedPrescriberLogoId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    let formData = new FormData();
    formData.append('FormFile', data.prescriberLogo[0]);

    return putItem<FormData, PrescriberLogoResponse>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.PRESCRIBER_LOGO,
      formData,
      uploadedPrescriberLogoId
    ).then(
      (response) => {
        dispatch(setPrescriberLogo(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deletePrescriberLogo =
  (id: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ApiId, any>(ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.PRESCRIBER_LOGO, id).then(
      () => {
        dispatch(removePrescriberLogo());
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setPrescriberLogo, removePrescriberLogo } = prescriberLogoSlice.actions;

/* selectors */
export const selectPrescriberLogo = (state: RootState) =>
  state.contract.prescriberPortal.prescriberLogo.uploadedPrescriberLogo || [];

/* reducers */
export default prescriberLogoSlice.reducer;
