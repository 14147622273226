import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { BASE_MASTER_PRODUCTS_SEARCH } from 'constants/pagination';
import { fetchCategoryAction } from 'features/manageCategories/manageCategories.slice';
import {
    ApiId,
    CreateMasterProductFormData,
    IApiPagination,
    IApiResponse,
    IDeleteRequest,
    IDocumentModel,
    IMasterProduct,
    IMasterProductSummary,
    ISearchPagination,
    IServiceCentreSummary,
    mapApiErrors,
    ProductPropertyDefinition,
    ShelfTypeEnum,
    UploadFile360
} from 'millbrook-core';
import { ProductFormData } from 'pages/ManageProductsPage/components/ProductForm/ProductForm.validation';
import qs from 'query-string';
import { deleteItems, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapProduct360ImagesToUploadRequest, mapProductDataToCreateRequest, mapProductImagesToUploadRequest } from './mappers';

/* types */
export interface MasterProductSearch extends ISearchPagination {
  name?: string;
  sku?: string;
  searchTerm?: string;
  shelfTypes?: ShelfTypeEnum[];
  supplierId?: ApiId;
  unmappedToSupplier?: boolean;
  isAvailableToProcure?: boolean;
  exactSKU?: boolean;
}

export type ProductInitialDataResponse = IApiResponse<CreateMasterProductFormData>;
export type ProductPropertiesResponse = IApiResponse<ProductPropertyDefinition[]>;

export type MasterProductDataRequest = Omit<IMasterProduct, 'productImages' | 'productListingImageId' | 'ppmTypes'>;
export type MasterProductCreateRequest = FormData;

export type MasterProductResponse = IApiResponse<IMasterProduct>;
export type PagedMasterProducts = IApiPagination<IMasterProductSummary[]>;
export type ProductsResponse = IApiResponse<PagedMasterProducts>;
export type ProductImagesUploadRequest = FormData;
export type ProductImagesDeleteRequest = IDeleteRequest;
export type ProductImagesResponse = IApiResponse<ApiId>;

export type MasterProductUpdateResponse = null;
export type MasterProductDocumentsDeleteRequest = IDeleteRequest;

const PRODUCTS_CACHE = 'products';
const LITERATURE_CACHE = 'productliterature';

/* state */
interface ManageProductsState {
  filters: MasterProductSearch;
  existingFiles: object[];
  selectedProduct?: IMasterProduct;
  documents?: ProductLiteratureDocument[];
  documentToEdit?: ProductLiteratureDocument;
  syncLog?: ProductSyncLog;
}

export enum ProductSyncStatusEnum {
  NotSynced,
  Synced,
  ProductOnly,
  ConfigOnly,
  RetryFailed
}

export const ProductSyncStatusDisplay = new Map<ProductSyncStatusEnum, string>([
  [ProductSyncStatusEnum.NotSynced, 'Not synced'],
  [ProductSyncStatusEnum.Synced, 'Synced'],
  [ProductSyncStatusEnum.ProductOnly, 'Config not synced'],
  [ProductSyncStatusEnum.ConfigOnly, 'Product not synced'],
  [ProductSyncStatusEnum.RetryFailed, 'Retry failed']
]);

interface ProductSyncLog {
  serviceCentres: ProductSyncLogEntry[];
}

export interface ProductSyncLogEntry {
  serviceCentre: IServiceCentreSummary;
  status: ProductSyncStatusEnum;
  retryAttempts: number;
  maxAttempts: number;
}

interface LiteratureDocumentModel extends IDocumentModel {
  productId: ApiId;
}

export type ContractDocumentCreateRequest = FormData;
export type ProductLiteratureDocument = LiteratureDocumentModel;

const initialState: ManageProductsState = {
  filters: BASE_MASTER_PRODUCTS_SEARCH,
  existingFiles: [],
  documents: []
};

/* slice */
const ManageProductsSlice = createSlice({
  name: 'ManageProducts',
  initialState,
  reducers: {
    updateSearchFilters(state, action: PayloadAction<MasterProductSearch>) {
      state.filters = action.payload;
    },
    clearSearchFilters(state) {
      state.filters = initialState.filters;
    },
    updateSelectedProduct(state, action: PayloadAction<IMasterProduct | undefined>) {
      state.selectedProduct = action.payload;
    },
    updateSelectProductSpareParts(state, action: PayloadAction<IMasterProductSummary[]>) {
      state.selectedProduct && (state.selectedProduct.linkedSpares = action.payload);
    },
    clearProduct(state) {
      state.selectedProduct = initialState.selectedProduct;
      state.documents = initialState.documents;
      state.documentToEdit = initialState.documentToEdit;
    },
    setProductDocuments(state, action: PayloadAction<ProductLiteratureDocument[]>) {
      state.documents = action.payload;
    },
    setProductDocumentToEdit(state, action: PayloadAction<ProductLiteratureDocument | undefined>) {
      state.documentToEdit = action.payload;
    },
    setProductSyncStatus(state, action: PayloadAction<ProductSyncLog | undefined>) {
      state.syncLog = action.payload;
    }
  }
});

/* thunks */
export const initialiseProductFormData =
  (productId?: ApiId): AppThunk =>
  async (dispatch, getState) => {
    // not using Promise.all for these items, because they block the initial page loading and can happen independently
    dispatch<any>(fetchCategoryAction()); // 1: gets the product categories, goes to a slice
    productId && dispatch<any>(fetchMasterProductDocuments(productId)); // 2: gets the product documents, goes to a slice
    productId && dispatch<any>(fetchProductSyncStatus(productId)); // 3: gets the product sync state, goes to a slice

    return Promise.all([
      dispatch<any>(fetchMasterProductInitialData()), // 0: returns the initial data from the api
      productId && dispatch<any>(fetchMasterProduct(productId)) // 1: gets the product, goes to a slice
    ]).then((response: [CreateMasterProductFormData, undefined]) => {
      return response[0];
    });
  };

export const fetchMasterProductInitialData = (): AppThunk => async (dispatch, getState) => {
  return getItems<ProductInitialDataResponse>(ENDPOINTS.PRODUCTS.PRODUCTS_INITIAL_DATA, {
    //cacheName: PRODUCTS_CACHE
  }).then((response) => {
    return response.result;
  });
};

export const fetchMasterProducts =
  (searchFilters: MasterProductSearch = {}): AppThunk =>
  async (dispatch, getState) => {
    const URL = qs.stringifyUrl(
      {
        url: ENDPOINTS.PRODUCTS.PRODUCTS,
        query: {
          ...BASE_MASTER_PRODUCTS_SEARCH,
          ...searchFilters
        }
      },
      { skipEmptyString: true }
    );

    return getItems<ProductsResponse>(URL).then((response) => {
      return response.result;
    });
  };

export const fetchMasterProduct =
  (id: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<MasterProductResponse>(ENDPOINTS.PRODUCTS.PRODUCT(id), { enableGlobalErrorDialog: true }).then(
      (response) => {
        dispatch(updateSelectedProduct(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchMasterProductProperties =
  (productTypeId: string): AppThunk =>
  async (dispatch, getState) => {
    if (productTypeId) {
      return getItems<ProductPropertiesResponse>(ENDPOINTS.PRODUCTS.PRODUCT_SPEC(productTypeId), {
        enableGlobalErrorDialog: true,
        cacheName: PRODUCTS_CACHE
      }).then(
        (response) => {
          if (response.result) {
            const mappedResults: ProductPropertyDefinition[] = (response.result || []).map(
              (r: ProductPropertyDefinition) => ({
                ...r,
                propertyDefinitionId: r.id || '',
                propertyValueId: '',
                value: ''
              })
            );

            return mappedResults;
          }
        },
        () => {
          // handled with global error handler
        }
      );
    }
  };

export const createMasterProduct =
  (data: ProductFormData): AppThunk =>
  async (dispatch, getState) => {
    const mappedProductData = mapProductDataToCreateRequest(data);

    return postItems<MasterProductCreateRequest, MasterProductResponse>(
      ENDPOINTS.PRODUCTS.PRODUCTS,
      mappedProductData,
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateMasterProduct =
  (data: IMasterProduct): AppThunk =>
  async (dispatch, getState) => {
    const { productImages, ...requestData } = data;

    return putItem<MasterProductDataRequest, object>(
      ENDPOINTS.PRODUCTS.PRODUCTS,
      requestData,
      requestData.masterProductId,
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(fetchMasterProduct(data.masterProductId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteMasterProductImages =
  (id: string, imageIds: ApiId[]): AppThunk =>
  async (dispatch, getState) => {
    return deleteItems<ProductImagesDeleteRequest, any>(ENDPOINTS.PRODUCTS.PRODUCT_IMAGES(id), imageIds, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchMasterProduct(id));
      },
      () => {
        // handled with global error handler
      }
    );
        };

export const deleteMasterProduct360Images =
    (id: string): AppThunk =>
        async (dispatch) => {
            return deleteItems<ApiId, any>(ENDPOINTS.PRODUCTS.PRODUCT_360_IMAGES(id), id, {
                enableGlobalErrorDialog: true
            }).then(
                () => {
                    dispatch<any>(fetchMasterProduct(id));
                },
                () => {
                    // handled with global error handler
                }
            );
        };

export const uploadMasterProductImages =
  (id: ApiId, fileListData: Blob[]): AppThunk =>
  async (dispatch, getState) => {
    return postItems<ProductImagesUploadRequest, ProductImagesResponse>(
      ENDPOINTS.PRODUCTS.PRODUCT_IMAGES(id),
      mapProductImagesToUploadRequest(fileListData),
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        return response.result || false;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
        };

export const uploadMasterProduct360Images =
    (id: ApiId, fileListData: UploadFile360[]): AppThunk =>
        async (dispatch, getState) => {
            return postItems<ProductImagesUploadRequest, ProductImagesResponse>(
                ENDPOINTS.PRODUCTS.PRODUCT_360_IMAGES(id),
                mapProduct360ImagesToUploadRequest(fileListData),
                {
                    enableGlobalErrorDialog: true
                }
            ).then(
                (response) => {
                    return response.result || false;
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchMasterProductDocuments =
  (productId: string): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ProductLiteratureDocument[]>>(ENDPOINTS.PRODUCTS.DOCUMENTS(productId), {
      disableFullPageLoader: true,
      cacheName: LITERATURE_CACHE
    }).then((response) => {
      dispatch(setProductDocuments(response.result || []));
    });
  };

export const fetchProductSyncStatus =
  (productId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ProductSyncLog>>(ENDPOINTS.PRODUCTS.SYNC_STATUS(productId), {
      disableFullPageLoader: true
    }).then((response) => {
      dispatch(setProductSyncStatus(response.result));
    });
  };

export const retryProductServiceCentreSync =
  (productId: ApiId, locationId?: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<null, any>(ENDPOINTS.PRODUCTS.SYNC_STATUS(productId, locationId), null, {
      disableFullPageLoader: true,
      enableGlobalErrorDialog: true
    })
      .then((response) => {
        dispatch(fetchProductSyncStatus(productId));
      })
      .catch(() => {
        // handled in global error dialog
      });
  };

export const createMasterProductDocument =
  (productId: ApiId, files: Blob[]): AppThunk =>
  async (dispatch) => {
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return postItems<ContractDocumentCreateRequest, number>(ENDPOINTS.PRODUCTS.DOCUMENTS(productId), formData, {
      cacheName: LITERATURE_CACHE,
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchMasterProductDocuments(productId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const updateMasterProductDocument =
  (data: ProductLiteratureDocument, productId: ApiId): AppThunk =>
  async (dispatch) => {
    var formData = new FormData();
    formData.append('name', data.name);

    return putItem<FormData, MasterProductUpdateResponse>(`${ENDPOINTS.PRODUCTS.PUT_DOCUMENTS}`, formData, data.id, {
      cacheName: LITERATURE_CACHE,
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchMasterProductDocuments(productId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const deleteMasterProductDocument =
  (productId: ApiId, data: ProductLiteratureDocument[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);

    return deleteItems<MasterProductDocumentsDeleteRequest, MasterProductUpdateResponse>(
      ENDPOINTS.PRODUCTS.DELETE_DOCUMENTS,
      ids,
      {
        enableGlobalErrorDialog: true,
        cacheName: LITERATURE_CACHE
      }
    ).then(
      () => {
        dispatch<any>(fetchMasterProductDocuments(productId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

/* actions */
export const {
  updateSearchFilters,
  clearSearchFilters,
  updateSelectedProduct,
  updateSelectProductSpareParts,
  clearProduct,
  setProductDocuments,
  setProductDocumentToEdit,
  setProductSyncStatus
} = ManageProductsSlice.actions;

/* selectors */
export const selectSelectedProduct = (state: RootState) => {
  return state.manageProducts.selectedProduct;
};

export const selectMasterProductsSearchFilters = (state: RootState) => state.manageProducts.filters;

export const selectProductDocuments = (state: RootState) => state.manageProducts.documents;

export const selectProductDocumentToEdit = (state: RootState) => state.manageProducts.documentToEdit;

export const selectProductSyncLog = (state: RootState) => state.manageProducts.syncLog;

/* reducer */
export default ManageProductsSlice.reducer;
