import { combineReducers } from '@reduxjs/toolkit';
import { auditHistory } from 'features/contract/auditHistory/auditHistorySlice';
import { budgetPin } from 'features/contract/budgetPin/budgetPin.slice';
import navigation from 'features/contract/builder/contractNav.slice';
import { keyDates } from 'features/contract/keyDates/keyDates.slice';
import { overview } from 'features/contract/overview/overview.slice';
import peripheralStores from 'features/contract/peripheralStores/peripheralStores.slice';
import { prescriberPortal } from 'features/contract/prescriberPortal/prescriberPortal.slice';
import { pricing } from 'features/contract/pricing/pricing.slice';
import products from 'features/contract/products/contractProducts.slice';
import { recycledSpecialProducts } from 'features/contract/recycledSpecialProducts/recycledSpecialProducts.slice';
import { scope } from 'features/contract/scope/scope.slice';
import { serviceUser } from 'features/contract/serviceUser/serviceUser.slice';
import { prescriptionPortal } from 'features/contract/prescriptionPortal/prescriptionPortal.slice';

export const contract = combineReducers({
    navigation,
    overview,
    keyDates,
    budgetPin,
    scope,
    pricing,
    prescriberPortal,
    products,
    peripheralStores,
    serviceUser,
    recycledSpecialProducts,
    auditHistory,
    prescriptionPortal
});
