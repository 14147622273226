import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import activity from 'features/activities/activity.slice';
import assessments from 'features/assessments/assessments.slice';
import { contract } from 'features/contract/contract.slice';
import contracts from 'features/contracts/contracts.slice';
import manageCategories from 'features/manageCategories/manageCategories.slice';
import manageProducts from 'features/manageProducts/manageProducts.slice';
import manageUsers from 'features/manageUsers/manageUsersSlice';
import masterLists from 'features/masterLists/masterLists.slice';
import procurement from 'features/procurement/procurement.slice';
import warehouse from 'features/warehouse/warehouse.slice';
import { baseRootReducers } from 'millbrook-core';

/* reducers */
// <<<<<<< HEAD
// const createRootReducer = (history: History<LocationState>) =>
//   combineReducers({
//     router: connectRouter(history),
//     ui,
//     auth: authReducer,
//     masterLists,
//     contracts,
//     contract,
//     manageUsers,
//     manageCategories,
//     manageProducts,
//     procurement,
//     warehouse,
//     activity,
//     assessments
//   });
// =======
const allReducers = combineReducers({
  ...baseRootReducers,
  masterLists,
  contracts,
  contract,
  manageUsers,
  manageCategories,
  manageProducts,
  procurement,
  warehouse,
  activity,
  assessments
});

// TODO: work out the state here
const createRootReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === 'hydrate') {
    state = action.payload;
  }
  return allReducers(state, action);
};
// >>>>>>> features-migrations

export const rootReducer = createRootReducer;
