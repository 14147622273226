import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors, PartialBy } from 'millbrook-core';
import { MillbrookContactFormData } from 'pages/Contract/KeyDates/MillbrookContact/components/MillbrookContactForm/MillbrookContactForm';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

/* types */
export type MillbrookContact = {
  id: ApiId;
  contractId?: ApiId;
  phoneNumber: string;
  email: string;
  sms: string;
  whatsApp: string;
  useFacebook: boolean;
  useTwitter: boolean;
};

export type MillbrookContactRequest = PartialBy<MillbrookContact, 'id'>;

export type MillbrookContactResponse = IApiResponse<MillbrookContact>;

/* state */
interface MillbrookContactState extends MillbrookContact {}

const initialState: MillbrookContactState = {
  id: '',
  phoneNumber: '',
  email: '',
  sms: '',
  whatsApp: '',
  useFacebook: false,
  useTwitter: false
};

/* slice */
const contractMillbrookContactSlice = createSlice({
  name: 'millbrookContact',
  initialState,
  reducers: {
    setMillbrookContact(state, action: PayloadAction<MillbrookContact | undefined>) {
      state.id = action.payload?.id || initialState.id;
      state.phoneNumber = action.payload?.phoneNumber || initialState.phoneNumber;
      state.email = action.payload?.email || initialState.email;
      state.useFacebook = action.payload?.useFacebook || initialState.useFacebook;
      state.useTwitter = action.payload?.useTwitter || initialState.useTwitter;
      state.sms = action.payload?.sms || initialState.sms;
      state.whatsApp = action.payload?.whatsApp || initialState.whatsApp;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchMillbrookContact =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<MillbrookContactResponse>(ENDPOINTS.CONTRACT.KEY_DATES.MILLBROOK_CONTACT(contractId)).then(
      (response) => {
        dispatch(setMillbrookContact(response.result || undefined));
      }
    );
  };

export const createMillbrookContact =
  (millbrookContact: MillbrookContactFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<MillbrookContactRequest, MillbrookContactResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.MILLBROOK_CONTACT(),
      { ...millbrookContact, contractId }
    ).then(
      () => {
        dispatch<any>(fetchMillbrookContact(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateMillbrookContact =
  (millbrookContact: MillbrookContactFormData, id: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<MillbrookContactRequest, MillbrookContactResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.MILLBROOK_CONTACT(),
      { ...millbrookContact, id, contractId },
      id,
      undefined,
      false
    ).then(
      () => {
        dispatch<any>(fetchMillbrookContact(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setMillbrookContact } = contractMillbrookContactSlice.actions;

/* selectors */
export const selectMillbrookContact = (state: RootState) => state.contract.keyDates.millbrookContact;

/* reducers */
export default contractMillbrookContactSlice.reducer;
