/********** Help Documents ************/

import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { fetchDocumentCategories } from 'features/masterLists/masterDocumentCategories/masterTrustDocumentCategories.slice';
import {
  ApiId,
  HelpDocumentCategoryModel,
  HelpDocumentCategoryTreeResponseModel,
  HelpDocumentModel,
  IApiResponse,
  IDeleteRequest,
  IDocumentModel,
  ISelectOption,
  mapApiErrors
} from 'millbrook-core';
import { HelpDocumentUploadFormData } from 'pages/Contract/PrescriberPortal/HelpDocuments/ContractHelpDocuments.validation';
import { deleteItems, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import {
  clearContractState,
  ContractDocumentCreateRequest,
  ContractDocumentUpdateRequest
} from '../overview/overview.slice';
import { mapDocumentsDataToCreateRequest } from './mappers';

const DOCS_CACHE = 'help-docs';

/* state */
interface HelpDocsState {
  documents?: HelpDocumentModel[];
  categories?: HelpDocumentCategoryTreeResponseModel[];
}

const initialState: HelpDocsState = {};

/* slice */
const helpAndSupportSlice = createSlice({
  name: 'helpAndSupport',
  initialState,
  reducers: {
    setContractHelpDocuments(state, action: PayloadAction<HelpDocumentCategoryModel[]>) {
      let docs: HelpDocumentModel[] = [];

      action.payload.forEach((category) => {
        docs = [...docs, ...category.helpDocuments];
      });

      state.documents = docs;
    },
    setContractHelpDocumentsCategories(state, action: PayloadAction<HelpDocumentCategoryTreeResponseModel[]>) {
      state.categories = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchContractHelpDocuments =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<HelpDocumentCategoryModel[]>>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.HELP_DOCUMENTS(contractId),
      {
        cacheName: DOCS_CACHE
      }
    ).then(
      (response) => {
        dispatch(setContractHelpDocuments(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchContractHelpDocumentsCategories = (): AppThunk => async (dispatch) => {
  return dispatch<any>(fetchDocumentCategories()).then(
    (response: HelpDocumentCategoryTreeResponseModel[]) => {
      dispatch(setContractHelpDocumentsCategories(response || []));
    }
    //(response) => {
    //  const error = mapApiErrors(response);
    //  throw new Error(error);
    //}
  );
};

export const createContractHelpDocument =
  (contractId: ApiId, data: HelpDocumentUploadFormData): AppThunk =>
  async (dispatch) => {
    return postItems<ContractDocumentCreateRequest, number>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.HELP_DOCUMENTS(contractId),
      mapDocumentsDataToCreateRequest(data),
      {
        cacheName: DOCS_CACHE
      }
    ).then(
      () => {
        dispatch<any>(fetchContractHelpDocuments(contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractHelpDocument =
  (contractId: ApiId, data: IDocumentModel): AppThunk =>
  async (dispatch) => {
    return putItem<ContractDocumentUpdateRequest, null>(
      ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.HELP_DOCUMENTS(),
      data,
      data.id,
      {
        cacheName: DOCS_CACHE
      }
    ).then(
      () => {
        dispatch<any>(fetchContractHelpDocuments(contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractHelpDocuments =
  (contractId: ApiId, data: IDocumentModel[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.HELP_DOCUMENTS(), ids, {
      enableGlobalErrorDialog: true,
      cacheName: DOCS_CACHE
    }).then(
      () => {
        dispatch<any>(fetchContractHelpDocuments(contractId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const downloadFile =
  (url: string): AppThunk =>
  async (dispatch) => {
    return getItem<any, any>(ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.HELP_DOCUMENT(url)).then((response) => {
      // TODO: try and get the filename using the endpoint, can't get anything working right now
    });
  };

/* actions */
export const { setContractHelpDocuments, setContractHelpDocumentsCategories } = helpAndSupportSlice.actions;

/* selectors */
// export const selectContractHelpDocumentsList = (state: RootState) =>
//   state.contract.prescriberPortal.helpAndSupport.documents;

export const selectContractHelpDocuments = (state: RootState) =>
  state.contract.prescriberPortal.helpAndSupport.documents;

export const selectContractHelpDocumentsCategories = (state: RootState) =>
  state.contract.prescriberPortal.helpAndSupport.categories;

const flattenCats = (
  cats: HelpDocumentCategoryTreeResponseModel[],
  flat: ISelectOption[] = [],
  parentCatName: string[] = []
) => {
  return cats.reduce((f, cat) => {
    const level = parentCatName.length ? 'sub' : 'top';
    const catName = [...parentCatName, cat.name];

    flat.push({ value: cat.id, label: catName.join(' - '), level });

    if (cat.children?.length) {
      f = flattenCats(cat.children, f, catName);
    }
    return f;
  }, flat);
};

export const selectContractHelpDocumentsCategoryOptions = createSelector(
  [selectContractHelpDocumentsCategories],
  (categories = []) => {
    // flatten the categories into category - sub-cat

    return flattenCats(categories);
  }
);

/* reducers */
export default helpAndSupportSlice.reducer;
