import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors } from 'millbrook-core';
import { ContractInactiveReasonsFormData } from 'pages/Contract/ServiceUser/InactiveReasons/components/ContractInactiveReasonsForm';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractInactiveReasonsFormToRequest } from '../mappers';
import { clearContractState } from '../overview/overview.slice';

/* types */
export interface InactiveReason {
    id: ApiId;
    contractId: ApiId;
    masterName: string;
    inactiveReasonId: ApiId;
    onContract: boolean;
}

export type ContractInactiveReasonId = ApiId;
export interface ContractInactiveReasons {
    contractId: ApiId;
    inactiveReasonIds: ContractInactiveReasonId[];
}

export type ContractInactiveReasonsRequest = ContractInactiveReasons;
export type ContractInactiveReasonsResponse = IApiResponse<InactiveReason[]>;

/* state */
export interface ContractInactiveReasonsState {
    reasons?: InactiveReason[];
}
const initialState: ContractInactiveReasonsState = {};

/* slice */
const contractContractInactiveReasonsSlice = createSlice({
    name: 'inactiveReasons',
    initialState,
    reducers: {
        setContractInactiveReasons(state, action: PayloadAction<InactiveReason[]>) {
            state.reasons = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearContractState, () => {
            return initialState;
        });
    }
});

/* thunks */
export const fetchContractInactiveReasons =
    (contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return getItems<ContractInactiveReasonsResponse>(
                `${ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_INACTIVE_REASONS}?contractId=${contractId}&includeMasterList=true`
            ).then((response) => {
                dispatch(setContractInactiveReasons(response.result || []));
            });
        };

export const createContractInactiveReasons =
    (serviceUserInactiveReasons: ContractInactiveReasonsFormData, contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return postItems<ContractInactiveReasonsRequest, ContractInactiveReasonsResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_INACTIVE_REASONS,
                mapContractInactiveReasonsFormToRequest(serviceUserInactiveReasons, contractId)
            ).then(
                () => {
                    dispatch<any>(fetchContractInactiveReasons(contractId));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateContractInactiveReasons =
    (serviceUserGroups: ContractInactiveReasonsFormData, contractId: ApiId): AppThunk =>
        async (dispatch, getState) => {
            return putItem<ContractInactiveReasonsRequest, ContractInactiveReasonsResponse>(
                ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_INACTIVE_REASONS,
                mapContractInactiveReasonsFormToRequest(serviceUserGroups, contractId)
            ).then(
                () => {
                    dispatch<any>(fetchContractInactiveReasons(contractId));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

/* actions */
export const { setContractInactiveReasons } = contractContractInactiveReasonsSlice.actions;

/* selectors */
export const selectInactiveReasonsList = (state: RootState) => state.contract.serviceUser.serviceUserInactiveReasons.reasons;

/* reducers */
export default contractContractInactiveReasonsSlice.reducer;
