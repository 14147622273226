import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  ContractSpeed,
  IApiResponse,
  mapApiErrors,
  ServiceSpeedTypeCodeEnum,
  ServiceTypeCodeEnum
} from 'millbrook-core';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { getCurrentService } from './contractServices.slice';
import { ContractSpeedRequest, ContractSpeedResponse } from './scope.types';

interface SpeedsState {
  speedList: ContractSpeed[];
}

export type ContractSpeedsResponse = IApiResponse<ContractSpeed[]>;

const initialState: SpeedsState = { speedList: [] };

const contractSpeedsSlice = createSlice({
  name: 'speeds',
  initialState,
  reducers: {
    setContractSpeedList(state, action: PayloadAction<ContractSpeed[]>) {
      state.speedList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// thunks for speeds
export const fetchContractSpeedsByService =
  (contractId: ApiId, contractServiceTypeId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ContractSpeedsResponse>(
      ENDPOINTS.CONTRACT.SCOPE.SPEEDS_GET(contractId, contractServiceTypeId)
    ).then(
      (response) => {
        // map the response enum periodType to the string
        const responseMap = (response.result || []).map((item) => ({
          ...item,
          contractId,
          name: item.name || item.masterName || '',
          description: item.description || item.masterDescription || '',
          masterName: item.masterName || '',
          masterDescription: item.masterDescription || '',
          slaTitle: item.slaTitle || '',
          contractServiceTypeId,
          modified: item.onContract && (item.name !== item.masterName || item.description !== item.masterDescription)
        }));

        dispatch(setContractSpeedList(responseMap));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createContractSpeed =
  (data: ContractSpeedRequest): AppThunk =>
  async (dispatch) => {
    return postItems<ContractSpeedRequest, ContractSpeedResponse>(ENDPOINTS.CONTRACT.SCOPE.SPEEDS, data).then(
      () => {
        dispatch<any>(fetchContractSpeedsByService(data.contractId, data.contractServiceTypeId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractSpeed =
  (data: ContractSpeedRequest): AppThunk =>
  async (dispatch) => {
    return putItem<ContractSpeedRequest, ContractSpeedResponse>(ENDPOINTS.CONTRACT.SCOPE.SPEEDS, data, data.id).then(
      () => {
        dispatch<any>(fetchContractSpeedsByService(data.contractId, data.contractServiceTypeId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractSpeed =
  (data: ContractSpeed): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, ContractSpeedResponse>(ENDPOINTS.CONTRACT.SCOPE.SPEEDS, data.id).then(
      () => {
        dispatch<any>(fetchContractSpeedsByService(data.contractId, data.contractServiceTypeId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractSpeedList } = contractSpeedsSlice.actions;

/* selectors */
const speedList = (state: RootState) => state.contract.scope.speeds.speedList;

export const selectContractSpeedList = createSelector([speedList, getCurrentService], (speeds, service) => {
  /**
   * derived. Removing the speeds from certain service. It's causing too many questions and raising bugs that aren't bugs
   */
  switch (service?.serviceTypeCode) {
    case ServiceTypeCodeEnum.SpecialRequisition:
      return speeds.filter((speed) => speed.serviceSpeedTypeCode === ServiceSpeedTypeCodeEnum.Specials28Days);
    case ServiceTypeCodeEnum.PooledSpecials:
      return speeds.filter((speed) => speed.serviceSpeedTypeCode === ServiceSpeedTypeCodeEnum.RSPOrders10Days);
    default:
      return speeds;
  }
});

/* reducers */
export default contractSpeedsSlice.reducer;
