import { byte } from '@zxing/library/esm/customTypings';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  ErrorResponse,
  IApiResponse,
  IMediaModel,
  ISelectOption,
  IsoDate,
  mapApiErrors,
  ShelfTypeEnum,
  SHORT_UTC_DATE_FORMAT
} from 'millbrook-core';
import { deleteItem, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk } from 'store/store';
import { PurchaseOrderFormData } from '../../../pages/Procurement/PurchaseOrders/PurchaseOrderDraftPage.validation';
import {
  setLocationStockLevels,
  setPurchaseOrder,
  setPurchaseOrderMedias,
  setPurchaseOrders,
  setPurchaseOrdersArchive,
  setPurchaseOrdersCount,
  setRecommendedOrders
} from '../procurement.slice';
import { LocationLevelFormData } from './components/LocationStockLevel/LocationStockLevelItemForm';
import { format } from 'date-fns';

export enum PurchaseOrderStatus {
  Draft = 1,
  RequiresAuthorisation = 2,
  PurchaseOrderPlaced = 3,
  PartiallyFulfilled = 4,
  Fulfilled = 5,
  Cancelled = -1,
  CancelledNoLongerRequired = -2,
  CancelledSupplierCannotFulfill = -3,
  NoGRNSupplierPODReceived = 6
}

export const PurchaseOrderStatusDisplayName = {
  [PurchaseOrderStatus.Cancelled.toString()]: 'Cancelled',
  [PurchaseOrderStatus.CancelledNoLongerRequired.toString()]: 'Cancelled no longer required',
  [PurchaseOrderStatus.CancelledSupplierCannotFulfill.toString()]: 'Cancelled supplier cannot fulfill',
  [PurchaseOrderStatus.Draft.toString()]: 'Draft',
  [PurchaseOrderStatus.RequiresAuthorisation.toString()]: 'Requires authorisation',
  [PurchaseOrderStatus.PurchaseOrderPlaced.toString()]: 'Purchase order placed',
  [PurchaseOrderStatus.PartiallyFulfilled.toString()]: 'Partially fulfilled',
  [PurchaseOrderStatus.Fulfilled.toString()]: 'Fulfilled',
  [PurchaseOrderStatus.NoGRNSupplierPODReceived.toString()]: 'No GRN supplier POD received'
};

export const PurchaseOrderFinalisedStatus: Array<PurchaseOrderStatus> = [
  PurchaseOrderStatus.Fulfilled,
  PurchaseOrderStatus.Cancelled,
  PurchaseOrderStatus.CancelledNoLongerRequired,
  PurchaseOrderStatus.CancelledSupplierCannotFulfill,
  PurchaseOrderStatus.NoGRNSupplierPODReceived
];

export const getPurchaseOrderStatusSelectOptions = (): ISelectOption[] => {
  return Object.values(PurchaseOrderStatus).map((key) => ({
    value: key,
    label: PurchaseOrderStatusDisplayName[key]
  }));
};

export const getPurchaseOrderCancelledSelectOptions = (purchaseOrderStatus: PurchaseOrderStatus): ISelectOption[] => {
  return Object.values(PurchaseOrderStatus).reduce<ISelectOption[]>((options, key) => {
    if (
      typeof key === 'number' &&
      (purchaseOrderStatus === PurchaseOrderStatus.Draft ||
      purchaseOrderStatus === PurchaseOrderStatus.RequiresAuthorisation
        ? key < PurchaseOrderStatus.Draft
        : purchaseOrderStatus === PurchaseOrderStatus.PurchaseOrderPlaced
        ? key < PurchaseOrderStatus.Draft || key === PurchaseOrderStatus.NoGRNSupplierPODReceived
        : key === PurchaseOrderStatus.NoGRNSupplierPODReceived)
    ) {
      options.push({
        value: key,
        label: PurchaseOrderStatusDisplayName[key]
      });
    }
    return options;
  }, []);
};

export interface PurchaseOrder {
  id: ApiId;
  poRef: number;
  activityRef: string;
  activityId?: ApiId;
  raisedById: ApiId;
  raisedByName: string;
  placedBy: string;
  placedByName: string;
  datePlaced: IsoDate;
  isSpecial: boolean;
  note: string;
  carriageCost: string;
  eta: Date;
  totalValue: string;
  supplierId: ApiId;
  supplierName: string;
  locationId: ApiId;
  contractId: ApiId;
  statusId: PurchaseOrderStatus;
  sendEmail: boolean;
  deliveryAddressId?: ApiId;
  deliveryAddress?: PurchaseOrderDeliveryAddress;
  orderItems: PurchaseOrderItem[];
  prescriberName: string;
  contractName: string;
  prescriberTeam: string;
  prescriberEmail: string;
  prescriberPhone: string;
  prescriberMobile: string;
}

export interface PurchaseOrderDeliveryAddress {
  id: ApiId;
  shippingName: string;
  line1: string;
  line2: string;
  line3: string;
  county: string;
  postcode: string;
  country: string;
  city: string;
}

export interface PurchaseOrderItem {
  id: ApiId;
  quantity: number;
  note: string;
  name: string;
  description: string;
  sku: string;
  supplierSku: string;
  price: string;
  supplierProductId: ApiId;
  productId: ApiId;
  supplierProductPriceId: ApiId;
  purchaseOrderId: ApiId;
}

export interface PurchaseOrderMedia {
  id: ApiId;
  dateCreated: Date;
  purchaseOrderId: ApiId;
  mediaId?: ApiId;
  media: IMediaModel;
  attachToPOEmail: boolean;
}

export interface RecommendedOrder {
  name: any;
  id: ApiId;
  locationID: ApiId;
  locationName: string;
  productID: ApiId;
  productSKU: string;
  productName: string;
  supplierID: ApiId;
  supplierName: string;
  supplierProductId: string;
  supplierProductPriceId: string;
  min: number;
  max: number;
  reorderPoint: number;
  assumedCleaningPercentage: number;
  defaultCarriageCost: string;
  stockLevel: number;
  requiredForMin: number;
  requiredForMax: number;
  requiredForCustom: number;
  cteAvailableCount: number;
  cteProductCount: number;
  cleaningTotalCount: number;
  dueInCount: number;
  onOpenOrders: number;
  delin: number;
  processed: boolean;
  supplierSKUNotCreated: boolean;
  dateCreated: Date;
  eta: Date;
  createdby: string;
  orderQtyAs: number;
  processPO: boolean;
}

export interface LocationProductLevels {
  id: ApiId;
  locationID: ApiId;
  locationName: string;
  productID: ApiId;
  productSKU: string;
  productName: string;
  min: number;
  max: number;
  reorderPoint: number;
  assumedCleaningPercentage: string;
  allowAutoOrder: boolean;
}

export interface UnallocatedProducts {
  id: ApiId;
  sku: string;
  name: string;
  description: string;
  shelfType: ShelfTypeEnum;
}

export interface LocationLevels {
  locationProductLevels?: LocationProductLevels[];
  unallocatedProducts?: UnallocatedProducts[];
}

const LITERATURE_CACHE = 'purchaseorderliterature';

export type PurchaseOrderListResponse = IApiResponse<PurchaseOrder[]>;
export type PurchaseOrderResponse = IApiResponse<PurchaseOrder>;
export type PurchaseOrderCountResponse = IApiResponse<number>;
export type PurchaseOrderPDFResponse = IApiResponse<byte[]>;
export type PurchaseOrderDocumentCreateRequest = FormData;
export type PurchaseOrderMediasListResponse = IApiResponse<PurchaseOrderMedia[]>;
export type PurchaseOrderMediaResponse = IApiResponse<PurchaseOrderMedia>;
export type RecommendedOrderListResponse = IApiResponse<RecommendedOrder[]>;
export type LocationLevelsResponse = IApiResponse<LocationLevels>;
export type ProcessRecommendedOrderListResponse = IApiResponse<boolean>;
export type ProcessPutRequest = FormData;

// thunks

export const fetchPurchaseOrders =
  (
    locationId?: ApiId,
    contractId?: ApiId,
    statusIds?: number[],
    isSpecial?: boolean,
    supplierId?: ApiId,
    poRef?: number,
    activityRef?: string,
    dateFrom?: Date,
    dateTo?: Date
  ): AppThunk =>
  async (dispatch) => {
    var filters = ``;

    if (locationId) {
      filters += `locationId=${locationId}`;
    }

    if (contractId) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `locationId=${contractId}`;
    }

    if (statusIds) {
      var i;
      for (i = 0; i < statusIds.length; i++) {
        if (filters.length > 0) {
          filters += `&`;
        }
        filters += `statusIds=${statusIds[i]}`;
      }
    }

    if (isSpecial !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `isSpecial=${isSpecial}`;
    }

    if (supplierId !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `supplierId=${supplierId}`;
    }

    if (poRef !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `poRef=${poRef}`;
    }

    if (activityRef !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `activityRef=${activityRef}`;
    }

    if (dateFrom !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `dateFrom=${new Date(dateFrom).toUTCString()}`;
    }

    if (dateTo !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `dateTo=${new Date(dateTo).toUTCString()}`;
    }

    const url = `${ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER}?` + filters;

    return getItems<PurchaseOrderListResponse>(url).then((response) => {
      dispatch(setPurchaseOrders(response.result || []));
      return response;
    });
  };

// thunks
export const fetchPurchaseOrdersArchive =
  (
    locationId?: ApiId,
    contractId?: ApiId,
    statusIds?: number[],
    isSpecial?: boolean,
    supplierId?: ApiId,
    poRef?: number,
    activityRef?: string,
    dateFrom?: Date,
    dateTo?: Date
  ): AppThunk =>
  async (dispatch) => {
    var filters = ``;

    if (locationId) {
      filters += `locationId=${locationId}`;
    }

    if (contractId) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `locationId=${contractId}`;
    }

    if (statusIds) {
      var i;
      for (i = 0; i < statusIds.length; i++) {
        if (filters.length > 0) {
          filters += `&`;
        }
        filters += `statusIds=${statusIds[i]}`;
      }
    }

    if (isSpecial !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `isSpecial=${isSpecial}`;
    }

    if (supplierId !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `supplierId=${supplierId}`;
    }

    if (poRef !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `poRef=${poRef}`;
    }

    if (activityRef !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `activityRef=${activityRef}`;
    }

    if (dateFrom !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `dateFrom=${new Date(dateFrom).toUTCString()}`;
    }

    if (dateTo !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `dateTo=${new Date(dateTo).toUTCString()}`;
    }

    const url = `${ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER}?` + filters;

    return getItems<PurchaseOrderListResponse>(url).then((response) => {
      dispatch(setPurchaseOrdersArchive(response.result || []));
      return response;
    });
  };

export const fetchPurchaseOrderPdf =
  (purchaseOrderId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PurchaseOrderPDFResponse[]>(
      ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER_PDF(purchaseOrderId)
    ).then((response) => {
      return response;
    });
  };

export const fetchRecommednedOrders =
  (locationId: ApiId, supplierId?: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<RecommendedOrderListResponse>(
      ENDPOINTS.PROCUREMENT.PROCUREMENT.RECOMMENDED_ORDER(locationId, supplierId)
    ).then((response) => {
      dispatch(setRecommendedOrders(response.result || []));
      return response;
    });
  };

export const updateRecommendedOrders =
  (recommendedorders: RecommendedOrder[]): AppThunk =>
  async (dispatch, getState) => {
    let formData = new FormData();
    formData.append('recommendedorders', JSON.stringify(recommendedorders));
    return putItem<ProcessPutRequest, ProcessRecommendedOrderListResponse>(
      ENDPOINTS.PROCUREMENT.PROCUREMENT.PROCESS_RECOMMENDED_ORDER,
      formData
    ).then(
      (response) => {
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createPurchaseOrderDocument =
  (poId: ApiId, files: Blob[]): AppThunk =>
  async (dispatch) => {
    var formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }
    return postItems<PurchaseOrderDocumentCreateRequest, number>(
      ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.DOCUMENTS(poId),
      formData,
      {
        cacheName: LITERATURE_CACHE,
        enableGlobalErrorDialog: true
      }
    ).then(
      () => {
        //dispatch<any>(fetchMasterProductDocuments(productId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

export const fetchPurchaseOrderMedias =
  (purchaseOrderId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, PurchaseOrderMediasListResponse>(
      ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER_MEDIAS,
      purchaseOrderId
    ).then((response) => {
      dispatch(setPurchaseOrderMedias(response.result || []));
      return response;
    });
  };

export const deletePurchaseOrderMedia =
  (poMediaId: ApiId): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, undefined>(ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER_MEDIAS, poMediaId).then(
      () => {}
    );
  };

export const updatePurchaseOrderMedia =
  (purchaseOrderMedia: PurchaseOrderMedia): AppThunk =>
  async (dispatch, getState) => {
    //This update is only really designed to flip the attachToPOEmail check. Please be careful about using it.
    return putItem<PurchaseOrderMedia, PurchaseOrderMediaResponse>(
      ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER_MEDIAS,
      purchaseOrderMedia,
      purchaseOrderMedia.id
    ).then(
      (response) => {
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// thunks
export const fetchPurchaseOrdersCount =
  (statusIds?: number[], isSpecial?: boolean): AppThunk =>
  async (dispatch) => {
    var filters = ``;

    if (statusIds) {
      var i;
      for (i = 0; i < statusIds.length; i++) {
        if (filters.length > 0) {
          filters += `&`;
        }
        filters += `statusIds=${statusIds[i]}`;
      }
    }

    if (isSpecial !== undefined) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `isSpecial=${isSpecial}`;
    }

    const url = `${ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER_COUNT}?` + filters;

    return getItems<PurchaseOrderCountResponse>(url).then((response) => {
      dispatch(setPurchaseOrdersCount(response.result || 0));
      return response;
    });
  };

export const fetchPurchaseOrder =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, PurchaseOrderResponse>(ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER, id).then(
      (response) => {
        dispatch(setPurchaseOrder(response.result || undefined));
        return response;
      }
    );
  };

export const createPurchaseOrder =
    (newPurchaseOrder: PurchaseOrderFormData): AppThunk =>
        async (dispatch) => {
            const { eta, ...order } = newPurchaseOrder;
            const mappedNewPurchaseOrder = {
                eta: format(eta, SHORT_UTC_DATE_FORMAT),
                ...order
            };
            return postItems<any, PurchaseOrderResponse>(
                ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER,
                mappedNewPurchaseOrder
            ).then(
                (response) => {
                    if (response.result) {
                        dispatch<any>(fetchPurchaseOrder(response.result.id));
                    }
                    return response.result;
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updatePurchaseOrder =
    (purchaseOrder: PurchaseOrder): AppThunk =>
        async (dispatch, getState) => {
            const { eta, ...order } = purchaseOrder;
            const mappedPurchaseOrder = {
                eta: format(eta, SHORT_UTC_DATE_FORMAT),
                ...order
            };
            return putItem<any, PurchaseOrderResponse>(
                ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER,
                mappedPurchaseOrder,
                purchaseOrder.id
            ).then(
                (response) => {
                    if (response.result) {
                        dispatch<any>(fetchPurchaseOrder(response.result.id));
                    }
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const deletePurchaseOrder =
  (purchaseOrder: PurchaseOrder): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, PurchaseOrderResponse>(
      ENDPOINTS.PROCUREMENT.PURCHASE_ORDERS.PURCHASE_ORDER,
      purchaseOrder.id
    ).then(
      (response) => {
        dispatch<any>(fetchPurchaseOrders());
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchLocationStockLevels =
  (locationId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<LocationLevels>(ENDPOINTS.PROCUREMENT.PROCUREMENT.LOCATION_STOCK_LEVELS(locationId)).then(
      (response) => {
        dispatch(setLocationStockLevels(response));
        return response;
      }
    );
  };

export const createLocationStockLevel =
  (locationlevel: LocationLevelFormData): AppThunk =>
  async (dispatch) => {
    return postItems<LocationLevelFormData, boolean>(
      ENDPOINTS.PROCUREMENT.PROCUREMENT.CREATE_LOCATION_STOCK_LEVEL,
      locationlevel
    ).then(
      (response) => {
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateLocationStockLevel =
  (locationlevel: LocationLevelFormData): AppThunk =>
  async (dispatch) => {
    return putItem<LocationLevelFormData, boolean>(
      ENDPOINTS.PROCUREMENT.PROCUREMENT.UPDATE_LOCATION_STOCK_LEVEL,
      locationlevel
    ).then(
      (response) => {
        return response;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };
