import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, mapApiErrors, PartialBy } from 'millbrook-core';
import { deleteItem, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

export interface AdditionalContractServiceUserField {
  id: ApiId;
  contractId: ApiId;
  fieldName: string;
  mandatory: boolean;
}

export type AdditionalContractServiceUserFieldRequest = PartialBy<AdditionalContractServiceUserField, 'id'>;

export type AdditionalContractServiceUserFieldsResponse = IApiResponse<AdditionalContractServiceUserField[]>;

export interface AdditionalContractServiceUserFieldsRequest {
  serviceUserFields: AdditionalContractServiceUserField[];
  contractId: ApiId;
}

/* state */
interface ContractAdditionalServiceUserFieldsState {
  fields: AdditionalContractServiceUserField[];
}

const initialState: ContractAdditionalServiceUserFieldsState = {
  fields: []
};

const contractAdditionalServiceUserFieldsSlice = createSlice({
  name: 'additionalServiceUserFields',
  initialState,
  reducers: {
    setAdditionalServiceUserFields(state, action: PayloadAction<AdditionalContractServiceUserField[]>) {
      state.fields = action.payload;
    },
    removeAdditionalServiceUserField(state, action: PayloadAction<ApiId>) {
      state.fields = state.fields.filter((x) => x.id !== action.payload);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// actions
export const { setAdditionalServiceUserFields, removeAdditionalServiceUserField } =
  contractAdditionalServiceUserFieldsSlice.actions;

// thunks

export const fetchAdditionalContractServiceUserFields =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<AdditionalContractServiceUserFieldsResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.ADDITIONAL_SERVICE_USER_FIELDS(contractId)
    ).then(
      (response) => {
        dispatch(setAdditionalServiceUserFields(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createAdditionalContractServiceUserField =
  (data: AdditionalContractServiceUserField): AppThunk =>
  async (dispatch) => {
    const request = { serviceUserFields: [data], contractId: data.contractId };

    return postItems<AdditionalContractServiceUserFieldsRequest, AdditionalContractServiceUserFieldsResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.ADDITIONAL_SERVICE_USER_FIELDS(data.contractId),
      request
    ).then(
      () => {
        dispatch<any>(fetchAdditionalContractServiceUserFields(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteAdditionalContractServiceUserField =
  (data: AdditionalContractServiceUserField): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, AdditionalContractServiceUserFieldsResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.ADDITIONAL_SERVICE_USER_FIELDS(data.contractId),
      data.id,
      { enableGlobalErrorDialog: true }
    ).then(
      () => {
        dispatch(removeAdditionalServiceUserField(data.id));
      },
      (response) => {
        // handled with global error
        //const error = mapApiErrors(response);
        //throw new Error(error);
      }
    );
  };

// selectors
export const selectAdditionalContractServiceUserFields = (state: RootState) =>
  state.contract.serviceUser.additionalServiceUserFields.fields;

export default contractAdditionalServiceUserFieldsSlice.reducer;
