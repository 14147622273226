import { ApiId, GLOBAL_ROUTES } from 'millbrook-core';
import { ROUTEPARAMS } from './routeParameters';

export const ROUTES = {
    ...GLOBAL_ROUTES,
    ADMIN: {
        root: `/admin`,
        masterLists: `/admin/master-lists`,
        serviceCentres: `/admin/master-lists/service-centres`,
        serviceUsers: `/admin/master-lists/service-users`,
        careOutcomes: `/admin/master-lists/care-outcomes`,
        reviews: `/admin/master-lists/reviews`,
        properties: `/admin/master-lists/properties`,
        services: `/admin/master-lists/services`,
        reason_codes: `/admin/master-lists/reason-codes`,
        cleaning_codes: `/admin/master-lists/cleaning-codes`,
        ppm_types: `/admin/master-lists/ppm-types`,
        event_types: `/admin/master-lists/event-types`,
        reasonCodes: `/admin/master-lists/reason-codes`,
        cleaningCodes: `/admin/master-lists/cleaning-codes`,
        ppmTypes: `/admin/master-lists/ppm-types`,
        eventTypes: `/admin/master-lists/event-types`,
        clientDocumentCategories: `/admin/master-lists/client-document-categories`,
        help: {
            list: `/admin/help`,
            page: (helpPageId: ApiId = ROUTEPARAMS.HELP_PAGE_ID) => `/admin/help/${helpPageId}`
        },
        trustDocumentCategories: `/admin/master-lists/document-categories`
    },
    CONTRACTS: {
        root: `/contracts`
    },
    CONTRACT: {
        root: `/contract/:contractId?`,
        draft: `/contract/`,
        overview: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}`,
        keyDates: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/key-dates`,
            awardDate: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-award-date`,
            operationalDates: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-operational-dates`,
            extensionDates: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-extension-options`,
            priceReviewDates: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-price-review-dates`,
            workingHours: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-working-hours`,
            nonWorkingDays: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-non-working-days`,
            millbrookContact: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/key-dates/contract-millbrook-contact`
        },
        scope: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/scope`,
            contractServices: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/scope/contract-services`,
            contractSpeeds: (
                contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
                contractServiceTypeId: ApiId = ROUTEPARAMS.CONTRACT_SERVICE_TYPE_ID
            ) => `/contract/${contractId}/scope/speeds/${contractServiceTypeId}`,
            reviewTypes: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/scope/contract-review-types`,
            ppmTypes: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/scope/contract-ppm-types`,
            scrap: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/scope/scrap`,
            productConfiguration: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/scope/product-configuration`,
            cleaningCodesAndCharges: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/scope/cleaning-codes`,
            hireTrialEquipment: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/scope/hire-trial`,
            soundexSearch: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/scope/soundex-search`
        },
        pricing: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/pricing`,
            serviceCharges: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/pricing/service-charges`,
            reasonCodes: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/pricing/reason-codes`
        },
        products: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/products/`,
            catalogue: (
                contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
                productCategoryId: ApiId = ROUTEPARAMS.PRODUCT_CATEGORY_ID
            ) => `/contract/${contractId}/products/catalogue/${productCategoryId}`,
            searchResults: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/products/search`,
            details: (
                contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
                productCategoryId: ApiId = ROUTEPARAMS.PRODUCT_CATEGORY_ID,
                productId: ApiId = ROUTEPARAMS.PRODUCT_ID
            ) => `/contract/${contractId}/products/catalogue/${productCategoryId}/${productId}`,
            detailsNoCat: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, productId: ApiId = ROUTEPARAMS.PRODUCT_ID) =>
                `/contract/${contractId}/products/search/${productId}`
        },
        budgetPin: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin`,
            organisations: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/budget-pin/organisations`,
            areas: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/areas`,
            teams: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/teams`,
            groups: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/groups`,
            groupsAdd: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/groups/add`,
            groupsEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, pinGroupId: ApiId = ROUTEPARAMS.PIN_GROUP_ID) =>
                `/contract/${contractId}/budget-pin/groups/edit/${pinGroupId}`,
            users: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/users`,
            userAdd: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/users/add`,
            userEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, userId: ApiId = ROUTEPARAMS.USER_ID) =>
                `/contract/${contractId}/budget-pin/users/${userId}`,
            stock: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/budget-pin/stock`,
            stockEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, pinGroupId: ApiId = ROUTEPARAMS.PIN_GROUP_ID) =>
                `/contract/${contractId}/budget-pin/stock/edit/${pinGroupId}`,
            stockMultiEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/budget-pin/stock/edit-multiple/`
        },
        prescriberPortal: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/prescriber-portal`,
            prescriberLogo: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescriber-portal/prescriber-logo`,
            helpAndSupport: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescriber-portal/help-and-support`,
            benefitsRealisation: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescriber-portal/benefits-realisation`,
            professionalAssessment: {
                root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                    `/contract/${contractId}/prescriber-portal/professional-assessment`,
                contractProfessionalAssessmentQuestions: (
                    contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
                    professionalAssessmentId: ApiId = ROUTEPARAMS.PROFESSIONAL_ASSESSMENT_ID
                ) =>
                    `/contract/${contractId}/prescriber-portal/professional-assessment/assessment-questions/${professionalAssessmentId}`,
                contractProfessionalAssessmentQuestionProductManagement: (
                    contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
                    contractProfessionalAssessmentQuestionId: ApiId = ROUTEPARAMS.CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_ID
                ) =>
                    `/contract/${contractId}/prescriber-portal/professional-assessment/manage-question-products/${contractProfessionalAssessmentQuestionId}`
            },
            contractConfig: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescriber-portal/contract-config`
        },
        prescriptionPortal: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/prescription-portal`,
            retailers: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescription-portal/retailers`,
            retailersAdd: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescription-portal/retailers/add`,
            retailersEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, retailerId: ApiId = ROUTEPARAMS.CONTRACT_RETAILER_ID) =>
                `/contract/${contractId}/prescription-portal/retailers/edit/${retailerId}`,
            retailUsers: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescription-portal/retailer-users`,
            retailerUsersAdd: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/prescription-portal/retaile-users/add`,
            retailerUsersEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, retailerUserId: ApiId = ROUTEPARAMS.CONTRACT_RETAILER_USER_ID) =>
                `/contract/${contractId}/prescription-portal/retailer-users/edit/${retailerUserId}`,
        },
        serviceUser: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/service-user`,
            serviceUserGroups: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/service-user/service-user-groups`,
            serviceUserFields: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/service-user/service-user-fields`,
            serviceUserAlerts: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/service-user/service-user-alerts`,
            inactiveReasons: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/service-user/inactive-reasons`,
            defineGPsByCCG: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/service-user/define-gps-by-ccg`,
            propertyOwnershipTypes: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/service-user/property-ownership-types`
        },
        peripheralStores: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/peripheral-stores`,
            stores: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/peripheral-stores/manage-stores/`,
            storeAdd: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/peripheral-stores/manage-stores/add`,
            storeEdit: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, storeId: ApiId = ROUTEPARAMS.P_STORE_ID) =>
                `/contract/${contractId}/peripheral-stores/manage-stores/${storeId}`,
            manageStock: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID, storeId: ApiId = ROUTEPARAMS.P_STORE_ID) =>
                `/contract/${contractId}/peripheral-stores/manage-stock/${storeId}`,
            manageUsers: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/peripheral-stores/manage-users`
        },
        recycledSpecialProducts: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/recycled-special-products`,
            holdTimes: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/recycled-special-products/hold-times`,
            pooledRsps: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/recycled-special-products/pooled-rsps`
        },
        auditHistory: {
            root: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) => `/contract/${contractId}/audit-history`,
            auditHistory: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
                `/contract/${contractId}/audit-history/audit-history`
        }
    },
    PROCUREMENT: {
        root: `/procurement`,
        locationstocklevels: `/LocationStockLevels`,
        suppliers: {
            root: `/procurement/suppliers`,
            supplierDraft: `/procurement/suppliers/draft`,
            supplierOverview: (supplierId: ApiId = ROUTEPARAMS.SUPPLIER_ID) => `/procurement/suppliers/overview/${supplierId}`
        },
        purchase_orders: {
            root: `/procurement/purchase-orders`,
            purchaseOrderDraft: `/procurement/purchase-orders/draft`,
            recommendedPurchaseOrders: (locationId: ApiId = ROUTEPARAMS.LOCATION_ID) =>
                `/procurement/purchase-orders/recommended-purchase-orders/${locationId}`,
            purchaseOrderConfirmation: `/procurement/purchase-orders/confirmation`,
            purchaseOrderOverview: (purchaseOrderId: ApiId = ROUTEPARAMS.PURCHASE_ORDER_ID) =>
                `/procurement/purchase-orders/overview/${purchaseOrderId}`
        },
        special_purchase_orders: {
            root: `/procurement/special-purchase-orders`,
            search: `/procurement/special-purchase-orders/search/`,
            specialReqByLocation: (locationId: ApiId = ROUTEPARAMS.LOCATION_ID) =>
                `/procurement/special-purchase-orders/${locationId}`,
            specialPurchaseOrderOverview: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) =>
                `/procurement/special-purchase-orders/overview/${activityId}`
        }
    },
    WAREHOUSE: {
        root: '/warehouse',
        workshop: {
            root: '/warehouse/workshop',
            job: (jobId: ApiId = ROUTEPARAMS.GUID_ID) => `/warehouse/workshop/${jobId}`
        },
        specialsAndTesting: '/warehouse/specials-testing',
        rspValidityCheckTool: '/warehouse/rsp-validity-check-tool'
    },
    ASSESSMENTS: {
        root: '/assessments',
        professionalAssessments: {
            root: '/assessments/professional-assessments',
            professionalAssessmentCreatePage: '/assessments/professional-assessment-create',
            professionalAssessmentOverviewPage: (professionalAssessmentId: ApiId = ROUTEPARAMS.PROFESSIONAL_ASSESSMENT_ID) =>
                `/assessments/professional-assessment-overview/${professionalAssessmentId}`
        }
    },
    USER_MANAGEMENT: `/manage-users`,
    CATEGORY_MANAGEMENT: `/manage-categories`,
    PRODUCT_MANAGEMENT: {
        list: `/manage-products`,
        create: `/manage-products/create`,
        details: (productId: ApiId = ROUTEPARAMS.PRODUCT_ID) => `/manage-products/details/${productId}`,
        edit: (productId: ApiId = ROUTEPARAMS.PRODUCT_ID) => `/manage-products/edit/${productId}`
    },
    PUKS: {
        root: '/puk-search',
        details: (stockId: ApiId = ROUTEPARAMS.STOCK_ID) => `/puk-search/details/${stockId}`
    },
    INVOICING: {
        list: (contractId: ApiId = ROUTEPARAMS.CONTRACT_ID_OPT) => `/invoicing/${contractId}`,
        details: (invoiceId: ApiId = ROUTEPARAMS.INVOICE_ID) => `/invoicing/details/${invoiceId}`
    },
    SEARCH: {
        root: '/search'
    },
    SERVICE_USERS: {
        root: '/service-users',
        details: (serviceUserId: ApiId = ROUTEPARAMS.SERVICE_USER_ID, contractId: ApiId = ROUTEPARAMS.CONTRACT_ID) =>
            `/service-users/${serviceUserId}/${contractId}`,
        additionalDetails: (
            serviceUserId: ApiId = ROUTEPARAMS.SERVICE_USER_ID,
            contractId: ApiId = ROUTEPARAMS.CONTRACT_ID
        ) => `/service-users/${serviceUserId}/${contractId}/additional-info`,
        eosDetails: (
            serviceUserId: ApiId = ROUTEPARAMS.SERVICE_USER_ID,
            contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
            eosId: ApiId = ROUTEPARAMS.EOS_ID
        ) => `/service-users/${serviceUserId}/${contractId}/equipment-on-site/${eosId}`,
        eosRemoval: (
            serviceUserId: ApiId = ROUTEPARAMS.SERVICE_USER_ID,
            contractId: ApiId = ROUTEPARAMS.CONTRACT_ID,
            eosId: ApiId = ROUTEPARAMS.EOS_ID
        ) => `/service-users/${serviceUserId}/${contractId}/equipment-on-site/${eosId}/remove`
    },
    ACTIVITIES: {
        root: (activityRoot: ActivityRootType | string = ROUTEPARAMS.ACTIVITY_ROOT) => `/${activityRoot}`,
        details: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}`,
        rootCorrections: '/activities/corrections',
        detailsCorrection: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/corrections/${activityId}`,
        charges: (activityId: ApiId = ROUTEPARAMS.ACTIVITY_ID) => `/activities/${activityId}/charges`
    }
};

export type ActivityRootType = 'activities' | 'pstore-activities';
