import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, mapApiErrors } from 'millbrook-core';
import { ContractServiceUserGroupsFormData } from 'pages/Contract/ServiceUser/ServiceUserGroups/components/ContractServiceUserGroupsForm';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractServiceUserGroupsFormToRequest } from '../mappers';
import { clearContractState } from '../overview/overview.slice';

/* types */
export interface ServiceUserGroup {
  id: ApiId;
  contractId: ApiId;
  masterName: string;
  serviceUserGroupId: ApiId;
  onContract: boolean;
}

export type ContractServiceUserGroupId = ApiId;
export interface ContractServiceUserGroups {
  contractId: ApiId;
  serviceUserGroupIds: ContractServiceUserGroupId[];
}

export type ContractServiceUserGroupsRequest = ContractServiceUserGroups;
export type ContractServiceUserGroupsResponse = IApiResponse<ServiceUserGroup[]>;

/* state */
export interface ContractServiceUserGroupsState {
  groups?: ServiceUserGroup[];
}
const initialState: ContractServiceUserGroupsState = {};

/* slice */
const contractContractServiceUserGroupsSlice = createSlice({
  name: 'serviceUserGroups',
  initialState,
  reducers: {
    setContractServiceUserGroups(state, action: PayloadAction<ServiceUserGroup[]>) {
      state.groups = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchContractServiceUserGroups =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ContractServiceUserGroupsResponse>(
      `${ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_GROUPS}?contractId=${contractId}&includeMasterList=true`
    ).then((response) => {
      dispatch(setContractServiceUserGroups(response.result || []));
    });
  };

export const createContractServiceUserGroups =
  (serviceUserGroups: ContractServiceUserGroupsFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<ContractServiceUserGroupsRequest, ContractServiceUserGroupsResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_GROUPS,
      mapContractServiceUserGroupsFormToRequest(serviceUserGroups, contractId)
    ).then(
      () => {
        dispatch<any>(fetchContractServiceUserGroups(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractServiceUserGroups =
  (serviceUserGroups: ContractServiceUserGroupsFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<ContractServiceUserGroupsRequest, ContractServiceUserGroupsResponse>(
      ENDPOINTS.CONTRACT.SERVICE_USER.SERVICE_USER_GROUPS,
      mapContractServiceUserGroupsFormToRequest(serviceUserGroups, contractId)
    ).then(
      () => {
        dispatch<any>(fetchContractServiceUserGroups(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractServiceUserGroups } = contractContractServiceUserGroupsSlice.actions;

/* selectors */
export const selectServiceUserGroupsList = (state: RootState) => state.contract.serviceUser.serviceUserGroups.groups;

/* reducers */
export default contractContractServiceUserGroupsSlice.reducer;
