import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, createHashFromArray, IApiResponse, IMasterProductSummary, mapApiErrors } from 'millbrook-core';
import { CategoryFormData } from 'pages/ProductCategoriesPage/components/CategoryForm/CategoryForm.validation';
import { deleteItem, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';

/* types */
export interface MasterProductCategory {
  id: ApiId;
  parentId?: ApiId;
  name: string;
  imageUrl: string;
  categoryImageFile?: FileList;
  children?: MasterProductCategory[];
  products: IMasterProductSummary[];
  productCount?: number;
}

export type CategoryCreateRequest = FormData;
export type CategoryUpdateRequest = FormData;
export type CategoryAssignRequest = { productId: string; productCategoryId: string };
export type CategoryUnAssignRequest = { productId: string; productCategoryId: string };

/* state */
interface ManageCategoriesState {
  categories: MasterProductCategory[];
  currentCategoryFullDetails?: MasterProductCategory;
  selectedCategoryId?: string;
}

const initialState: ManageCategoriesState = {
  categories: []
};

/* slice */
const ManageCategoriesSlice = createSlice({
  name: 'ManageCategories',
  initialState,
  reducers: {
    updateCategoryList(state, action: PayloadAction<MasterProductCategory[]>) {
      state.categories = action.payload;
    },
    setCurrentCategory(state, action: PayloadAction<MasterProductCategory>) {
      state.currentCategoryFullDetails = action.payload;
    },
    setSelectedCategoryId(state, action: PayloadAction<string | undefined>) {
      state.selectedCategoryId = action.payload;
    },
    resetCurrentCategory(state) {
      state.currentCategoryFullDetails = undefined;
    }
  }
});

/* thunks */
export const fetchCategoryAction =
  (isContracts?: boolean): AppThunk =>
  async (dispatch, getState) => {
    return getItems<IApiResponse<MasterProductCategory[]>>(
      isContracts ? ENDPOINTS.CONTRACT.PRODUCTS.PRODUCT_CATEGORIES : ENDPOINTS.PRODUCTS.CATEGORIES,
      {
        enableGlobalErrorDialog: true,
        disableFullPageLoader: true
      }
    ).then(
      (response) => {
        if (response.result) {
          dispatch(updateCategoryList(response.result));
        }
      },
      (response) => {
        // enableGlobalErrorDialog is handling error message
      }
    );
  };

export const fetchCategoryByIdAction =
  (id: string): AppThunk =>
  async (dispatch, getState) => {
    return getItem<string, IApiResponse<MasterProductCategory>>(ENDPOINTS.PRODUCTS.CATEGORIES, id, {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        if (response.result) {
          dispatch(setCurrentCategory(response.result));
        }
      },
      (response) => {
        // enableGlobalErrorDialog is handling error message
      }
    );
  };

export const createCategoryAction =
  (data: CategoryFormData, parentId?: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const { categoryImageFile, ...restOfData } = data;
    var formData = new FormData();
    formData.append('categoryImage', categoryImageFile[0]);
    formData.append('model', JSON.stringify({ ...restOfData, parentId }));

    return postItems<CategoryCreateRequest, number>(ENDPOINTS.PRODUCTS.CATEGORIES, formData).then(
      (response) => {
        dispatch(fetchCategoryAction());
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateCategoryAction =
  (data: CategoryFormData, id: ApiId, parentId?: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const { categoryImageFile, ...restOfData } = data;
    var formData = new FormData();
    formData.append('categoryImage', categoryImageFile[0]);
    formData.append('model', JSON.stringify({ ...restOfData, parentId, id }));

    return putItem<CategoryUpdateRequest, number>(ENDPOINTS.PRODUCTS.CATEGORIES, formData, id).then(
      (response) => {
        dispatch(fetchCategoryAction());
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteCategoryAction =
  (id: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ApiId, number>(ENDPOINTS.PRODUCTS.CATEGORIES, id, {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(fetchCategoryAction());
      },
      (response) => {
        // enableGlobalErrorDialog is handling error message
      }
    );
  };

export const assignProductAction =
  (productId: ApiId, categoryid: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<CategoryAssignRequest, number>(
      ENDPOINTS.PRODUCTS.CATEGORIES_ASSIGN,
      {
        productId,
        productCategoryId: categoryid
      },
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(fetchCategoryByIdAction(categoryid));
      },
      (response) => {
        // enableGlobalErrorDialog is handling error message
      }
    );
  };

export const unAssignProductAction =
  (productId: ApiId, categoryid: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<CategoryUnAssignRequest, number>(
      ENDPOINTS.PRODUCTS.CATEGORIES_UNASSIGN,
      {
        productId,
        productCategoryId: categoryid
      },
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(fetchCategoryByIdAction(categoryid));
      },
      (response) => {
        // enableGlobalErrorDialog is handling error message
      }
    );
  };

/* actions */
export const { updateCategoryList, setCurrentCategory, resetCurrentCategory, setSelectedCategoryId } =
  ManageCategoriesSlice.actions;

/* selectors */
const selectSelectedCategoryId = (state: RootState) => state.manageCategories.selectedCategoryId;
const selectAllCategories = (state: RootState) => state.manageCategories.categories;

export const selectCurrentCategoryFullDetails = (state: RootState) => state.manageCategories.currentCategoryFullDetails;

export const getHashedCategories = createSelector([selectAllCategories], (categories) =>
  createHashFromArray<MasterProductCategory>(categories)
);

export const selectCategories = createSelector(
  [selectSelectedCategoryId, getHashedCategories, selectAllCategories],
  (id, hash, categories) => {
    if (!id) {
      return categories;
    }

    return hash[id || '']?.children;
  }
);

export const selectCurrentCategory = createSelector([getHashedCategories, selectSelectedCategoryId], (hash, id) => {
  return hash[id || ''];
});

export const selectCurrentParentCategory = createSelector(
  [getHashedCategories, selectSelectedCategoryId, selectAllCategories],
  (hash, id, categories) => {
    if (id && hash[id]) {
      const parentId = hash[id].parentId;
      return parentId ? hash[parentId] : { id: '', children: categories };
    }

    return null;
  }
);

/* reducer */
export default ManageCategoriesSlice.reducer;
