import { combineReducers } from '@reduxjs/toolkit';
import awardDate from './awardDate.slice';
import extensionDates from './extensionDates.slice';
import operationalDates from './operationalDates.slice';
import priceReviewDates from './priceReviewDates.slice';
import workingHours from './workingHours.slice';
import nonWorkingDays from './nonWorkingDays.slice';
import millbrookContact from './millbrookContact.slice';

// SAMPLE - combined slice
export const keyDates = combineReducers({
  awardDate,
  extensionDates,
  operationalDates,
  priceReviewDates,
  workingHours,
  nonWorkingDays,
  millbrookContact
});
