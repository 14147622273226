import {
  AggregatedPermissions,
  ApiAuthorizationRoutes,
  AUTH_ROUTES,
  LoadingSpinner,
  GrantAccess,
  NotAuthorisedPage,
  ProtectedRoute,
  selectUserIsLoggedIn
} from 'millbrook-core';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../constants';

const HomePage = lazy(() => import('pages/HomePage/HomePage'));
const DashboardPage = lazy(() => import('pages/DashboardPage/DashboardPage'));
const AdminRoot = lazy(() => import('pages/Admin/'));
const ContractRoot = lazy(() => import('pages/Contracts/'));
const ContractsListPage = lazy(() => import('pages/Contracts/ContractsListPage/ContractsListPage'));
const EditProductPage = lazy(() => import('pages/ManageProductsPage/ProductFormPage'));
const ManageProductsPage = lazy(() => import('pages/ManageProductsPage/ManageProductsPage'));
const ViewProductPage = lazy(() => import('pages/ManageProductsPage/ViewProductPage'));
const ManageUsersPage = lazy(() => import('pages/ManageUsersPage/ManageUsersPage'));
const ProductCategoriesPage = lazy(() => import('pages/ProductCategoriesPage/ProductCategoriesPage'));
const WarehouseRoot = lazy(() => import('pages/Warehouse'));
const PukSearchRoot = lazy(() => import('pages/PukSearch'));
const InvoicingPage = lazy(() => import('pages/Invoicing/InvoicingPage/InvoicingPage'));
const InvoiceDetails = lazy(() => import('pages/Invoicing/InvoiceDetailsPage/InvoiceDetailsPage'));
const ProcurementRoot = lazy(() => import('pages/Procurement'));
const LocationStockLevelsRoot = lazy(() => import('pages/LocationStockLevels'));
const SearchRoot = lazy(() => import('pages/Search/SearchRoot'));
const ActivitiesRoot = lazy(() => import('pages/Search/ActivitiesRoot'));
const ServiceUsersRoot = lazy(() => import('pages/Search/ServiceUsersRoot'));
const AssessmentRoot = lazy(() => import('pages/Assessments'));

const AppRouter = () => {
  const userIsLoggedIn = useSelector(selectUserIsLoggedIn);

  return (
    <Suspense fallback={<LoadingSpinner isLoading />}>
      <Switch>
        <Route path={ROUTES.HOME} exact component={userIsLoggedIn ? DashboardPage : HomePage} />
        <Route path={AUTH_ROUTES.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <ProtectedRoute path={ROUTES.HOME} exact component={DashboardPage} />
        {/* ASSESSMENTS */}
        <ProtectedRoute path={ROUTES.ASSESSMENTS.root} component={AssessmentRoot} />
        {/* WAREHOUSE */}
        <ProtectedRoute
          path={ROUTES.WAREHOUSE.root}
          component={WarehouseRoot}
          permission={AggregatedPermissions.Maintenance}
        />
        {/* ADMIN */}
        <ProtectedRoute path={ROUTES.ADMIN.root} component={AdminRoot} />
        {/* CONTRACTS */}
        <ProtectedRoute
          path={ROUTES.CONTRACTS.root}
          component={ContractsListPage}
          permission={GrantAccess(['Contract', 'View'])}
        />
        <ProtectedRoute
          path={ROUTES.CONTRACT.root}
          component={ContractRoot}
          permission={GrantAccess(['Contract', 'View'])}
        />
        <ProtectedRoute
          path={ROUTES.USER_MANAGEMENT}
          component={ManageUsersPage}
          permission={GrantAccess(['User', 'View'])}
        />
        <ProtectedRoute
          path={ROUTES.CATEGORY_MANAGEMENT}
          component={ProductCategoriesPage}
          permission={GrantAccess(['ProductCategory', 'View'])}
        />
        <ProtectedRoute
          path={ROUTES.PRODUCT_MANAGEMENT.list}
          component={ManageProductsPage}
          exact
          permission={GrantAccess(['Product', 'View'])}
        />
        <ProtectedRoute
          path={ROUTES.PRODUCT_MANAGEMENT.create}
          component={EditProductPage}
          permission={GrantAccess(['Product', 'Add'])}
        />
        <ProtectedRoute
          path={ROUTES.PRODUCT_MANAGEMENT.edit()}
          component={EditProductPage}
          permission={GrantAccess(['Product', 'Edit'])}
        />
        <ProtectedRoute
          path={ROUTES.PRODUCT_MANAGEMENT.details()}
          component={ViewProductPage}
          permission={GrantAccess(['Product', 'View'])}
        />

        <ProtectedRoute path={ROUTES.PUKS.root} component={PukSearchRoot} />

        <ProtectedRoute path={ROUTES.SEARCH.root} component={SearchRoot} />

        <ProtectedRoute path={ROUTES.ACTIVITIES.root()} component={ActivitiesRoot} />

        <ProtectedRoute path={ROUTES.SERVICE_USERS.root} component={ServiceUsersRoot} />

        {/* INVOICING */}
        <ProtectedRoute
          path={ROUTES.INVOICING.details()}
          exact
          component={InvoiceDetails}
          permission={GrantAccess(['Invoicing', 'View'])}
        />
        <ProtectedRoute
          path={ROUTES.INVOICING.list()}
          exact
          component={InvoicingPage}
          permission={GrantAccess(['Invoicing', 'View'])}
        />

        {/* PROCUREMENT */}
        <ProtectedRoute path={ROUTES.PROCUREMENT.root} component={ProcurementRoot} />
        <ProtectedRoute
          path={ROUTES.PROCUREMENT.special_purchase_orders.specialReqByLocation()}
          component={ProcurementRoot}
        />
        <ProtectedRoute path={ROUTES.PROCUREMENT.locationstocklevels} component={LocationStockLevelsRoot} />
        <Route path={ROUTES.NOT_AUTHORISED} render={() => <NotAuthorisedPage url={ROUTES.HOME} />} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
};

export { AppRouter };
