import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROUTES } from 'constants/routes';
import { setupContractSections } from 'helpers/mappers';
import { ApiId } from 'millbrook-core';
import { AppThunk, RootState } from 'store/store';
import { setContractServiceList } from '../scope/contractServices.slice';
import { ContractNavStates, ContractSection } from './sectionSteps/contractNav.types';
import { contractNavScopeSteps } from './sectionSteps/scope.nav';
import { ContractNavSection } from './sectionSteps/toplevel.nav';

interface ContractNavState {
  currentSection?: ContractNavSection;
  currentTopLevelSectionCode?: ContractNavSection;
  sections: ContractSection[];
  currentStep?: ContractSection;
}

const initialState: ContractNavState = { sections: [] };

const contractNavSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setContractNav(state, action: PayloadAction<ContractSection[]>) {
      state.sections = action.payload;
    },
    setContractNavSection(state, action: PayloadAction<ContractNavSection | undefined>) {
      state.currentSection = action.payload;
      if (!action.payload) {
        state.currentStep = undefined;
      }
    },
    // this is set from the list item. Is that bad?
    setContractNavStep(state, action: PayloadAction<ContractSection | undefined>) {
      state.currentStep = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setContractServiceList, (state, action) => {
      const services = action.payload;

      const contractId = services[0].contractId;
      // find the scope step. Do I need to clone with this state, seems to work without complaint
      const sections = state.sections;

      const scope = sections.find((s) => s.step === ContractNavSection.SCOPE);

      if (scope) {
        const steps = contractNavScopeSteps(
          contractId,
          services.filter((s) => s.onContract)
        );

        // need to set the next href
        setupContractSections(steps, scope.next || '');

        scope.subSections = steps;
      }
    });
    // NOTE: Don't clear the contract nav state. It doesn't need to because the scope is recreated from the other slices that have been blitzed,
    // and there is an issue when creating a new contract where the initContractNav happens before the clearContractState, but the component doesn't rerender in that case.
    // Leaving this here as a warning.
  }
});

// actions
export const { setContractNav, setContractNavSection, setContractNavStep } = contractNavSlice.actions;

// thunks
// This doesn't need to be a thunk, but when(if) the extra work comes in to introduce an api, then the merge between BE step states and FE output will probably be done here
export const initContractNav =
  (contractId: ApiId = '', contractSteps: ContractSection[]): AppThunk =>
  async (dispatch) => {
    if (!contractId) {
      // this is the draft form. Attempting to "disable" all of the steps
      dispatch(setContractNav(contractSteps.map((step) => ({ ...step, state: ContractNavStates.DISABLED }))));
    } else {
      // default next for last item will be the contract overview
      let next = ROUTES.CONTRACT.overview(contractId);

      setupContractSections(contractSteps, next);

      dispatch(setContractNav(contractSteps));
    }
  };

// selectors
export const selectContractNavSections = (state: RootState) => state.contract.navigation.sections;
export const selectContractNavCurrentStep = (state: RootState) => state.contract.navigation.currentStep;
const selectContractNavCurrentSection = (state: RootState) => state.contract.navigation.currentSection;

export const getTopLevelSection = createSelector(
  [selectContractNavCurrentSection, selectContractNavSections],
  (step, sections) => {
    return sections.find((s) => s.step === step);
  }
);

export default contractNavSlice.reducer;
