import { combineReducers } from '@reduxjs/toolkit';
import serviceUserGroups from './serviceUserGroups.slice';
import serviceUserFields from './serviceUserFields.slice';
import additionalServiceUserFields from './additionalServiceUserFields.slice';
import serviceUserAlerts from './serviceUserAlerts.slice';
import serviceUserInactiveReasons from './serviceUserInactiveReasons.slice';
import defineGPsByCCG from './defineGPsByCCG.slice';
import propertyOwnershipTypes from './contractPropertyOwnershipTypes.slice';

export const serviceUser = combineReducers({
    serviceUserGroups,
    serviceUserFields,
    additionalServiceUserFields,
    serviceUserAlerts,
    serviceUserInactiveReasons,
    defineGPsByCCG,
    propertyOwnershipTypes
});
