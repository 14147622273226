import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IDeleteRequest, mapApiErrors } from 'millbrook-core';
import { deleteItems, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { PinArea, PinAreaRequest, PinAreaResponse } from './budgetPin.types';

interface AreasState {
  areaList: PinArea[];
}

const initialState: AreasState = { areaList: [] };

const areasSlice = createSlice({
  name: 'areas',
  initialState,
  reducers: {
    setAreaList(state, action: PayloadAction<PinArea[]>) {
      state.areaList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

const cacheName = 'pin-areas';

// thunks for areas
export const fetchAreas =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PinAreaResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.AREAS(contractId), { cacheName }).then(
      (response) => {
        dispatch(setAreaList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createArea =
  (data: PinArea): AppThunk =>
  async (dispatch) => {
    const { id, ...mappedData } = data;
    return postItems<PinAreaRequest, PinAreaResponse>(
      ENDPOINTS.CONTRACT.BUDGET_PIN.AREAS(),
      {
        ...mappedData
      },
      { cacheName }
    ).then(
      () => {
        dispatch<any>(fetchAreas(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateArea =
  (data: PinArea): AppThunk =>
  async (dispatch) => {
    return putItem<PinAreaRequest, PinAreaResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.AREAS(), data, data.id, {
      cacheName
    }).then(
      () => {
        dispatch<any>(fetchAreas(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteAreas =
  (data: PinArea[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.id);

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.BUDGET_PIN.AREAS(), ids, {
      enableGlobalErrorDialog: true,
      cacheName
    }).then(
      () => {
        dispatch<any>(fetchAreas(data[0].contractId));
      },
      () => {
        // handled with global error handler
      }
    );
  };

/* actions */
export const { setAreaList } = areasSlice.actions;

/* selectors */
export const selectAreaList = (state: RootState) => state.contract.budgetPin.areas.areaList;

export const getAreaSelectOptions = createSelector([selectAreaList], (areaList) => {
  return areaList.map((area) => {
    return { label: area.name, value: area.id };
  });
});

/* reducers */
export default areasSlice.reducer;
