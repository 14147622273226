import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
  [ContractSectionEnum.HoldTimes]: 'Hold times',
  [ContractSectionEnum.PooledRsps]: 'Pooled RSPs'
};

const route = ROUTES.CONTRACT.recycledSpecialProducts;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavRecycledSpecialProductsSteps = (contractId: ApiId): ContractSection[] => [
  {
    step: ContractSectionEnum.HoldTimes,
    name: stepDisplayName[ContractSectionEnum.HoldTimes],
    href: route.holdTimes(contractId)
  },
  {
    step: ContractSectionEnum.PooledRsps,
    name: stepDisplayName[ContractSectionEnum.PooledRsps],
    href: route.pooledRsps(contractId)
  }
];
