import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, IsoDate, mapApiErrors, PartialBy } from 'millbrook-core';
import { OperationalDatesFormData } from 'pages/Contract/KeyDates/OperationalDates/components/OperationalDatesForm/OperationalDatesForm';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

// types
// SAMPLE - TYPES: default, request, response and state types
export interface OperationalDates {
  id: ApiId;
  contractId?: ApiId;
  startDate: IsoDate;
  endDate: IsoDate;
}

export type OperationalDatesRequest = PartialBy<OperationalDates, 'id'>;

export type OperationalDatesResponse = IApiResponse<OperationalDates>;

interface OperationalDatesState extends OperationalDates {}

const initialState: OperationalDatesState = {
  id: '',
  startDate: null,
  endDate: null
};

const contractOperationalDatesSlice = createSlice({
  name: 'operationalDates',
  initialState,
  reducers: {
    setOperationalDates(state, action: PayloadAction<OperationalDates | undefined>) {
      if (action.payload) {
        state.id = action.payload.id;
        state.startDate = action.payload.startDate;
        state.endDate = action.payload.endDate;
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const { setOperationalDates } = contractOperationalDatesSlice.actions;

export const fetchOperationalDates =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<OperationalDatesResponse>(ENDPOINTS.CONTRACT.KEY_DATES.OPERATIONAL_DATES(contractId), {
      enableGlobalErrorDialog: true
    })
      .then((response) => {
        // 204 response returns no data (meaning the user hasn't entered this data yet),
        // just send the initialstate
        dispatch(setOperationalDates(response.result || initialState));
      })
      .catch((e) => {
        // handled in global error handler
      });
  };

// SAMPLE - MAPPING REQUEST
// mapping form data to request
export const createOperationalDates =
  (operationalDates: OperationalDatesFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<OperationalDatesRequest, OperationalDatesResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.OPERATIONAL_DATES(),
      { ...(operationalDates as any), contractId }
    ).then(
      () => {
        dispatch<any>(fetchOperationalDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateOperationalDates =
  (operationalDates: OperationalDatesFormData, contractId: ApiId, id: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<OperationalDatesRequest, OperationalDatesResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.OPERATIONAL_DATES(),
      { ...(operationalDates as any), contractId, id },
      id,
      undefined,
      false
    ).then(
      () => {
        dispatch<any>(fetchOperationalDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// selectors
export const selectOperationalDatesState = (state: RootState) => state.contract.keyDates.operationalDates;
export const selectOperationalDates = createSelector([selectOperationalDatesState], (operationalDates) => {
  return {
    id: operationalDates.id,
    startDate: operationalDates.startDate,
    endDate: operationalDates.endDate
  };
});

export default contractOperationalDatesSlice.reducer;
