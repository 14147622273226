import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { ApiId, ErrorResponse, IApiResponse, IMediaModel, mapApiErrors } from 'millbrook-core';
import { serialize } from 'object-to-formdata';
import { ENDPOINTS } from '../../constants';
import { deleteItem, getItem, getItems, postItems, putItem } from '../../services/api.service';
import { AppThunk } from '../../store/store';
import { ProfessionalAssessmentFormData } from './professionalAssessments/components/ProfessionalAssessmentCreateForm/ProfessionalAssessmentCreateForm';

interface AssessmentsState {
  professionalAssessments: ProfessionalAssessment[];
  professionalAssessment?: ProfessionalAssessment;
}

const initialState: AssessmentsState = {
  professionalAssessments: []
};

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState,
  reducers: {
    setProfessionalAssessments(state, action: PayloadAction<ProfessionalAssessment[]>) {
      state.professionalAssessments = action.payload;
    },
    setProfessionalAssessment(state, action: PayloadAction<ProfessionalAssessment | undefined>) {
      state.professionalAssessment = action.payload;
    }
  }
});

export interface ProfessionalAssessment {
    id: ApiId;
    title: string;
    image?: IMediaModel;
    professionalAssessmentQuestions: ProfessionalAssessmentQuestion[];
}

export interface ProfessionalAssessmentQuestion {
    id: ApiId;
    professionalAssessmentQuestionReference: number;
    question: string;
    sometimesOption: boolean;
    disabled: boolean;
}

/* types */
export type ProfessionalAssessmentListResponse = IApiResponse<ProfessionalAssessment[]>;
export type ProfessionalAssessmentResponse = IApiResponse<ProfessionalAssessment>;

/* thunks */
export const fetchProfessionalAssessments = (): AppThunk => async (dispatch) => {
    return getItems<ProfessionalAssessmentListResponse>(ENDPOINTS.ASSESSMENTS.PROFESSIONAL_ASSESSMENT).then(
        (response) => {
            dispatch(setProfessionalAssessments(response.result || []));
        }
    );
};

export const fetchProfessionalAssessment =
    (id: ApiId): AppThunk =>
        async (dispatch) => {
            return getItem<ApiId, ProfessionalAssessmentResponse>(ENDPOINTS.ASSESSMENTS.PROFESSIONAL_ASSESSMENT, id).then(
                (response) => {
                    dispatch(setProfessionalAssessment(response.result || undefined));
                    return response;
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const createProfessionalAssessment =
    (newProfessionalAssessment: ProfessionalAssessmentFormData): AppThunk =>
        async (dispatch) => {
            const { imageToUpload, ...rest } = newProfessionalAssessment;
            const formData = serialize(rest, { allowEmptyArrays: true, indices: true });

            for (let i = 0; i < imageToUpload.length; i++) {
                formData.append('imageToUpload', imageToUpload[i]);
            }
            return postItems<FormData, ProfessionalAssessmentResponse>(
                ENDPOINTS.ASSESSMENTS.PROFESSIONAL_ASSESSMENT,
                formData
            ).then(
                () => {
                    dispatch<any>(fetchProfessionalAssessments());
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateProfessionalAssessment =
    (professionalAssessment: ProfessionalAssessmentFormData): AppThunk =>
        async (dispatch) => {
            const { imageToUpload, ...rest } = professionalAssessment;
            const formData = serialize(rest, { allowEmptyArrays: true, indices: true });

            for (let i = 0; i < imageToUpload.length; i++) {
                formData.append('imageToUpload', imageToUpload[i]);
            }

            return putItem<FormData, ProfessionalAssessmentResponse>(
                ENDPOINTS.ASSESSMENTS.PROFESSIONAL_ASSESSMENT,
                formData,
                professionalAssessment.id
            ).then(
                (response) => {
                    dispatch(setProfessionalAssessment(response.result || undefined));
                },
                (response: ErrorResponse) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const deleteProfessionalAssessment =
    (id: ApiId): AppThunk =>
        async (dispatch) => {
            return deleteItem<ApiId, ProfessionalAssessmentListResponse>(
                ENDPOINTS.ASSESSMENTS.PROFESSIONAL_ASSESSMENT,
                id
            ).then(
                (response) => {
                    dispatch(setProfessionalAssessments(response.result || []));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };


export const { setProfessionalAssessments, setProfessionalAssessment } = assessmentsSlice.actions;

export const selectProfessionalAssessments = (state: RootState) => state.assessments.professionalAssessments;
export const selectProfessionalAssessment = (state: RootState) => state.assessments.professionalAssessment;

export default assessmentsSlice.reducer;
