import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ApiId, getItem, getItems, IApiResponse, mapApiErrors, PartialBy, postItems, putItem } from "millbrook-core";
import { ENDPOINTS } from "../../../constants";
import { AppThunk, RootState } from "../../../store/store";
import { ContractRetailerUserModel } from "./retailerusers.types";

/* types */
export type ContractRetailerUserOverviewListResponse = IApiResponse<ContractRetailerUserModel[]>;

export type ContractRetailerUserRequest = PartialBy<ContractRetailerUserModel, 'id' | 'dateCreated'>;

export type ContractRetailerUserResponse = IApiResponse<ContractRetailerUserModel[]>;

//const cacheName = 'contract-retailer-user';

/* thunks */
export const fetchContractRetailerUsers =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return getItems<ContractRetailerUserOverviewListResponse>(
                ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER_USER(contractId)
            ).then((response) => {
                dispatch(setContractRetailerUsersOverview(response.result || []));
            });
        };

export const fetchContractRetailerUser =
    (id: ApiId): AppThunk =>
        async (dispatch) => {
            return getItem<ApiId, ContractRetailerUserResponse>(
                ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER_USER(), id
            ).then((response) => {
                return response.result || {};
            });
        };

export const createContractRetailerUser =
    (data: ContractRetailerUserRequest, contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return postItems<ContractRetailerUserRequest, ContractRetailerUserResponse>(ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER_USER(), data).then(
                (response) => {
                    dispatch<any>(fetchContractRetailerUsers(contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateContractRetailerUser =
    (data: ContractRetailerUserModel, contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return putItem<ContractRetailerUserRequest, ContractRetailerUserResponse>(ENDPOINTS.CONTRACT.PRESCRIPTION_PORTAL.CONTRACT_RETAILER_USER(), data, data.id).then(
                (response) => {
                    dispatch<any>(fetchContractRetailerUsers(contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };


interface ContractRetailersState {
    contractRetailerUsersOverview: ContractRetailerUserModel[];
}

const initialState: ContractRetailersState = { 
    contractRetailerUsersOverview: []
};

const contractRetailerUsersSlice = createSlice({
    name: 'contractRetailerUsers',
    initialState,
    reducers: {
        setContractRetailerUsersOverview(state, action: PayloadAction<ContractRetailerUserModel[]>) {
            state.contractRetailerUsersOverview = action.payload;
        }
    }
});

export const {
    setContractRetailerUsersOverview
} = contractRetailerUsersSlice.actions;

export const selectContractRetailerUsersOverview = (state: RootState) =>
    state.contract.prescriptionPortal.contractRetailerUsers.contractRetailerUsersOverview;

export default contractRetailerUsersSlice.reducer;
