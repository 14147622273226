const regexpGuid = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';

export const ROUTEPARAMS = {
    CONTRACT_ID: ':contractId',
    CONTRACT_ID_OPT: ':contractId?',
    PIN_GROUP_ID: ':pinGroupId',
    CONTRACT_SERVICE_TYPE_ID: ':contractServiceTypeId',
    USER_ID: ':userId',
    PRODUCT_CATEGORY_ID: ':productCategoryId?',
    PRODUCT_ID: ':productId',
    SUPPLIER_ID: ':supplierId',
    PURCHASE_ORDER_ID: ':purchaseOrderId',
    HELP_PAGE_ID: ':helpPageId',
    SERVICE_USER_ID: ':serviceUserId',
    EOS_ID: ':eosId',
    ACTIVITY_ID: ':activityId',
    ACTIVITY_ROOT: ':activityRoot(activities|pstore-activities)',
    P_STORE_ID: ':pstoreId?',
    LOCATION_ID: ':locationId',
    STOCK_ID: ':stockId',
    INVOICE_ID: ':invoiceId',
    PROFESSIONAL_ASSESSMENT_ID: ':professionalAssessmentId',
    CONTRACT_PROFESSIONAL_ASSESSMENT_ID: ':contractProfessionalAssessmentId',
    CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_ID: ':contractProfessionalAssessmentQuestionId',
    GUID_ID: `:id(${regexpGuid})`,
    CONTRACT_RETAILER_ID: ':contractRetailerId',
    CONTRACT_RETAILER_USER_ID: ':contractRetailerUserId'
};
