import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, IsoDate, mapApiErrors } from 'millbrook-core';
import { AwardDateFormData } from 'pages/Contract/KeyDates/AwardDate/components/AwardDateForm/AwardDateForm';
import { getItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

// types
export interface AwardDate {
  contractId: ApiId;
  awardDate: IsoDate;
}

export type AwardDateRequest = AwardDate;
export type AwardDateResponse = IApiResponse<AwardDate>;
type AwardDateState = Omit<AwardDate, 'contractId'>;

const initialState: AwardDateState = {
  awardDate: null
};

const contractAwardDateSlice = createSlice({
  name: 'awardDate',
  initialState,
  reducers: {
    setAwardDate(state, action: PayloadAction<AwardDate | undefined>) {
      state.awardDate = action.payload?.awardDate || null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// actions
export const { setAwardDate } = contractAwardDateSlice.actions;

// thunks
export const fetchAwardDate =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<AwardDateResponse>(ENDPOINTS.CONTRACT.KEY_DATES.AWARD_DATE(contractId)).then((response) => {
      dispatch(setAwardDate(response.result));
    });
  };

export const updateAwardDate =
  (awardDate: AwardDateFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<AwardDateRequest, AwardDateResponse>(ENDPOINTS.CONTRACT.KEY_DATES.AWARD_DATE(contractId), {
      awardDate: awardDate.awardDate,
      contractId
    }).then(
      () => {
        dispatch<any>(fetchAwardDate(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// selectors
export const selectAwardDate = (state: RootState) => state.contract.keyDates.awardDate.awardDate;

export default contractAwardDateSlice.reducer;
