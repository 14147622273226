import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ChargingMechanism,
  ContractDraftCreateRequest,
  ContractDraftCreateResponse,
  ContractStateEnum,
  ContractListItemModel
} from 'features/contract/contract.types';
import { mapContractDraftFormToRequest } from 'features/contract/mappers';
import { setContractStatus } from 'features/contract/overview/overview.slice';
import { ApiId, ErrorResponse, IApiResponse, ISelectOption, IServiceCentreSummary, mapApiErrors } from 'millbrook-core';
import { ContractDraftFormData } from 'pages/Contracts/ContractDraftPage/components/ContractDraftForm/ContractDraftForm.validation';
import { getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractsListFromServer } from './fetch-mappers';

/* types */

export type ContractsListResponse = IApiResponse<ContractListItemModel[]>;

export interface ContractDraftData {
  locations: IServiceCentreSummary[];
  chargingMechanisms: ChargingMechanism[];
}

export type ContractDraftDataResponse = IApiResponse<ContractDraftData>;

/* state */
interface ContractsState {
  contracts?: ContractListItemModel[];
  serviceCentres?: IServiceCentreSummary[];
  chargingMechanisms?: ChargingMechanism[];
  filterContractStatus: ContractStateEnum | 'All';
  filterContractText: string;
}

const initialState: ContractsState = {
  filterContractStatus: ContractStateEnum.Live,
  filterContractText: ''
};

/* slice */
const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    updateContractsList(state, action: PayloadAction<ContractListItemModel[]>) {
      state.contracts = action.payload;
      // if there are no live contracts, then default to All. Probably won't be an issue with the live site.
      if (action.payload && !action.payload.find((contract) => contract.contractState === ContractStateEnum.Live)) {
        state.filterContractStatus = 'All';
      }
    },
    setContractsFilter(state, action: PayloadAction<ContractStateEnum | 'All'>) {
      state.filterContractStatus = action.payload;
    },
    setContractData(state, action: PayloadAction<ContractDraftData | undefined>) {
      if (action.payload) {
        state.serviceCentres = action.payload.locations;
        state.chargingMechanisms = action.payload.chargingMechanisms;
      }
    },
    setContractsFilterText(state, action: PayloadAction<string>) {
      state.filterContractText = action.payload;
    }
  }
});

/* thunks */
export const CONTRACT_CACHE_NAME = 'contracts';

export const fetchContractsList =
  (state?: ContractStateEnum): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ContractsListResponse>(ENDPOINTS.CONTRACTS + (state ? `?state=${state}` : ''), {
      cacheName: CONTRACT_CACHE_NAME
    }).then(
      (response) => {
        dispatch(updateContractsList(mapContractsListFromServer(response.result || [])));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchContractsDraftData = (): AppThunk => async (dispatch, getState) => {
  return getItems<ContractDraftDataResponse>(ENDPOINTS.CONTRACT.DRAFT.DATA).then((response) => {
    dispatch(setContractData(response.result || undefined));
  });
};

export const createContractDraft =
  (newContract: ContractDraftFormData): AppThunk =>
  async (dispatch, getState) => {
    return postItems<ContractDraftCreateRequest, IApiResponse<ContractDraftCreateResponse>>(
      ENDPOINTS.CONTRACTS,
      mapContractDraftFormToRequest(newContract),
      {
        cacheName: CONTRACT_CACHE_NAME
      }
    ).then(
      (response: IApiResponse<ContractDraftCreateResponse>) => {
        return response.result?.id || undefined;
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const activateContract =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<{}, IApiResponse<{}>>(
      ENDPOINTS.CONTRACT.ACTIVATE(id),
      {},
      {
        enableGlobalErrorDialog: true,
        cacheName: CONTRACT_CACHE_NAME
      }
    );
  };

export const archiveContract =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<{}, IApiResponse<{}>>(
      ENDPOINTS.CONTRACT.ARCHIVE(id),
      {},
      {
        enableGlobalErrorDialog: true,
        cacheName: CONTRACT_CACHE_NAME
      }
    ).then((response) => {
      dispatch<any>(setContractStatus(ContractStateEnum.Archive));
    });
  };

/* actions */
export const { updateContractsList, setContractsFilter, setContractData, setContractsFilterText } =
  contractsSlice.actions;

/* selectors */
export const selectContractsState = (state: RootState) => state.contracts;
export const selectContracts = (state: RootState) => state.contracts && state.contracts.contracts;

export const selectContractsCount = (state: RootState) => state.contracts.contracts?.length || 0;

export const selectFilterContractText = (state: RootState) => state.contracts.filterContractText;

export const selectContractOptions = createSelector([selectContracts], (contracts): ISelectOption[] => {
  return contracts?.map((c) => ({ label: c.name, value: c.id })) || [];
});

export const selectFilterContractStatus = (state: RootState) => state.contracts && state.contracts.filterContractStatus;

export const selectFilteredContracts = createSelector(
  [selectContracts, selectFilterContractStatus, selectFilterContractText],
  (contracts, stateFilter, textFilter) => {
    if (stateFilter === 'All' && !textFilter) {
      return contracts;
    }

    return contracts?.filter((contract) =>
      textFilter
        ? contract.name.toLowerCase().includes(textFilter.toLowerCase())
        : contract.contractState === stateFilter
    );
  }
);
export const selectContractsServiceCentres = (state: RootState) =>
  (state.contracts && state.contracts.serviceCentres) || [];
export const selectContractsChargingMechanisms = (state: RootState) =>
  state.contracts && state.contracts.chargingMechanisms;

/* reducers */
export default contractsSlice.reducer;
