import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, IsoDate, mapApiErrors, PartialBy } from 'millbrook-core';
import { NonWorkingDayFormData } from 'pages/Contract/KeyDates/NonWorkingDays/components/NonWorkingDayForm/NonWorkingDayForm';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

// types
export type NonWorkingDay = {
  id: ApiId;
  contractId?: ApiId;
  date: IsoDate;
};

export type NonWorkingDayRequest = PartialBy<NonWorkingDay, 'id'>;

export type NonWorkingDaysResponse = IApiResponse<NonWorkingDay[]>;

interface NonWorkingDayState {
  dates?: NonWorkingDay[];
}

const initialState: NonWorkingDayState = {};

const contractNonWorkingDaysSlice = createSlice({
  name: 'nonWorkingDays',
  initialState,
  reducers: {
    setNonWorkingDays(state, action: PayloadAction<NonWorkingDay[]>) {
      state.dates = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const { setNonWorkingDays } = contractNonWorkingDaysSlice.actions;

// thunks
export const fetchNonWorkingDays =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<NonWorkingDaysResponse>(ENDPOINTS.CONTRACT.KEY_DATES.NON_WORKING_DAYS(contractId)).then(
      (response) => {
        dispatch(setNonWorkingDays(response.result || []));
      }
    );
  };

export const createNonWorkingDay =
  (nonWorkingDay: NonWorkingDayFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<NonWorkingDayRequest, NonWorkingDaysResponse>(ENDPOINTS.CONTRACT.KEY_DATES.NON_WORKING_DAYS(), {
      ...(nonWorkingDay as any),
      contractId
    }).then(
      () => {
        dispatch<any>(fetchNonWorkingDays(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateNonWorkingDay =
  (nonWorkingDay: NonWorkingDayFormData, id: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<NonWorkingDayRequest, NonWorkingDaysResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.NON_WORKING_DAYS(),
      { ...(nonWorkingDay as any), contractId, id },
      id
    ).then(
      () => {
        dispatch<any>(fetchNonWorkingDays(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteNonWorkingDay =
  (nonWorkingDayId: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ApiId, NonWorkingDaysResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.NON_WORKING_DAYS(),
      nonWorkingDayId
    ).then(
      () => {
        dispatch<any>(fetchNonWorkingDays(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// selectors
export const selectNonWorkingDays = (state: RootState) => state.contract.keyDates.nonWorkingDays.dates;

export default contractNonWorkingDaysSlice.reducer;
