import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { MasterCleaningCode } from 'features/masterLists/masterCleaningCodes/masterCleaningCodes.slice';
import { ApiId, ErrorResponse, IApiResponse, ISelectOption, mapApiErrors } from 'millbrook-core';
import { getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

/* types */
export interface CleaningCodeMappingDetails {
  cleaningCodeId: ApiId;
  masterName: string;
  masterDescription: string;
}

export interface ContractCleaningCode extends MasterCleaningCode, CleaningCodeMappingDetails {
  onContract: boolean;
}

export interface ContractCleaningCodeRequest extends Omit<ContractCleaningCode, 'id'> {
  id?: ApiId;
  contractId: ApiId;
}

export type ContractCleaningCodeResponse = IApiResponse<ContractCleaningCode>;
export type ContractCleaningCodeListResponse = IApiResponse<ContractCleaningCode[]>;

/* state */
interface ContractCleaningCodesState {
  cleaningCodes?: ContractCleaningCode[];
}

const initialState: ContractCleaningCodesState = {};

const contractCleaningCodesSlice = createSlice({
  name: 'cleaningCodesAndCharges',
  initialState,
  reducers: {
    setCleaningCodes(state, action: PayloadAction<ContractCleaningCode[]>) {
      state.cleaningCodes = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchContractCleaningCodes =
  (contractId: ApiId, includeMasterList: boolean = true): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ContractCleaningCodeListResponse>(
      `${ENDPOINTS.CONTRACT.PRICING.CLEANING_CODES}?contractId=${contractId}&includeMasterList=${includeMasterList}`,
      { enableGlobalErrorDialog: true }
    )
      .then((response) => {
        const responseMap = (response.result || []).map((cleaningCode) => ({
          ...cleaningCode,
          selected: true
        }));

        dispatch(setCleaningCodes(responseMap));
      })
      .catch((e: any) => {
        // handled in global handler
      });
  };

export const editContractCleaningCode =
  (data: ContractCleaningCodeRequest): AppThunk =>
  async (dispatch, getState) => {
    const request = data.id
      ? putItem<ContractCleaningCodeRequest, ContractCleaningCodeResponse>(
          ENDPOINTS.CONTRACT.PRICING.CLEANING_CODES,
          data,
          data.id
        )
      : postItems<ContractCleaningCodeRequest, ContractCleaningCodeResponse>(
          `${ENDPOINTS.CONTRACT.PRICING.CLEANING_CODES}?contractId=${data.contractId}`,
          data
        );

    request.then(
      () => {
        dispatch<any>(fetchContractCleaningCodes(data.contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setCleaningCodes } = contractCleaningCodesSlice.actions;

/* selectors */
export const selectContractCleaningCodes = (state: RootState) =>
  state.contract.scope.cleaningCodesAndCharges.cleaningCodes;

export const getCleaningCodeOptions = createSelector(
  [selectContractCleaningCodes],
  (cleaningCodes): ISelectOption[] => {
    return (cleaningCodes || []).map((cleaningCode) => {
      const { name, description, id } = cleaningCode;
      return { label: `${name} - ${description}`, value: id };
    });
  }
);

export default contractCleaningCodesSlice.reducer;
