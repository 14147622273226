import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
    [ContractSectionEnum.DefineServiceUserGroups]: 'Define service user groups',
    [ContractSectionEnum.ServiceUserDataFields]: 'Service user data fields',
    [ContractSectionEnum.ServiceUserAlerts]: 'Service user alerts',
    [ContractSectionEnum.InactiveReasons]: 'Inactive reasons',
    [ContractSectionEnum.DefineGPsByCCG]: 'Define GPs by CCG',
    [ContractSectionEnum.PropertyOwnershipTypes] : 'Property ownership types'
};

const route = ROUTES.CONTRACT.serviceUser;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavServiceUserSteps = (contractId: ApiId): ContractSection[] => [
    {
        step: ContractSectionEnum.DefineServiceUserGroups,
        name: stepDisplayName[ContractSectionEnum.DefineServiceUserGroups],
        href: route.serviceUserGroups(contractId)
    },
    {
        step: ContractSectionEnum.ServiceUserDataFields,
        name: stepDisplayName[ContractSectionEnum.ServiceUserDataFields],
        href: route.serviceUserFields(contractId)
    },
    {
        step: ContractSectionEnum.ServiceUserAlerts,
        name: stepDisplayName[ContractSectionEnum.ServiceUserAlerts],
        href: route.serviceUserAlerts(contractId)
    },
    {
        step: ContractSectionEnum.InactiveReasons,
        name: stepDisplayName[ContractSectionEnum.InactiveReasons],
        href: route.inactiveReasons(contractId)
    },
    {
        step: ContractSectionEnum.DefineGPsByCCG,
        name: stepDisplayName[ContractSectionEnum.DefineGPsByCCG],
        href: route.defineGPsByCCG(contractId)
    },
    {
        step: ContractSectionEnum.PropertyOwnershipTypes,
        name: stepDisplayName[ContractSectionEnum.PropertyOwnershipTypes],
        href: route.propertyOwnershipTypes(contractId)
    }
];
