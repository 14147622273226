import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from 'components/MainLayout/MainLayout';
import {
  AuthProvider,
  ConfirmationServiceProvider,
  ErrorDialog,
  hideGenericErrorDialog,
  LoadingSpinner,
  NotAuthenticatedDialog,
  NotificationsProvider,
  SavePromptProvider,
  selectShowGenericErrorDialog,
  selectShowNotAuthenticatedDialog,
  selectUiIsLoading,
  THEME
} from 'millbrook-core';
import { useDispatch, useSelector } from 'react-redux';
import 'scss/App.scss';
import { AppRouter } from './AppRouter';

const useStyles = makeStyles((theme) => ({
  container: {
    overflow: 'hidden'
  }
}));

function App() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isLoading = useSelector(selectUiIsLoading);
  const isAuthenticationDialogShown = useSelector(selectShowNotAuthenticatedDialog);
  const genericErrorDialogMessage = useSelector(selectShowGenericErrorDialog);

  return (
    <ThemeProvider theme={THEME}>
      <NotificationsProvider>
        <ConfirmationServiceProvider>
          <AuthProvider pageTitleDefault="Millbrook CARES">
            <SavePromptProvider>
              <CssBaseline />
              <LoadingSpinner isLoading={isLoading} />
              {isAuthenticationDialogShown && <NotAuthenticatedDialog />}
              {genericErrorDialogMessage && (
                <ErrorDialog
                  open={!!genericErrorDialogMessage}
                  message={genericErrorDialogMessage}
                  onClose={() => dispatch(hideGenericErrorDialog())}
                />
              )}
              <div id="appContainer" className={classes.container}>
                <MainLayout>
                  <AppRouter />
                </MainLayout>
              </div>
            </SavePromptProvider>
          </AuthProvider>
        </ConfirmationServiceProvider>
      </NotificationsProvider>
    </ThemeProvider>
  );
}

export default App;
