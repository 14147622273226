import { WarehouseProductDataRequest } from 'features/warehouse/warehouse.slice';
import { ApiId, IMediaModel, mapArrayFlagsToEnum, ProductPropertyDefinition, UploadFile360 } from 'millbrook-core';
import {
    ProductFormData,
    ProductWarehouseFormData
} from 'pages/ManageProductsPage/components/ProductForm/ProductForm.validation';
import { MasterProductCreateRequest, ProductImagesUploadRequest } from './manageProducts.slice';

export const mapProductDataToCreateRequest = (data: ProductFormData): MasterProductCreateRequest => {
  /*
   * Transform data for create product API
   * api accepts a formData object with productImageFiles and a model for the form data
   * productImageFiles is a single file which will be set as the main product image (productListingImageId)
   * if there are multiple images to be uploaded we upload the remaining images using the uploadMasterProductImages action
   */
  const { productImageFiles, value, skillsRequired } = data;

  let productCreateRequest = new FormData();
  productCreateRequest.append('productImageFiles', productImageFiles[0]);
  productCreateRequest.append(
    'model',
    JSON.stringify({ ...data, skillRequired: mapArrayFlagsToEnum(skillsRequired), value: value === -1 ? null : value })
  );

  return productCreateRequest;
};

export const mapProduct360ImagesToUploadRequest = (files: UploadFile360[]): ProductImagesUploadRequest => {
  var imagesData = new FormData();

    if (files != null && files.length > 0) {
        files.forEach((file, index) => {
            if (file.fileData) {
                imagesData.append(`file_${index}`, file.fileData, file.name);
            }
            imagesData.append(`order_${index}`, index.toString());
        });
    }

  return imagesData;
};

export const mapProductImagesToUploadRequest = (images: Blob[]): ProductImagesUploadRequest => {
    var imagesData = new FormData();

    if (images != null && images.length > 0)
        Array.from(images).forEach((image) => imagesData.append('productImageFiles', image as File));

    return imagesData;
};

interface ProductWarehouseFormDataWithImages extends ProductWarehouseFormData {
  productImages?: IMediaModel[];
}

export const mapProductWarehouseDataToRequest = (
  data: ProductWarehouseFormDataWithImages,
  masterProductId: ApiId
): WarehouseProductDataRequest => {
  // remove product from data and rename as productProperties for the API
  // remove any fields that are type string | number (e.g. price) then set any empty string to undefined below

  /** TODO: THIS MUST BE FIXED SO IT ISNT USING ANY.
   * IT IS RAISING A WHOLE BUNCH OF DODGY STUFF WITH MISMATCHED TYPES,
   * AND MISMATCHED MODELS BETWEEN FE AND BE
   * e.g. unitOfMeasure, supplierId is part of the data, but it isn't on the interface */
  const { productImageFiles, productImages, skillsRequired, value, ...restOfData } = data as any;

  let transformedData: WarehouseProductDataRequest = {
    ...restOfData,
    productProperties: (restOfData.productProperties || []).map((item: ProductPropertyDefinition) => {
      const { propertyValueId, propertyDefinitionId, value, measurement, prefixMeasurement, name } = item;

      return {
        propertyDefinitionId,
        value,
        propertyValueId,
        measurement,
        prefixMeasurement,
        name
      };
    }),
    shelfType: parseInt(restOfData.shelfType),
    volumetricIndicator: parseInt(restOfData.volumetricIndicator),
    // TODO: THIS NEEDS TO BE FIXED, SO THE MASTERPRODUCTID IS OPTIONAL i.e. CREATE AN INTERFACE THAT HAS IT AS OPTIONAL
    masterProductId
  };

  if (skillsRequired) {
    transformedData = {
      ...transformedData,
      // TODO: THIS NEEDS TO BE AN ENUM. DROPDOWNS ALLOW NUMBERS
      skillRequired: mapArrayFlagsToEnum(skillsRequired)
    };
  }

  if (value) {
    transformedData = {
      ...transformedData,
      value: value
    };
  }

  return transformedData;
};
