import { MasterProductSearch } from 'features/manageProducts/manageProducts.slice';
import { ISearchPagination } from 'millbrook-core';

export const NUMBER_OF_MASTER_PRODUCTS_PER_PAGE = 5;

export const BASE_MASTER_PRODUCTS_SEARCH: MasterProductSearch = {
  fetchAll: true,
  sortOrder: 'asc',
  sortColumn: 'name'
};

export const BASE_WORKSHOP_SEARCH: ISearchPagination = {
  pageSize: 10,
  pageIndex: 1,
  sortOrder: 'desc',
  sortColumn: 'dateCreated'
};
