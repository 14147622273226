import { ApiId, DEFAULT_GUID, ContractPPMType } from 'millbrook-core';
import { ContractReasonCode } from './pricing/pricing.types';
import { ContractPropertyOwnershipType } from './serviceUser/contractPropertyOwnershipTypes.slice';
import { ContractServiceUserAlert } from './serviceUser/serviceUserAlerts.slice';

export const mapContractReasonCodesFromServer = (
  list: ContractReasonCode[],
  contractId: ApiId
): ContractReasonCode[] => {
  return list.map((item) => ({
    ...item,
    contractId
  }));
};

export const mapContractServiceUserAlertsFromServer = (
  list: ContractServiceUserAlert[],
  contractId: ApiId
): ContractServiceUserAlert[] => {
  return list.map((item) => ({
    ...item,
    contractId,
    name: item.name || item.masterName || '',
    masterName: item.masterName || '',
    modified: (item.onContract || item.id !== DEFAULT_GUID) && item.name !== ''
  }));
};

export const mapContractPPMTypesFromServer = (list: ContractPPMType[], contractId: ApiId): ContractPPMType[] => {
  return list.map((item) => ({
    ...item,
    contractId,
    name: item.name || item.masterName || '',
    description: item.description || item.masterDescription || '',
    masterName: item.masterName || '',
    masterDescription: item.masterDescription || '',
    modified:
      (item.onContract || item.id !== DEFAULT_GUID) &&
      (item.frequencyDays !== item.masterFrequencyDays ||
        item.duration !== item.masterDuration ||
        item.dueAfterDays !== item.MasterDueAfterDays)
  }));
};


export const mapContractPropertyOwnershipTypesFromServer = (
    list: ContractPropertyOwnershipType[],
    contractId: ApiId
): ContractPropertyOwnershipType[] => {
    return list.map((item) => ({
        ...item,
        contractId,
        name: item.name || item.masterName || '',
        masterName: item.masterName || '',
        modified: (item.onContract || item.id !== DEFAULT_GUID) && item.name !== ''
    }));
};
