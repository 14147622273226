import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MasterProductDataRequest } from 'features/manageProducts/manageProducts.slice';
import { isEqual } from 'lodash';
import {
  ApiId,
  IApiResponse,
  IMasterProduct,
  IServerSelectOption,
  mapApiErrors,
  selectUserServiceCentres
} from 'millbrook-core';
import qs from 'query-string';
import { AppThunk, RootState } from 'store/store';
import { BASE_WORKSHOP_SEARCH, ENDPOINTS } from '../../constants';
import { ProductWarehouseFormData } from '../../pages/ManageProductsPage/components/ProductForm/ProductForm.validation';
import { getItem, getItems, postItems, putItem } from '../../services/api.service';
import { mapProductWarehouseDataToRequest } from '../manageProducts/mappers';
import { Printer } from './print.slice';
import { PukToRspAvailableonContracts } from './pukToRsp.slice';
import { ServicingRecord } from './servicingRecord.slice';
import { ServicingType } from './servicingType.slice';
import { StockHistory } from './stockHistory.slice';
import { UserSetting } from './userSetting.slice';
import {
  WarehouseStock,
  WorkshopCreateModel,
  WorkshopJobSearch,
  WorkshopModel,
  WorkshopStatusEnum,
  WorkshopSummaryModel,
    WorkshopUpdateModel,
    RSPValidityCheckToolModel
} from './warehouse.types';

export enum BarcodeType {
  Invalid = 0,
  Certificate = 1,
  OldPUK = 2,
  NewPUK = 3
}

interface WarehouseState {
  warehouseStock?: WarehouseStock;
  servicingTypes: ServicingType[];
  servicingRecords: ServicingRecord[];
  userSetting?: UserSetting;
  printers: Printer[];
  stockHistory: StockHistory[];
  allServicingTypes: IServerSelectOption[];
  pukToRspAvailableonContracts: PukToRspAvailableonContracts[];
  workshopJobSearchData: WorkshopJobSearch;
    rspValidityResponse: RSPValidityCheckToolModel[];
}

const initialState: WarehouseState = {
  servicingTypes: [],
  servicingRecords: [],
  printers: [],
  stockHistory: [],
  allServicingTypes: [],
  pukToRspAvailableonContracts: [],
  workshopJobSearchData: {
    statuses: [WorkshopStatusEnum.Open, WorkshopStatusEnum.OnHoldAwaitingParts, WorkshopStatusEnum.OnHoldAwaitingTrust]
    },
    rspValidityResponse: []
};

const warehouseSlice = createSlice({
  name: 'warehouse',
  initialState,
  reducers: {
    setWarehouseStock(state, action: PayloadAction<WarehouseStock | undefined>) {
      state.warehouseStock = action.payload;
    },
    setServicingTypes(state, action: PayloadAction<ServicingType[]>) {
      state.servicingTypes = action.payload;
    },
    setServicingRecords(state, action: PayloadAction<ServicingRecord[]>) {
      state.servicingRecords = action.payload;
    },
    setUserSetting(state, action: PayloadAction<UserSetting | undefined>) {
      state.userSetting = action.payload;
    },
    setPrinters(state, action: PayloadAction<Printer[]>) {
      state.printers = action.payload;
    },
    setStockHistory(state, action: PayloadAction<StockHistory[]>) {
      state.stockHistory = action.payload;
    },
    setAllServicingTypes(state, action: PayloadAction<IServerSelectOption[]>) {
      state.allServicingTypes = action.payload;
    },
    setPukToRspAvailableonContracts(state, action: PayloadAction<PukToRspAvailableonContracts[]>) {
      state.pukToRspAvailableonContracts = action.payload;
    },
    setWorkshopSearchData(state, action: PayloadAction<WorkshopJobSearch>) {
      const dataIsSame = isEqual(state.workshopJobSearchData, action.payload);
      if (!dataIsSame) {
        state.workshopJobSearchData = action.payload;
      }
    },
    updateWorkshopSearchData(state, action: PayloadAction<WorkshopJobSearch>) {
      const newState = { ...state.workshopJobSearchData, ...action.payload };

      const dataIsSame = isEqual(state.workshopJobSearchData, newState);

      if (!dataIsSame) {
        state.workshopJobSearchData = newState;
      }
    },
    resetWorkshopSearchData(state) {
      state.workshopJobSearchData = initialState.workshopJobSearchData;
      },
      setRSPValidityResponse(state, action: PayloadAction<RSPValidityCheckToolModel[]>) {
          state.rspValidityResponse = action.payload;
      },
  }
});

export const {
  setWarehouseStock,
  setServicingTypes,
  setServicingRecords,
  setUserSetting,
  setPrinters,
  setStockHistory,
  setAllServicingTypes,
  setPukToRspAvailableonContracts,
    setWorkshopSearchData,
    setRSPValidityResponse,
  updateWorkshopSearchData,
  resetWorkshopSearchData
} = warehouseSlice.actions;

export type WarehouseStockResponse = IApiResponse<WarehouseStock>;

export type RSPValidityResponse = IApiResponse<RSPValidityCheckToolModel[]>;

export interface WarehouseProductDataRequest extends MasterProductDataRequest {
  puk: string;
  serialNumber: string;
  batchNumber: string;
  servicingTypes: ApiId[];
}

/**
 * Thunks
 */
export const fetchWarehouseStock =
  (puk: string): AppThunk =>
  async (dispatch) => {
    return getItem<string, WarehouseStockResponse>(ENDPOINTS.WAREHOUSE.STOCK.STOCK(puk)).then(
      (response) => {
        dispatch(setWarehouseStock(response.result || undefined));
        return response;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
        };

export const fetchRSPValidity =
    (puk: string): AppThunk =>
        async (dispatch) => {
            return getItems<RSPValidityResponse>(ENDPOINTS.WAREHOUSE.STOCK.RSP_VALIDITY(puk)).then(
                (response) => {
                    dispatch(setRSPValidityResponse(response.result || []));
                    return response;
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchWarehouseStockByCert =
  (fieldstickerid: string): AppThunk =>
  async (dispatch) => {
    return getItem<string, WarehouseStockResponse>(ENDPOINTS.WAREHOUSE.STOCK.STOCK_GETBYCERT(fieldstickerid)).then(
      (response) => {
        dispatch(setWarehouseStock(response.result || undefined));
        return response;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateWarehouseProduct =
  (data: ProductWarehouseFormData, id: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<WarehouseProductDataRequest, object>(
      ENDPOINTS.WAREHOUSE.WAREHOUSE_PRODUCT.WAREHOUSE_PRODUCT,
      mapProductWarehouseDataToRequest(data, id),
      id,
      {
        enableGlobalErrorDialog: true
      }
    ).then(
      (response) => {
        dispatch(fetchWarehouseStock(data.puk));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/**
 * Workshop
 */

export const fetchWorkshopJobDetails =
  (jobId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItem<ApiId, IApiResponse<WorkshopModel>>(ENDPOINTS.WAREHOUSE.WORKSHOP.JOB_DETAILS(jobId)).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

const generateWorkshopJobParamsUrl = (searchFilters: WorkshopJobSearch, baseUrl: string) => {
  return qs.stringifyUrl(
    {
      url: baseUrl,
      query: {
        ...BASE_WORKSHOP_SEARCH,
        ...searchFilters
      }
    },
    { skipEmptyString: true }
  );
};

export const fetchWorkshopJobList =
  (searchFilters: WorkshopJobSearch): AppThunk =>
  async (dispatch, getState) => {
    const URL = generateWorkshopJobParamsUrl(searchFilters, ENDPOINTS.WAREHOUSE.WORKSHOP.LIST_JOBS);

    return getItems<IApiResponse<WorkshopSummaryModel[]>>(URL).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const err = mapApiErrors(response);
        throw new Error(err);
      }
    );
  };

export const fetchWorkshopJobFilters =
  (searchFilters: WorkshopJobSearch = {}): AppThunk =>
  async (dispatch, getState) => {
    const URL = generateWorkshopJobParamsUrl(searchFilters, ENDPOINTS.WAREHOUSE.WORKSHOP.LIST_JOBS_FILTERS);

    return getItems<IApiResponse<WorkshopSummaryModel[]>>(URL, {
      cacheName: 'workshop-filters',
      disableFullPageLoader: true
    }).then((response) => {
      return response.result;
    });
  };

export const fetchPukForJob =
  (code: string, locationId?: ApiId): AppThunk =>
  async (dispatch, getState) => {
    const barcodeType = validateBarcodeType(code);

    const url =
      barcodeType === BarcodeType.Certificate
        ? ENDPOINTS.WAREHOUSE.STOCK.STOCK_GETBYCERT(code, locationId)
        : ENDPOINTS.WAREHOUSE.STOCK.STOCK(code, locationId);

    // do the puk lookup here. This will need to include whether it is at the correct location or not
    return getItem<string, WarehouseStockResponse>(url).then(
      (response) => {
        if (!response.result) {
          throw new Error('Stock not found at this service centre, please check the PUK');
        } else {
          return response.result;
        }
      },
      (response) => {
        const err = mapApiErrors(response);
        throw new Error(err);
      }
    );
  };

export const fetchPukSuggestedSpareParts =
  (productId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItem<ApiId, IApiResponse<IMasterProduct[]>>(ENDPOINTS.PRODUCTS.PRODUCT_SPARES(productId)).then(
      (response) => {
        return response.result || [];
      },
      (response) => {
        const err = mapApiErrors(response);
        throw new Error(err);
      }
    );
  };

export const createWorkshopJob =
  (data: WorkshopCreateModel): AppThunk =>
  async (dispatch, getState) => {
    // create job api
    return postItems<WorkshopCreateModel, IApiResponse<WorkshopModel>>(
      ENDPOINTS.WAREHOUSE.WORKSHOP.JOB_CREATE_UPDATE,
      data
    ).then(
      (response) => {
        return response.result?.id;
      },
      (response) => {
        const err = mapApiErrors(response);
        throw new Error(err);
      }
    );
  };

export const updateWorkshopJob =
  (updateData: WorkshopUpdateModel): AppThunk =>
  async (dispatch, getState) => {
    return putItem<WorkshopUpdateModel, IApiResponse<WorkshopModel>>(
      ENDPOINTS.WAREHOUSE.WORKSHOP.JOB_CREATE_UPDATE,
      updateData,
      updateData.id
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const err = mapApiErrors(response);
        throw new Error(err);
      }
    );
  };

export const fetchWorkshopQuotePdf =
  (jobId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItem<ApiId, any>(ENDPOINTS.WAREHOUSE.WORKSHOP.GENERATE_QUOTE_PDF(jobId)).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const err = mapApiErrors(response);
        throw new Error(err);
      }
    );
  };

/**
 * Selectors
 */

export const selectWarehouseStock = (state: RootState) => state.warehouse && state.warehouse.warehouseStock;
export const selectServicingTypes = (state: RootState) => state.warehouse && state.warehouse.servicingTypes;
export const selectServicingRecords = (state: RootState) => state.warehouse && state.warehouse.servicingRecords;
export const selectUserSetting = (state: RootState) => state.warehouse && state.warehouse.userSetting;
export const selectPrinters = (state: RootState) => state.warehouse && state.warehouse.printers;
export const selectStockHistory = (state: RootState) => state.warehouse && state.warehouse.stockHistory;
export const selectAllServicingTypes = (state: RootState) => state.warehouse && state.warehouse.allServicingTypes;
export const selectPukToRspAvailableonContracts = (state: RootState) =>
    state.warehouse && state.warehouse.pukToRspAvailableonContracts;
export const selectRSPValidityResponse = (state: RootState) => state.warehouse && state.warehouse.rspValidityResponse;

const searchData = (state: RootState) => state.warehouse.workshopJobSearchData;

export const selectWorkshopJobSearchData = createSelector(
  [searchData, selectUserServiceCentres],
  (searchData, userServiceCentres) => {
    return {
      ...searchData,
      serviceCentreId: searchData.serviceCentreId || userServiceCentres.lastServiceCentre?.id
    };
  }
);

export default warehouseSlice.reducer;

export const validateBarcodeType = (barcode: string) => {
  if (Number.isNaN(Number(barcode)) === false && barcode.length >= 10) {
    return BarcodeType.Certificate;
  } else if (Number.isNaN(Number(barcode)) === false && barcode.length >= 6 && barcode.length <= 9) {
    return BarcodeType.OldPUK;
  } else if (
    Number.isNaN(Number(barcode.substring(0, 2))) === true &&
    Number.isInteger(Number(barcode.substring(2, barcode.length - 2)))
  ) {
    return BarcodeType.NewPUK;
  } else {
    return BarcodeType.NewPUK;
  }
};
