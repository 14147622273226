import { ENDPOINTS } from 'constants/api';
import { mapMasterServiceToFormData } from 'helpers/mappers';
import {
  ApiId,
  ErrorResponse,
  IApiResponse,
  mapApiErrors,
  MasterSpeed,
  ServiceSpeedTypeEnum,
  ServiceTypeCodeEnum,
  SkillRequiredEnum
} from 'millbrook-core';
import { getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk } from 'store/store';
import { setMasterServices, setMasterSpeeds } from '../masterLists.slice';
import { MasterSpeedFormData } from './components/MasterSpeedsForm/MasterSpeedsForm';

export interface MasterService {
  id: ApiId;
  name: string;
  description: string;
  isOrderable: boolean;
  serviceTypeCode: ServiceTypeCodeEnum;
  serviceSpeedType: ServiceSpeedTypeEnum;
  skillRequired?: SkillRequiredEnum | null;
  requiresAdditionalInformation: boolean;
}

export interface MasterServiceRequest
  extends Omit<MasterService, 'id' | 'isOrderable' | 'serviceTypeCode' | 'serviceSpeedType'> {
  id?: ApiId;
}

export type MasterServiceResponse = IApiResponse<MasterService>;
export type MasterServiceListResponse = IApiResponse<MasterService[]>;

export interface MasterSpeedRequest extends Omit<MasterSpeed, 'daysHours' | 'id' | 'serviceSpeedTypeCode'> {
  daysHours: number;
  id?: ApiId;
}

export interface MasterSpeedServerResponse extends Omit<MasterSpeed, 'daysHours'> {
  daysHours: number;
}

export type MasterSpeedResponse = IApiResponse<MasterSpeedServerResponse>;
export type MasterSpeedListResponse = IApiResponse<MasterSpeedServerResponse[]>;

// MASTER SERVICE TYPES
export const fetchMasterServices = (): AppThunk => async (dispatch) => {
  return getItems<MasterServiceListResponse>(ENDPOINTS.SERVICES_LIST).then((response) => {
    dispatch(setMasterServices(response.result || []));
  });
};

export const fetchMasterService =
  (id: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, MasterServiceResponse>(ENDPOINTS.SERVICES_LIST, id).then((response) => {
      return response.result || null;
    });
  };

export const createMasterService =
  (data: MasterServiceRequest): AppThunk =>
  async (dispatch) => {
    const formData = mapMasterServiceToFormData(data);

    return postItems<FormData, MasterService>(ENDPOINTS.SERVICES_LIST, formData).then(
      () => {
        dispatch<any>(fetchMasterServices());
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateMasterService =
  (data: MasterService): AppThunk =>
  async (dispatch) => {
    const formData = mapMasterServiceToFormData(data);

    return putItem<FormData, MasterService>(ENDPOINTS.SERVICES_LIST, formData, data.id).then(
      () => {
        dispatch<any>(fetchMasterServices());
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// MASTER SERVICE SPEEDS
export const fetchMasterSpeeds = (): AppThunk => async (dispatch) => {
  return getItems<MasterSpeedListResponse>(ENDPOINTS.MASTER_LISTS.SPEEDS).then((response) => {
    dispatch(setMasterSpeeds(response.result || []));
  });
};

export const fetchMasterSpeed =
  (id: ApiId): AppThunk =>
  async () => {
    return getItem<ApiId, MasterSpeedResponse>(ENDPOINTS.MASTER_LISTS.SPEEDS, id).then((response) => {
      return response.result || {};
    });
  };

export const createMasterSpeed =
  (data: MasterSpeedFormData): AppThunk =>
  async (dispatch) => {
    return postItems<MasterSpeedRequest, MasterService>(ENDPOINTS.MASTER_LISTS.SPEEDS, data).then(
      () => {
        dispatch<any>(fetchMasterSpeeds());
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateMasterSpeed =
  (data: MasterSpeed): AppThunk =>
  async (dispatch) => {
    return putItem<MasterSpeedRequest, MasterSpeed>(ENDPOINTS.MASTER_LISTS.SPEEDS, data, data.id).then(
      () => {
        dispatch<any>(fetchMasterSpeeds());
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };
