import { ContractDocumentCreateRequest } from 'features/manageProducts/manageProducts.slice';
import { HelpDocumentUploadFormData } from 'pages/Contract/PrescriberPortal/HelpDocuments/ContractHelpDocuments.validation';

export const mapDocumentsDataToCreateRequest = (data: HelpDocumentUploadFormData): ContractDocumentCreateRequest => {
  const { files, categoryId } = data;
  var contractDocumentCreateRequest = new FormData();
  for (let i = 0; i < files.length; i++) {
    contractDocumentCreateRequest.append('formFileCollection', files[i]);
  }

  contractDocumentCreateRequest.append('categoryId', categoryId);

  return contractDocumentCreateRequest;
};
