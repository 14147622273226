import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, createHashFromArray, ErrorResponse, IDeleteRequest, mapApiErrors, PinGroup } from 'millbrook-core';
import { SearchStockProductData } from 'pages/Contract/BudgetPin/Stock/ContractStockSearch.validation';
import { deleteItems, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { ContractProductFilters, fetchContractProducts } from '../products/contractProducts.slice';
import { ContractProductSelectable } from '../products/products.types';
import { PinGroupStockItem, PinGroupStockItemResponse, PinGroupStockItemsPost } from './budgetPin.types';
import { fetchGroups, selectGroupList } from './groups.slice';

interface StockState {
  groupStockList: PinGroupStockItem[];
  productList: ContractProductSelectable[];
  currentGroupId?: ApiId;
  contractStockSearch?: SearchStockProductData;
}

const initialState: StockState = { groupStockList: [], productList: [] };

const stockSlice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    setProductList(state, action: PayloadAction<ContractProductSelectable[]>) {
      state.productList = action.payload;
    },
    setGroupStockList(state, action: PayloadAction<PinGroupStockItem[]>) {
      state.groupStockList = action.payload;
    },
    setCurrentGroupId(state, action: PayloadAction<string>) {
      state.currentGroupId = action.payload;
    },
    setGroupStockSearch(state, action: PayloadAction<SearchStockProductData>) {
      state.contractStockSearch = action.payload;
    },
    clearGroupStockSearch(state) {
      state.contractStockSearch = undefined;
      state.productList = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

// thunks for contract stockitems

export const initContractStockManage =
  (contractId: ApiId, pinGroupId?: ApiId): AppThunk =>
  async (dispatch) => {
    // grabs the groups to populate the dropdown and this will contain the individual group for the costs.
    return Promise.all([dispatch<any>(fetchGroups(contractId))]).then(
      (responses) => {
        pinGroupId && dispatch(setCurrentGroupId(pinGroupId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// feeds through contract products fetch, but I want it in the store here for refresh purposes
export const fetchContractStockProducts =
  (filters: ContractProductFilters): AppThunk =>
  async (dispatch) => {
    filters = { ...filters, onlyProductsOnContract: true, fetchAll: true };
    dispatch<any>(fetchContractProducts(filters))
      .then((products: ContractProductSelectable[]) => {
        dispatch(setProductList(products));
      })
      .catch((response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const fetchGroupStock =
  (pinGroupId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PinGroupStockItemResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUP_STOCK(pinGroupId), {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(setCurrentGroupId(pinGroupId));
        dispatch(setGroupStockList(response.result || []));
      },
      (response) => {
        // Handled in global error
        //   const error = mapApiErrors(response);
        //   throw new Error(error);
      }
    );
        };

export const removeStockToGroup =
    (pinGroup: ApiId | PinGroup[], products: ContractProductSelectable[]): AppThunk =>
        async (dispatch) => {
            const contractProductIds = products?.map((product) => product.contractProductId);
            const pinGroupIds = typeof pinGroup === 'string' ? [pinGroup] : pinGroup?.map((group) => group.id);

            return dispatch<any>(removeStockToGroups(pinGroupIds, contractProductIds)).then(() => {
                if (typeof pinGroup === 'string') {
                    return dispatch<any>(fetchGroupStock(pinGroup));
                }
            });
        };


export const removeStockToGroups =
    (pinGroupIds: ApiId[], contractProductIds: ApiId[]): AppThunk =>
        async (dispatch) => {
            return deleteItems<PinGroupStockItemsPost, any>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUP_STOCK_REMOVE(), {
                pinGroupIds,
                contractProductIds
            }).then((responses) => {
                // TODO: get feedback in the response from the API
            });
        };

export const addStockToGroup =
  (pinGroup: ApiId | PinGroup[], products: ContractProductSelectable[]): AppThunk =>
  async (dispatch) => {
    const contractProductIds = products?.map((product) => product.contractProductId);
    const pinGroupIds = typeof pinGroup === 'string' ? [pinGroup] : pinGroup?.map((group) => group.id);

    return dispatch<any>(addStockToGroups(pinGroupIds, contractProductIds)).then(() => {
      if (typeof pinGroup === 'string') {
        return dispatch<any>(fetchGroupStock(pinGroup));
      }
    });
  };

export const addStockToGroups =
  (pinGroupIds: ApiId[], contractProductIds: ApiId[]): AppThunk =>
  async (dispatch) => {
    return postItems<PinGroupStockItemsPost, any>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUP_STOCK(), {
      pinGroupIds,
      contractProductIds
    }).then((responses) => {
      // TODO: get feedback in the response from the API
    });
  };

export const deleteStockItems =
  (pinGroupId: ApiId, data: PinGroupStockItem[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.contractProductId);

    return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUP_STOCK(pinGroupId), ids, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchGroupStock(pinGroupId));
      },
      (response) => {
        // error handled in Global
        //const error = mapApiErrors(response);
        //throw new Error(error);
      }
    );
  };

/* actions */
export const { setGroupStockList, setCurrentGroupId, setProductList, setGroupStockSearch, clearGroupStockSearch } =
  stockSlice.actions;

/* selectors */
export const selectGroupStockList = (state: RootState) => state.contract.budgetPin.stock.groupStockList;
export const selectCurrentGroupId = (state: RootState) => state.contract.budgetPin.stock.currentGroupId;
export const selectGroupProductList = (state: RootState) => state.contract.budgetPin.stock.productList;
export const selectContractStockSearch = (state: RootState) => state.contract.budgetPin.stock.contractStockSearch;

export const getCurrentGroup = createSelector([selectGroupList, selectCurrentGroupId], (list, selected) => {
  return list && selected ? list.find((item) => item.id.toString() === selected.toString()) : null;
});

const getGroupHash = createSelector([selectGroupStockList], (array) =>
  createHashFromArray<PinGroupStockItem>(array, 'contractProductId')
);

export const getProductsForGroup = createSelector([selectGroupProductList, getGroupHash], (allProducts, groupHash) => {
  // BE: this might be better done on the BE?
  return allProducts.map((p) => ({ ...p, selected: !!groupHash[p.contractProductId] }));
});

/* reducers */
export default stockSlice.reducer;
