import { combineReducers } from '@reduxjs/toolkit';

import prescriberLogo from './prescriberLogo.slice';
import helpAndSupport from './helpAndSupport.slice';
import contractProfessionalAssessment from './contractProfessionalAssessment.slice';

export const prescriberPortal = combineReducers({
  prescriberLogo,
  helpAndSupport,
  contractProfessionalAssessment
});
