import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { CONTRACT_CACHE_NAME } from 'features/contracts/contracts.slice';
import {
    ApiId,
    IApiResponse,
    IDeleteRequest,
    IDocumentModel,
    IServiceCentreSummary,
    mapApiErrors
} from 'millbrook-core';
import { DocumentUploadFormData } from 'pages/Contract/Overview/ContractDocuments/ContractDocuments.validation';
import { deleteItems, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { ChargingMechanism, CommissioningAuthority, ContractDraft, ContractStateEnum } from '../contract.types';
import { ContractRspSharingConfig } from '../recycledSpecialProducts/pooledRsps.types';

/* types */
export interface ContractOverview extends Omit<ContractDraft, 'chargingMechanism'> {
    contractState: ContractStateEnum;
    chargingMechanism: ChargingMechanism;
    hospitalBedCostPerDay: number | null;
    serviceCentres: IServiceCentreSummary[];
    contractRspSharingConfig: ContractRspSharingConfig;
    hireTrialEnabled: boolean;
    hireLength?: number;
    trialLength?: number;
    displayStockLevels?: boolean;
    displayCheaperAlternatives?: boolean;
}

export type ContractOverviewResponse = IApiResponse<ContractOverview>;

export type ContractDocumentCreateRequest = FormData;

export type ContractDocumentUpdateRequest = IDocumentModel;

/* state */
interface OverviewState {
    id?: ApiId;
    moniker?: string;
    name?: string;
    serviceCentres?: IServiceCentreSummary[];
    commissioningAuthorities?: CommissioningAuthority[];
    contractType?: string;
    contractState?: ContractStateEnum;
    contractSubType?: string;
    chargingMechanism?: ChargingMechanism;
    hospitalBedCostPerDay?: string; // it's really a number but yup is giving me hell
    documents?: IDocumentModel[];
    contractRspSharingConfig?: ContractRspSharingConfig;
    hireTrialEnabled?: boolean;
    hireLength?: number;
    trialLength?: number;
    displayStockLevels?: boolean;
    displayCheaperAlternatives?: boolean;
    soundexSearchServiceUserSurnameEnabled?: boolean;
    soundexSearchServiceUserSurnameDefault?: boolean;
}

const initialState: OverviewState = {};

const DOCS_CACHE = 'contractdocuments';

/* slice */
const overviewSlice = createSlice({
    name: 'overview',
    initialState,
    reducers: {
        setContractOverview(state, action: PayloadAction<ContractOverview | undefined>) {
            if (action.payload) {
                const { hospitalBedCostPerDay, ...rest } = action.payload;

                return {
                    ...rest,
                    hospitalBedCostPerDay: (hospitalBedCostPerDay ?? '').toString()
                };
            }
        },
        setContractDocuments(state, action: PayloadAction<IDocumentModel[]>) {
            state.documents = action.payload;
        },
        setContractStatus(state, action: PayloadAction<ContractStateEnum>) {
            if (action.payload) {
                state.contractState = action.payload;
            }
        },
        setHospitalBedCost(state, action: PayloadAction<string>) {
            state.hospitalBedCostPerDay = action.payload;
        },
        setRspSharingConfig(state, action: PayloadAction<ContractRspSharingConfig>) {
            state.contractRspSharingConfig = action.payload;
        },
        // this is used as the extraReducer for clearing the other slices in the contract
        clearContractState() {
            return initialState;
        }
    }
});

/* actions */
export const {
    setContractOverview,
    setContractDocuments,
    setContractStatus,
    clearContractState,
    setHospitalBedCost,
    setRspSharingConfig
} = overviewSlice.actions;

/* thunks */
export const fetchContractOverview =
    (id: string): AppThunk =>
        async (dispatch) => {
            return getItem<string, ContractOverviewResponse>(ENDPOINTS.CONTRACT.OVERVIEW, id, {
                enableGlobalErrorDialog: true,
                cacheName: CONTRACT_CACHE_NAME
            }).then((response) => {
                dispatch(setContractOverview(response.result));
            });
        };

/********** Documents ************/

export const fetchContractDocuments =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return getItems<IApiResponse<IDocumentModel[]>>(ENDPOINTS.CONTRACT.DOCUMENTS(contractId), {
                disableFullPageLoader: true,
                cacheName: DOCS_CACHE
            }).then((response) => {
                dispatch(setContractDocuments(response.result || []));
            });
        };

export const createContractDocument =
    (contractId: ApiId, data: DocumentUploadFormData): AppThunk =>
        async (dispatch) => {
            const { files } = data;
            var formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }

            return postItems<ContractDocumentCreateRequest, number>(ENDPOINTS.CONTRACT.DOCUMENTS(contractId), formData, {
                cacheName: DOCS_CACHE
            }).then(
                () => {
                    dispatch<any>(fetchContractDocuments(contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };
export const updateContractDocument =
    (contractId: ApiId, data: IDocumentModel): AppThunk =>
        async (dispatch) => {
            return putItem<ContractDocumentUpdateRequest, null>(ENDPOINTS.CONTRACT.DOCUMENTS(), data, data.id, {
                cacheName: DOCS_CACHE
            }).then(
                () => {
                    dispatch<any>(fetchContractDocuments(contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const deleteContractDocuments =
    (contractId: ApiId, data: IDocumentModel[]): AppThunk =>
        async (dispatch) => {
            const ids = data.map((d) => d.id);

            return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.DOCUMENTS(), ids, {
                enableGlobalErrorDialog: true,
                cacheName: DOCS_CACHE
            }).then(
                () => {
                    dispatch<any>(fetchContractDocuments(contractId));
                },
                () => {
                    // handled with global error handler
                }
            );
        };

export const updateBenefitsRealisation =
    (cost: number | null, contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return putItem<{ cost: number | null }, IApiResponse<number>>(
                ENDPOINTS.CONTRACT.PRESCRIBER_PORTAL.BENEFITS_REALISATION(contractId),
                { cost },
                '',
                { cacheName: CONTRACT_CACHE_NAME }
            ).then(
                (response) => {
                    dispatch(setHospitalBedCost((response.result ?? '').toString()));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

/* selectors */
export const selectContractOverview = (state: RootState) => state.contract.overview;
export const selectContractStatus = (state: RootState) => state.contract.overview.contractState;
export const selectContractDocuments = (state: RootState) => state.contract.overview.documents;

export const selectContractConfig = createSelector([selectContractOverview], (overview) => {
    const { displayStockLevels = false, displayCheaperAlternatives = false } = overview;

    return { displayStockLevels, displayCheaperAlternatives };
});

/* reducers */
export const overview = overviewSlice.reducer;
