import { combineReducers } from '@reduxjs/toolkit';
import organisations from './organisations.slice';
import areas from './areas.slice';
import teams from './teams.slice';
import groups from './groups.slice';
import users from './users.slice';
import stock from './stock.slice';

export const budgetPin = combineReducers({
  organisations,
  areas,
  teams,
  groups,
  users,
  stock
});
