import { ColorType } from 'millbrook-core';

export enum ContractNavStates {
    DISABLED = 'disabled',
    READY = 'ready',
    COMPLETE = 'complete'
}

// REFACTOR: This would possibly be 2 or 3 interfaces. A FE interface for text, icons etc, a BE for the model coming from the server and a merge of the 2.
export interface ContractSection {
    name: string;
    state?: ContractNavStates;
    step: ContractSectionEnum | string;
    href: string;
    next?: string;
    iconColor?: ColorType;
    hasChildren?: boolean;
    subSections?: ContractSection[];
}

// This would be used in the backend if/when there is an api put in place to keep state like "completed"
export enum ContractSectionEnum {
    /***************************
     * ----- Key Dates------
     ***************************/
    //        [Description("Contract award date")]
    ContractAwardDate = 100,

    // [Description("Contract operational dates")]
    ContractOperationalDates = 110,

    // [Description("Extension options")]
    ExtensionOptions = 120,

    // [Description("Price review dates")]
    PriceReviewDates = 130,

    // [Description("Operating days & time")]
    OperationDaysTime = 140,

    // [Description("Non-working days")]
    NonWorkingDays = 150,

    // [Description("Millbrook contact details")]
    MillbrookContactDetails = 160,

    /***************************
     * ----- Scope------
     ***************************/
    // [Description("Contract Services")]
    ContractServices = 200,

    // [Description("Speeds")]
    Speeds = 210, // these are dynamically generated

    // [Description("Scrap Limit")]
    ScrapLimit = 220,

    // [Description("PPM Types")]
    PPMTypes = 230,

    // [Description("Review Types")]
    ReviewTypes = 240,

    // [Description("Product Configuration")]
    ProductConfiguration = 250,
    // [Description("Event Types")]
    // BE - ADDED AFTER
    EventTypes = 113,

    // [Description("Clean codes and charges")]
    CleanCodesCharges = 260,

    // [Description("Clean codes and charges")]
    HireTrialEquipment = 270,

    // [Description("Soundex Search")]
    SoundexSearch = 280,

    /***************************
     * ----- Pricing------
     ***************************/
    // [Description("Service changes")]
    ServiceChanges = 300,

    /***************************
     * ----- Products------
     ***************************/
    // [Description("Products")]
    Products = 400,

    /***************************
     * ----- Prescriber portal------
     ***************************/
    // [Description("Logo upload")]
    LogoUpload = 500,

    // [Description("Help and Support")]
    HelpSupport = 510,

    // [Description("Benefits realisation")]
    BenefitsRealisation = 520,

    // [Description("Professional assessment")]
    ProfessionalAssessment = 530,

    // [Description("Contract config")]
    ContractConfig = 540,

    /***************************
     * ----- Pin/Budget------
     ***************************/
    //
    // [Description("Organisations")]
    Organisations = 600,

    // [Description("Areas")]
    Areas = 610,

    // [Description("Teams")]
    Teams = 620,

    // [Description("Groups")]
    Groups = 630,

    // [Description("Users")]
    Users = 640,

    // [Description("Stock")]
    Stock = 650,

    /***************************
     * ----- Service user------
     ***************************/
    // [Description("Define service user groups")]
    DefineServiceUserGroups = 700,

    // [Description("Service user data fields")]
    ServiceUserDataFields = 710,

    // [Description("Client alerts")]
    ServiceUserAlerts = 720,

    // [Description("Inactive reasons")]
    InactiveReasons = 730,

    // [Description("Define GPs By CCG")]
    DefineGPsByCCG = 750,

    // [Description("Property ownership types")]
    PropertyOwnershipTypes = 760,

    /***************************
     * ----- Peripheral stores------
     ***************************/
    //
    // [Description("Manage peripheral store")]
    ManagePeripheralStore = 800,

    // [Description("Manage peripheral store stock")]
    ManagePeripheralStoreStock = 810,

    /***************************
     * ----- Recycled special products------
     ***************************/
    // [Description("Hold times")]
    HoldTimes = 44,

    // [Description("Pooled RSPs")]
    PooledRsps = 45,

    /***************************
     * ----- Prescription portal------
     ***************************/
    //
    // [Description("Logo upload")]
    Retailers = 900,

    // [Description("Help and Support")]
    RetailerUsers = 910,
}
