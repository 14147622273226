import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { groupBy } from 'lodash';
import {
    ApiId,
    ContractSpeed,
    IApiResponse,
    ICheckboxOption,
    IDeleteRequest,
    ISelectOption,
    mapApiErrors,
    PinGroup,
    showGenericErrorDialog
} from 'millbrook-core';
import { deleteItems, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';
import { fetchContractServices, getContractServicesEnabled } from '../scope/contractServices.slice';
import { PinGroupRequest, PinGroupResponse, PinGroupServiceSpeeds } from './budgetPin.types';

interface GroupsState {
    groupList: PinGroup[];
    groupProductList: PinGroup[];
    groupSpeedList: PinGroupServiceSpeeds[];
}

const initialState: GroupsState = { groupList: [], groupProductList: [], groupSpeedList: [] };

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroupList(state, action: PayloadAction<PinGroup[]>) {
            state.groupList = action.payload;
        },
        setGroupProductList(state, action: PayloadAction<PinGroup[]>) {
            state.groupProductList = action.payload;
        },
        setGroupSpeedList(state, action: PayloadAction<PinGroupServiceSpeeds[]>) {
            state.groupSpeedList = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(clearContractState, () => {
            return initialState;
        });
    }
});

// thunks for groups
export const initGroupFormData =
    (contractId: ApiId, pinGroupId?: ApiId): AppThunk =>
        async (dispatch) => {
            return Promise.all([
                // fetch the contract speeds
                dispatch<any>(fetchContractSpeedsGrouped(contractId)), // [0]
                // fetch the services. selector will grab the orderable ones
                dispatch<any>(fetchContractServices(contractId)), // [1]
                // fetch the pin group for the form
                pinGroupId && dispatch<any>(fetchGroup(pinGroupId)) // [2]
            ])
                .then((responses) => {
                    return responses[2];
                })
                .catch((error) => {
                    const errorMessage = mapApiErrors(error);
                    dispatch(showGenericErrorDialog(errorMessage));
                    throw new Error(error);
                });
        };

export const fetchGroups =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            return getItems<PinGroupResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS(contractId)).then(
                (response) => {
                    dispatch(setGroupList(response.result || []));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchGroupsProduct =
    (contractId: ApiId, productId: ApiId, isRemove: boolean): AppThunk =>
        async (dispatch) => {
            return getItems<PinGroupResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS_PRODUCT(contractId, productId, isRemove)).then(
                (response) => {
                    dispatch(setGroupProductList(response.result || []));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const fetchGroup =
    (id: ApiId): AppThunk =>
        async (dispatch) => {
            return getItem<ApiId, PinGroupResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS(), id).then((response) => {
                return response.result || {};
            });
        };

export const createGroup =
    (data: PinGroupRequest): AppThunk =>
        async (dispatch) => {
            return postItems<PinGroupRequest, PinGroupResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS(), data).then(
                (response) => {
                    dispatch<any>(fetchGroups(data.contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const updateGroup =
    (data: PinGroup): AppThunk =>
        async (dispatch) => {
            return putItem<PinGroupRequest, PinGroupResponse>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS(), data, data.id).then(
                (response) => {
                    dispatch<any>(fetchGroups(data.contractId));
                },
                (response) => {
                    const error = mapApiErrors(response);
                    throw new Error(error);
                }
            );
        };

export const deleteGroups =
    (data: PinGroup[]): AppThunk =>
        async (dispatch) => {
            const ids = data.map((d) => d.id);
            return deleteItems<IDeleteRequest, null>(ENDPOINTS.CONTRACT.BUDGET_PIN.GROUPS(), ids, {
                enableGlobalErrorDialog: true
            }).then(
                () => {
                    dispatch<any>(fetchGroups(data[0].contractId));
                },
                (response) => {
                    //const error = mapApiErrors(response);
                    //throw new Error(error);
                }
            );
        };

export const fetchContractSpeedsGrouped =
    (contractId: ApiId): AppThunk =>
        async (dispatch) => {
            try {
                const speedGroups = await getItems<IApiResponse<ContractSpeed[]>>(
                    ENDPOINTS.CONTRACT.SCOPE.SPEEDS_GET(contractId, '', 'false'),
                    { enableGlobalErrorDialog: true }
                ).then((response) => response.result || []);

                // groupby Service name
                const grouped = groupBy(speedGroups, 'contractServiceTypeName');

                // munge into simpler list of array of arrays
                const mapped = Object.keys(grouped).map((key) => {
                    const speeds: ContractSpeed[] = grouped[key];
                    return {
                        serviceName: key,
                        speeds: speeds.map((s) => ({ speedId: s.id, speedName: s.masterName }))
                    };
                });

                dispatch(setGroupSpeedList(mapped));
            } catch (e: any) {
                // this is being caught with the global dialog
            }
        };

/* actions */
export const { setGroupList, setGroupProductList, setGroupSpeedList } = groupsSlice.actions;

/* selectors */
export const selectGroupList = (state: RootState) => state.contract.budgetPin.groups.groupList;
export const selectGroupByProductList = (state: RootState) => state.contract.budgetPin.groups.groupProductList;
export const selectGroupSpeedsList = (state: RootState) => state.contract.budgetPin.groups.groupSpeedList;

export const getOrderableServicesForAuthorisation = createSelector(
    [getContractServicesEnabled],
    (services): ICheckboxOption[] => {
        return services
            .filter((s) => s.isOrderable)
            .map((item) => {
                return { label: item.name, value: item.id };
            });
    }
);

export const getGroupSelectOptions = createSelector([selectGroupList], (groupList): ISelectOption[] => {
    return groupList.map((item) => {
        return { label: item.name, value: item.id };
    });
});

/* reducers */
export default groupsSlice.reducer;
