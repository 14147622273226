import { SupDocType } from 'features/procurement/suppliers/components/SupplierForm/SupplierForm';
import { ApiId, GLOBAL_ENDPOINTS, IsoDate } from 'millbrook-core';

export const ENDPOINTS = {
    ...GLOBAL_ENDPOINTS,
    MASTER_LISTS: {
        SERVICE_USER_GROUPS: 'ServiceUserGroups',
        CLIENT_ALERTS: 'ClientAlerts',
        INACTIVE_REASONS: 'InactiveReasons',
        REVIEW_TYPES: 'ReviewTypes',
        REVIEW_PERIODS: 'ReviewPeriods',
        CARE_OUTCOMES: 'CareOutcomes',
        PROPERTY_ACCESS_TYPES: 'PropertyAccessTypes',
        PROPERTY_OWNERSHIP_TYPES: 'PropertyOwnershipTypes',
        SPEEDS: 'ServiceSpeed',
        CLEANING_CODES: 'CleaningCodes',
        EQUIPMENT_REVIEW_PERIODS: 'Equipment',
        PPM_TYPES: 'servicePPMTypes',
        EVENT_TYPES: 'EventType',
        CLIENT_DOCUMENTS_CATEGORIES_SORT: 'ClientDocuments/SortCategories'
    },
    HELP: {
        SECTIONS: 'HelpSections',
        SECTIONS_SORT: 'HelpSections/setOrders',
        SECTION_DETAIL: 'HelpSections/Details',
        SECTION_DETAIL_SORT: 'HelpSections/Details/setOrders',
        HELP_DOCUMENT_CATEGORIES_TREE: `HelpDocuments/helpDocumentCategoryTree`,
        HELP_DOCUMENT_CATEGORIES_TREE_SORT: `HelpDocuments/setHelpDocumentCategoryPriorities`
    },
    CONTRACTS: 'Contracts',
    INVOICING: {
        BY_CONTRACT: (contractId: ApiId) => `Invoice/contract/${contractId}`,
        MISC_CHARGE: (contractId: ApiId, miscChargeId: ApiId) =>
            `Invoice/contract/${contractId}/misccharges/${miscChargeId}`,
        MISC_CHARGES: (contractId: ApiId) => `Invoice/contract/${contractId}/misccharges`,
        INVOICE: `Invoice`,
        REGENERATE_INVOICE: `Invoice/regenerate`,
        FINALISE: (invoiceId: ApiId) => `Invoice/${invoiceId}/finalise`,
        STATUS: (invoiceId: ApiId) => `Invoice/${invoiceId}/status`
    },
    PRODUCTS: {
        CATEGORIES: `ProductCategories`,
        CATEGORIES_ASSIGN: `ProductCategories/AssignProduct`,
        CATEGORIES_UNASSIGN: `ProductCategories/UnAssignProduct`,
        PRODUCTS_INITIAL_DATA: `Products/initialData`,
        PRODUCTS: `Products`,
        PRODUCT: (productId: ApiId) => `Products/${productId}`,
        PRODUCT_SPEC: (productTypeId: ApiId) => `ProductTypes/${productTypeId}/properties`,
        PRODUCT_IMAGES: (productImageId: ApiId) => `Products/${productImageId}/images`,
        PRODUCT_360_IMAGES: (productImageId: ApiId) => `Products/${productImageId}/360images`,
        DOCUMENTS: (productId: string) => `ProductDocuments?productId=${productId}`,
        PRODUCT_SPARES: (productId: ApiId) => `Products/GetLinkedSpares/${productId}`,
        PUT_DOCUMENTS: `ProductDocuments`,
        DELETE_DOCUMENTS: `ProductDocuments`,
        SYNC_STATUS: (productId: ApiId, locationId: ApiId = '') =>
            `ProductSync/${productId}${locationId && '/' + locationId}`
    },

    /* CONTRACT ROUTES */
    CONTRACT: {
        OVERVIEW: `ContractOverview`,
        ACTIVATE: (contractId: ApiId) => `Contracts/${contractId}/Activate`,
        ARCHIVE: (contractId: ApiId) => `Contracts/${contractId}/Archive`,
        DOCUMENTS: (contractId: ApiId = '') => `ContractDocuments${contractId && '?contractId=' + contractId}`,
        DRAFT: {
            DATA: `ContractMandatoryData`
        },

        PRODUCTS: {
            PRODUCT: 'ContractProduct',
            PRODUCT_CATEGORIES: 'ContractProduct/categories',
            PRODUCT_DETAILS: (contractProductId: ApiId, contractId: ApiId) =>
                `ContractProduct/${contractProductId}?contractId=${contractId}`,
            PRODUCTS_FILTER: 'ContractProduct/search',
            PRODUCT_IMPORT: 'ContractProduct/import',
            PRODUCT_NOTES: (contractProductId: ApiId) => `ContractProductNotes?contractProductId=${contractProductId}`,
            PRODUCT_NOTE: `ContractProductNotes`
        },

        PRICING: {
            SERVICE_CHARGES: (contractId: ApiId) => `Contracts/${contractId}/ServiceCharges`,
            SERVICE_CHARGES_MULTIPLE_ACTIVITY_FEES: (contractId: ApiId) =>
                `Contracts/${contractId}/ServiceCharges/MultipleActivityFees`,
            REASON_CODE: 'ContractReasonCode',

            REASON_CODES: (contractId: ApiId) => `ContractReasonCode?contractId=${contractId}&includeMasterList=true`,

            CLEANING_CODES: `ContractCleaningCodes`
        },

        PRESCRIBER_PORTAL: {
            // PRESCRIBER_LOGO_GET found in GLOBAL_ENDPOINTS
            PRESCRIBER_LOGO: `PortalLogo`,
            HELP_DOCUMENTS: (contractId: ApiId = '') => `HelpDocuments${contractId && '?contractId=' + contractId}`,
            // get help document by name, this is a link rather than an api, that is why the config_api_url
            HELP_DOCUMENT: (name: string) => `HelpDocuments/GetDocument?storedName=${name}`,
            HELP_DOCUMENT_CATEGORIES: `HelpDocuments/helpCategories`,
            BENEFITS_REALISATION: (contractId: ApiId) => `Contracts/${contractId}/SaveHospitalBedCost`,
            CONTRACT_PROFESSIONAL_ASSESSMENT: `ContractProfessionalAssessment`,
            CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION: `ContractProfessionalAssessmentQuestion`,
            CONTRACT_PROFESSIONAL_ASSESSMENT_CHANGE_ACTIVE_STATUS: `ContractProfessionalAssessment/SetActiveContractProfessionalAssessment`,
            CONTRACT_PROFESSIONAL_ASSESSMENT_QUESTION_PRODUCT: `ContractProfessionalAssessmentQuestionProduct`,
            CONTRACT_PROFESSIONAL_ASSESSMENTS_BY_CONTRACT_ID: (contractId: ApiId) =>
                `ContractProfessionalAssessment/GetByContractId/${contractId}`,
            CONTRACT_PROFESSIONAL_ASSESSMENT_OVERVIEW: (contractId: ApiId) => `ContractProfessionalAssessment/${contractId}`,
            CONTRACT_PROFESSIONAL_ASSESSMENTS_BY_CONTRACT_ID_AND_PROFESSIONAL_ASSESSMENT_ID: (
                contractId: ApiId,
                professionalAssessmentId: ApiId
            ) =>
                `ContractProfessionalAssessment/GetByContractIdAndProfessionalAssessmentId/${contractId}/${professionalAssessmentId}`,
            CONTRACT_CONFIG: (contractId: ApiId) => `Contracts/${contractId}/SaveContractSpecificConfig`
        },

        PRESCRIPTION_PORTAL: {
            CONTRACT_RETAILER: (contractId: ApiId = '') => `ContractRetailer${contractId && '?contractId=' + contractId}`,
            CONTRACT_RETAILERS_BY_CONTRACT_ID: (contractId: ApiId) =>
                `ContractRetailer/GetByContractId/${contractId}`,
            CONTRACT_RETAILER_USER: (contractId: ApiId = '') => `ContractRetailerUser${contractId && '?contractId=' + contractId}`,
        },

        BUDGET_PIN: {
            ORGANISATIONS: (contractId: ApiId = '') => `Organisations${contractId && '?contractId=' + contractId}`,

            AREAS: (contractId: ApiId = '') => `PinArea${contractId && '?contractId=' + contractId}`,

            TEAMS: (contractId: ApiId = '') => `PinTeam${contractId && '?contractId=' + contractId}`,

            GROUPS: (contractId: ApiId = '') => `PinGroups${contractId && '?contractId=' + contractId}`,

            GROUPS_PRODUCT: (contractId: ApiId = '', productId: ApiId = '', isRemove: boolean) => `PinGroups/GetPinGroupsForProduct${contractId && '?contractId=' + contractId}${productId && contractId && '&&productId=' + productId}${productId && contractId && '&&isRemove=' + isRemove}`,

            GROUP_STOCK: (pinGroupId: ApiId = '') => `PinGroupsContractProducts${pinGroupId && `?pinGroupId=${pinGroupId}`}`,
            GROUP_STOCK_REMOVE: (pinGroupId: ApiId = '') => `PinGroupsContractProducts/ProductsRemove${pinGroupId && `?pinGroupId=${pinGroupId}`}`,
            ///// USER APIS ///////
            CONTRACT_PIN_USER_AUDIT: (PinUserId: ApiId) => `PinUser/GetPinUserAudit?PinUserId=${PinUserId}`,
            // This one gets all PIN users on a contract. This mixes the identity user with the PIN details
            CONTRACT_PIN_USER: (contractId: ApiId = '') => `PinUser${contractId && '?contractId=' + contractId}`,
            // This one gets all of the PINs that a pin user currently has
            USER_PIN_USERS: `PinUser/PinUserSearch`,
            // Search identity users
            SEARCH_USERS: 'Users/UserSearch',
            // Import user CSV
            CONTRACT_PIN_USER_IMPORT: `PinUser/import`,
            CONTRACT_PIN_USER_GENERATE_TEMPLATE: `PinUser/generateCsvTemplate`,
            // This one gets all of the PIN users who are authorisers. This model returns a subset of the PIN user information
            AUTHORISERS: (contractId: ApiId = '') => `PinUser/authorisers?contractId=${contractId}`

            /////////////
        },
        KEY_DATES: {
            AWARD_DATE: (contractId?: ApiId) => `ContractAwardDate${contractId ? `/${contractId}` : ''}`,
            OPERATIONAL_DATES: (contractId?: ApiId) => `ContractDates${contractId ? `?contractId=${contractId}` : ''}`,
            EXTENSION_OPTIONS: (contractId?: ApiId) =>
                `ContractExtensionDates${contractId ? `?contractId=${contractId}` : ''}`,
            PRICE_REVIEW_DATES: (contractId?: ApiId) => `PriceReviewDate${contractId ? `?contractId=${contractId}` : ''}`,
            WORKING_HOURS: (contractId?: ApiId) => `WorkingHours${contractId ? `?contractId=${contractId}` : ''}`,
            NON_WORKING_DAYS: (contractId?: ApiId) => `NonWorkingDates${contractId ? `/${contractId}` : ''}`,
            MILLBROOK_CONTACT: (contractId?: ApiId) =>
                `ContractContactDetails${contractId ? `?contractId=${contractId}` : ''}`
        },
        SCOPE: {
            SERVICES: `ContractServiceType`,
            SERVICES_GET: (contractId: ApiId) => `ContractServiceType?contractId=${contractId}&includeMasterList=true`,

            SPEEDS: `ContractServiceSpeed`,
            SPEEDS_GET: (contractId: ApiId, contractServiceTypeId: ApiId = '', useMaster: 'true' | 'false' = 'true') =>
                `ContractServiceSpeed?contractId=${contractId}${contractServiceTypeId && `&contractServiceTypeId=${contractServiceTypeId}`
                }&includeMasterList=${useMaster}`,

            TIME_SLOTS_GET: (contractId: ApiId, contractServiceTypeId: ApiId) =>
                `Contracts/${contractId}/ServiceTimeSlots/${contractServiceTypeId}`,
            TIME_SLOTS_UPDATE: (contractId: ApiId) => `Contracts/${contractId}/ServiceTimeSlots`,

            REVIEW_TYPES: `contractReviewTypes`,
            REVIEW_TYPES_GET: (contractId: ApiId) => `ContractReviewTypes?contractId=${contractId}&includeMasterList=true`,

            PPM_TYPES: `contractPPMTypes`,
            PPM_TYPES_GET: (contractId: ApiId) => `contractPPMTypes?contractId=${contractId}&includeMasterList=true`,
            SCRAP_GET: (contractId: ApiId) => `ContractScrapLimit/${contractId}`,
            SCRAP: `ContractScrapLimit`,
            PRODUCT_FIELDS: (contractId: ApiId) => `ContractProductMiscField/${contractId}`,
            SAVE_HIRE_TRIAL: (contractId: ApiId) => `Contracts/${contractId}/SaveContractTrialHireConfig`,
            SAVE_SOUNDEX_SEARCH: (contractId: ApiId) => `Contracts/${contractId}/SaveContractSoundexSearchConfig`
        },
        PERIPHERAL_STORES: {
            BASE: (contractId: ApiId = '') => `PeripheralStore${contractId ? `?contractId=${contractId}` : ''}`,
            STOCK: (pstoreId: ApiId) => `PeripheralStore/${pstoreId}/products`,
            CONTRACT_CONFIG: (contractId: ApiId) => `PeripheralStore/Contract/${contractId}/Config`
        },
        SERVICE_USER: {
            SERVICE_USER_GROUPS: `ContractServiceUserGroups`,
            SERVICE_USER_ALERTS: `ContractClientAlerts`,
            SERVICE_USER_INACTIVE_REASONS: `ContractServiceUserInactiveReasons`,
            SERVICE_USER_FIELDS: `ServiceUserFieldsValidations`,
            ADDITIONAL_SERVICE_USER_FIELDS: (contractId: ApiId) => `Contracts/${contractId}/serviceuserfields`,
            CONTRACT_CCGS: `ContractCCG`,
            CONTRACT_CCGS_UNMAPPED: `ContractCCG/GetAllUnmapped`,
            CONTRACT_CCGS_GP_SEARCH: `ContractCCG/SearchForGP`,
            CONTRACT_PROPERTY_OWNERSHIP_TYPES: 'ContractPropertyOwnershipTypes'
        },
        AUDIT_HISTORY: {
            AUDIT_HISTORY_GET: (contractId: ApiId) => `contractAudit?contractId=${contractId}`
        },
        RECYCLED_SPECIALS: {
            SHARED_CONTRACTS: (contractId: ApiId) => `contracts/${contractId}/sharedContractsForRsps`
        }
    },
    PROCUREMENT: {
        SUPPLIERS: {
            SUPPLIER: 'Supplier',
            SUPPLIERS: 'Suppliers',
            SUPPLIERS_COUNT: 'Suppliers/Count',
            SUPPLIER_PRODUCT: (supplierId: ApiId, locationId?: ApiId, searchText?: string) =>
                `supplierProduct?supplierId=${supplierId}${locationId ? `&locationId=${locationId}` : ''}${searchText ? `&searchText=${searchText}` : ''
                }`,
            SUPPLIER_PRODUCT_SEARCH: 'SupplierProduct/Search',
            SUPPLIER_PRODUCT_UNMAPPED_COUNT: (supplierId: ApiId) => `SupplierProduct/CountUnmapped?supplierId=${supplierId}`,
            SUPPLIER_PRO: 'SupplierProduct',
            DOCUMENTS: (supId: string, type: SupDocType) => `SupplierDocuments?supId=${supId}&Type=${type}`,
            SUPPLIER_MEDIAS: 'SupplierDocuments',
            SUPPLIER_PRODUCT_PRICE_BY_LOCATION: (supplierProductId: ApiId, locationId?: ApiId) =>
                `SupplierProductPrice/GetSupplierProductPricesByLocation/${supplierProductId}/${locationId}`,
            SUPPLIER_PRODUCT_PRICE_CUSTOM_LINE: 'SupplierProductPrice/CreateCustomLineSupplierPrice'
        },
        PROCUREMENT: {
            PROCUREMENT: 'Procurement',
            SPECIAL_ORDER_POOL: (activityId: ApiId) => `Procurement/GetSpecialOrderPool/${activityId}`,
            SPECIAL_ORDER_POOL_DELETE_ITEM: `Procurement/DeleteSpecialOrderPoolItem`,
            RECOMMENDED_ORDER: (locationId?: ApiId, supplierid?: ApiId) =>
                `Procurement/GetProcurementRecommendedOrders?locationId=${locationId}${supplierid ? `&SupplierID=${supplierid}` : ''
                }`,
            LOCATION_STOCK_LEVELS: (locationId?: ApiId) => `Procurement/GetLocationProductLevels?locationId=${locationId}`,
            CREATE_LOCATION_STOCK_LEVEL: 'Procurement/CreateLocationProductLevel',
            UPDATE_LOCATION_STOCK_LEVEL: 'Procurement/UpdateLocationProductLevel',
            PROCESS_RECOMMENDED_ORDER: `Procurement/ProcessProcurementRecommendedOrders?RecommendedOrders`
        },
        SPECIAL_PURCHASE_ORDERS: {
            SPECIAL_PURCHASE_ORDER: 'SpecialPurchaseOrder',
            SPECIAL_PRODUCT: 'SpecialProduct',
            SPECIAL_PURCHASE_ORDER_COUNT: 'SpecialPurchaseOrder/Count',
            SPECIAL_PURCHASE_ORDER_DRAFTS: (activityId: ApiId) =>
                `SpecialPurchaseOrder/GetSpecialPurchaseOrderDrafts/${activityId}`,
            SPECIAL_PURCHASE_ORDERS_BY_ACTIVITY_REF: (activityRef: string) =>
                `SpecialPurchaseOrder/GetSpecialPurchaseOrderByActivityRef/${activityRef}`,
            UPDATE_SPECIAL_REQUISITION: 'SpecialPurchaseOrder/UpdateSpecialRequisition',
            SPECIAL_ORDER_PROCESSED: (activityId: ApiId) => `SpecialPurchaseOrder/GetSpecialOrderProcessed/${activityId}`
        },
        PURCHASE_ORDERS: {
            PURCHASE_ORDER: 'PurchaseOrders',
            DOCUMENTS: (poId: string) => `PurchaseOrderDocuments?poId=${poId}`,
            PURCHASE_ORDER_COUNT: 'PurchaseOrders/Count',
            PURCHASE_ORDER_PDF: (purchaseOrderId: ApiId) => `PurchaseOrders/PDF/${purchaseOrderId}`,
            PURCHASE_ORDER_MEDIAS: 'PurchaseOrderDocuments',
            PURCHASE_ORDERS: (locationId?: ApiId, contractId?: ApiId, statusIds?: number[], isSpecial?: boolean) =>
                `PurchaseOrders?${locationId ? `locationId=${locationId}` : ''}${contractId ? (locationId ? `&contractId=${contractId}` : `contractId=${contractId}`) : ''
                }${statusIds ? (locationId || contractId ? `&statusIds=${statusIds}` : `statusIds=${statusIds}`) : ''}${isSpecial !== undefined
                    ? locationId || contractId || statusIds
                        ? `&isSpecial=${isSpecial}`
                        : `isSpecial=${isSpecial}`
                    : ''
                }`
        }
    },
    WAREHOUSE: {
        SERVICING_TYPES: {
            SERVICING_TYPES_GET: 'ServicingType',
            SERVICING_TYPES: (productId: ApiId) => `ServicingType/GetTypesByProductId/${productId}`
        },
        SERVICING_RECORDS: {
            SERVICING_RECORD: 'ServicingRecord',
            SERVICING_RECORDS: (stockId: ApiId) => `ServicingRecord/GetList/${stockId}`,
            SERVICING_RECORD_CERTIFICATE: (servicingRecordId: ApiId) => `ServicingRecord/Certificate/${servicingRecordId}`
        },
        STOCK: {
            STOCK: (puk: string, locationId?: ApiId) =>
                `Stock/GetByPUK?puk=${puk}${locationId ? `&locationId=${locationId}` : ''}`,
            STOCK_CONTRACTS_TO_RSP: (puk: string) => `Stock/CheckContractsForPukConvertToRSP?puk=${puk}`,
            STOCK_TO_RSP: 'Stock/StockToRSP',
            STOCK_HISTORY: (stockId: ApiId) => `StockHistory/GetByStockId?stockId=${stockId}`,
            STOCK_GETBYCERT: (fieldstickerid: string, locationId?: ApiId) =>
                `Stock/GetByCertificate?fieldstickerid=${fieldstickerid}${locationId ? `&locationId=${locationId}` : ''}`,
                RSP_VALIDITY: (puk: string) => `Stock/CheckRSPValidity?puk=${puk}`
        },
        USER_SETTING: {
            USER_SETTING: 'UserSetting',
            USER_SETTING_GET_BY_ID: (userSettingId: ApiId) => `UserSetting?id=${userSettingId}`,
            USER_SETTING_GET_BY_USER: `UserSetting/GetUserSettingByUserId`
        },
        WAREHOUSE_PRODUCT: {
            WAREHOUSE_PRODUCT: 'WarehouseProduct'
        },
        WORKSHOP: {
            LIST_JOBS: 'Workshop',
            LIST_JOBS_FILTERS: 'Workshop/SearchFilters',
            GENERATE_QUOTE_PDF: (jobId: ApiId) => `Workshop/ProduceRefurbishmentQuote/${jobId}`,
            JOB_DETAILS: (jobId: ApiId) => `Workshop/details/${jobId}`,
            JOB_CREATE_UPDATE: 'Workshop',
            STOCK_SPARE_PARTS: (stockId: ApiId) => `Workshop/suggestedSpareParts/${stockId}`
        }
    },
    ASSESSMENTS: {
        PROFESSIONAL_ASSESSMENT: 'ProfessionalAssessment'
    },
    PRINT: {
        PRINTERS: {
            PRINTERS: (servicecentre: string) => `Printer?servicecentre=${servicecentre}`
        },
        PRINT_JOBS: {
            MAINTENANCE_CERTIFICATE_LABEL: 'PrintJob'
        }
    },
    ACTIVITY: {
        ALERTS: (activityId: ApiId) => `Activity/${activityId}/Alerts`,
        SEARCH: 'Activity/AdminSearch',
        PSTORE_SEARCH: 'Activity/SearchActivitiesFromPStore',
        DETAILS: 'Activity/AdminGet',
        CHARGES: (activityId: ApiId) => `Activity/${activityId}/CreditDebitAdjustments`,
        ADJUST_CHARGE: 'Activity/adjustActivityCharge',
        LOOKUP_BY_REFERENCE: `Activity/LookupByReference`,
        AMEND_COMPLETION_DATE: (activityId: ApiId, completedDate: IsoDate) =>
            `Activity/${activityId}/AmendActivityCompletionDate?completedDate=${completedDate}`,
        CORRECT_ACTIVITY: (activityId: ApiId) => `Activity/${activityId}/CorrectActivity`,
        AMEND_ACTIVITY_STATUS: (activityId: ApiId) => `Activity/${activityId}/AmendActivityStatus`
    },
    SERVICE_USER: {
        SEARCH: 'ServiceUser/AdminSearch',
        DETAILS: (serviceUserId: ApiId, contractId: ApiId) => `ServiceUserRecord/AdminGet/${serviceUserId}/${contractId}`,
        EOS_DETAILS: (serviceUserId: ApiId) => `ServiceUserRecord/${serviceUserId}/AdminEosDetail`,
        REMOVE_EOS: (serviceUserId: ApiId, eosId: ApiId) => `ServiceUserRecord/${serviceUserId}/RemoveEos/${eosId}`
    },
    PUKS: {
        search: `Stock/search`,
        details: (stockId: ApiId) => `StockHistory/GetHistoryDetails/${stockId}`
    }
};
