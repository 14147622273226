import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  ContractHoldTimes,
  ErrorResponse,
  HoldTime,
  ContractHoldTimesResponse,
  ISelectOption,
  mapApiErrors
} from 'millbrook-core';
import { HoldTimesSectionFormData } from 'pages/Contract/RecycledSpecialProducts/HoldTimesSection/components/HoldTimesSectionForm';
import { getItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { getItemsOnContract, mapContractHoldTimesFormToRequest } from '../mappers';
import { clearContractState } from '../overview/overview.slice';

/* types */
export enum HoldTimes {
  ONE_DAY = '1',
  TWO_DAYS = '2',
  THREE_DAYS = '3',
  FOUR_DAYS = '4',
  FIVE_DAYS = '5'
}

export const HoldTimesDisplayName = {
  [HoldTimes.ONE_DAY]: '1 Day',
  [HoldTimes.TWO_DAYS]: '2 Days',
  [HoldTimes.THREE_DAYS]: '3 Days',
  [HoldTimes.FOUR_DAYS]: '4 Days',
  [HoldTimes.FIVE_DAYS]: '5 Days'
};

export const mapHoldTimessToSelectOptions = (): ISelectOption[] => {
  return Object.values(HoldTimes).map((key) => ({
    value: key,
    label: HoldTimesDisplayName[key]
  }));
};

export type HoldTimeIds = number[];

export interface HoldTimesRequest extends Omit<ContractHoldTimes, 'holdTimes'> {
  holdTimeIds: HoldTimeIds;
}

/* state */
interface HoldTimesState {
  holdTimes?: HoldTime[];
  hasHoldTimes: boolean;
}

const initialState: HoldTimesState = {
  hasHoldTimes: false
};

const contractHoldTimesSlice = createSlice({
  name: 'holdTimes',
  initialState,
  reducers: {
    setHoldTimes(state, action: PayloadAction<ContractHoldTimes | undefined>) {
      state.holdTimes = action.payload?.holdTimes || [];
      state.hasHoldTimes = action.payload?.hasHoldTimes || false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchHoldTimes =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ContractHoldTimesResponse>(
      `${ENDPOINTS.CONTRACT_RECYCLED_SPECIAL_HOLD_TIMES(contractId)}?includeMasterList=true`
    ).then((response) => {
      dispatch(setHoldTimes(response.result));
    });
  };

export const updateHoldTimes =
  (holdTimes: HoldTimesSectionFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<HoldTimesRequest, ContractHoldTimesResponse>(
      ENDPOINTS.CONTRACT_RECYCLED_SPECIAL_HOLD_TIMES(contractId),
      mapContractHoldTimesFormToRequest(holdTimes)
    ).then(
      () => {
        dispatch<any>(fetchHoldTimes(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setHoldTimes } = contractHoldTimesSlice.actions;

/* selectors */
export const selectHoldTimes = (state: RootState) => state.contract.recycledSpecialProducts.holdTimes.holdTimes;
export const selectHasHoldTimes = (state: RootState) => state.contract.recycledSpecialProducts.holdTimes.hasHoldTimes;
export const selectContractHoldTimes = createSelector([selectHoldTimes], (holdTimes) => {
  return getItemsOnContract<HoldTime>(holdTimes);
});

export default contractHoldTimesSlice.reducer;
