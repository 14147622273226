import { ApiId, ErrorResponse, mapApiErrors } from 'millbrook-core';
import { ENDPOINTS } from 'constants/api';
import { mapPooledRspFormDataToRequest } from '../mappers';
import { AppThunk, RootState } from 'store/store';
import { putItem, getItem } from 'services/api.service';
import { PooledRspFormData } from 'pages/Contract/RecycledSpecialProducts/PooledRspsSection/components/PooledRspsSectionForm';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { clearContractState, selectContractOverview } from '../overview/overview.slice';
import { ContractRspSharingConfig, RspSharingConfigResponse } from './pooledRsps.types';
import { ContractStateEnum } from '../contract.types';
import { selectContracts } from 'features/contracts/contracts.slice';

interface PoolRspState {
  contractId?: ApiId;
  sharedWithContractIds: ApiId[];
}

const initialState: PoolRspState = {
  sharedWithContractIds: []
};

const contractPooledRspsSlice = createSlice({
  name: 'pooledRsps',
  initialState,
  reducers: {
    setSharedContractsForRsps(state, action: PayloadAction<RspSharingConfigResponse | undefined>) {
      state.contractId = action.payload?.result?.contractId || '';
      state.sharedWithContractIds = action.payload?.result?.sharedWithContractIds || [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const { setSharedContractsForRsps } = contractPooledRspsSlice.actions;

export const updateSharedContractsForRsps =
  (sharedContracts: PooledRspFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<ContractRspSharingConfig, RspSharingConfigResponse>(
      ENDPOINTS.CONTRACT.RECYCLED_SPECIALS.SHARED_CONTRACTS(contractId),
      mapPooledRspFormDataToRequest(sharedContracts, contractId)
    ).then(
      () => {
        dispatch(fetchSharedContractsForRsps(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchSharedContractsForRsps =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItem<null, RspSharingConfigResponse>(
      ENDPOINTS.CONTRACT.RECYCLED_SPECIALS.SHARED_CONTRACTS(contractId)
    ).then(
      (response) => {
        dispatch(setSharedContractsForRsps(response));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const selectLiveContracts = createSelector(
  [selectContracts, selectContractOverview],
  (contracts = [], contract) => {
    return contracts.filter((x) => x.contractState === ContractStateEnum.Live && x.id != contract.id);
  }
);

export const selectSharedContracts = (state: RootState) => state.contract.recycledSpecialProducts.pooledRsps;

export default contractPooledRspsSlice.reducer;
