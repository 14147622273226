import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
  [ContractSectionEnum.Organisations]: 'Organisations',
  [ContractSectionEnum.Areas]: 'Areas',
  [ContractSectionEnum.Teams]: 'Teams',
  [ContractSectionEnum.Groups]: 'Groups',
  [ContractSectionEnum.Users]: 'Users',
  [ContractSectionEnum.Stock]: 'Stock'
};

const route = ROUTES.CONTRACT.budgetPin;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavBudgetSteps = (contractId: ApiId): ContractSection[] => [
  {
    step: ContractSectionEnum.Organisations,
    name: stepDisplayName[ContractSectionEnum.Organisations],
    href: route.organisations(contractId)
  },
  {
    step: ContractSectionEnum.Areas,
    name: stepDisplayName[ContractSectionEnum.Areas],
    href: route.areas(contractId)
  },
  {
    step: ContractSectionEnum.Teams,
    name: stepDisplayName[ContractSectionEnum.Teams],
    href: route.teams(contractId)
  },
  {
    step: ContractSectionEnum.Groups,
    name: stepDisplayName[ContractSectionEnum.Groups],
    href: route.groups(contractId)
  },
  {
    step: ContractSectionEnum.Users,
    name: stepDisplayName[ContractSectionEnum.Users],
    href: route.users(contractId)
  },
  {
    step: ContractSectionEnum.Stock,
    name: stepDisplayName[ContractSectionEnum.Stock],
    href: route.stock(contractId)
  }
];
