import { combineReducers } from '@reduxjs/toolkit';
import ppmTypes from './contractPPMTypes.slice';
import productFields from './contractProductFields.slice';
import reviewTypes from './contractReviewTypes.slice';
import services from './contractServices.slice';
import speeds from './contractSpeeds.slice';
import timeSlots from './contractTimeSlots.slice';
import cleaningCodesAndCharges from './cleaningCodes.slice';

export const scope = combineReducers({
  services,
  speeds,
  reviewTypes,
  ppmTypes,
  timeSlots,
  productFields,
  cleaningCodesAndCharges
});
