import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, IsoDate, mapApiErrors } from 'millbrook-core';
import { ExtensionDateFormData } from 'pages/Contract/KeyDates/ExtensionDates/components/ExtensionDateForm/ExtensionDateForm';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

// types
export interface ExtensionDate {
  id: ApiId;
  contractId?: ApiId;
  extensionStartDate: IsoDate;
  extensionEndDate: IsoDate;
  wasApplied: boolean;
}

export interface ExtensionDateCreateRequest extends Omit<ExtensionDate, 'id' | 'contractId'> {
  id?: ApiId;
}
export type ExtensionDatesRequest = ExtensionDate;
export type ExtensionDatesResponse = IApiResponse<ExtensionDate[]>;

interface ExtensionDateState {
  extensions?: ExtensionDate[];
}

const initialState: ExtensionDateState = {};

const contractExtensionDatesSlice = createSlice({
  name: 'extensionDates',
  initialState,
  reducers: {
    setExtensionDates(state, action: PayloadAction<ExtensionDate[]>) {
      state.extensions = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const { setExtensionDates } = contractExtensionDatesSlice.actions;

// thunks
export const fetchExtensionDates =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ExtensionDatesResponse>(ENDPOINTS.CONTRACT.KEY_DATES.EXTENSION_OPTIONS(contractId)).then(
      (response) => {
        dispatch(setExtensionDates(response.result || []));
      }
    );
  };

export const createExtensionDate =
  (extensionDate: ExtensionDateFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<ExtensionDateCreateRequest, ExtensionDatesResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.EXTENSION_OPTIONS(contractId),
      extensionDate as any
    ).then(
      () => {
        dispatch<any>(fetchExtensionDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateExtensionDate =
  (extensionDate: ExtensionDateFormData, id: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<ExtensionDatesRequest, ExtensionDatesResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.EXTENSION_OPTIONS(),
      { ...(extensionDate as any), id, contractId },
      id
    ).then(
      () => {
        dispatch<any>(fetchExtensionDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteExtensionDate =
  (id: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ApiId, ExtensionDatesResponse>(ENDPOINTS.CONTRACT.KEY_DATES.EXTENSION_OPTIONS(), id, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchExtensionDates(contractId));
      },
      (response: ErrorResponse) => {
        // handled in global error handler
        //const error = mapApiErrors(response);
        //throw new Error(error);
      }
    );
  };

// selectors
export const selectExtensionDates = (state: RootState) => state.contract.keyDates.extensionDates.extensions;

export default contractExtensionDatesSlice.reducer;
