import {
  ApiId,
  BaseSearchPaging,
  ButtonTypes,
  ColorType,
  IApiPagination,
  IFilterItemModel,
  IGuidName,
  IMediaModel,
  ISearchPagination,
  IsoDate,
  ShelfTypeEnum
} from 'millbrook-core';

export interface WarehouseStock {
  id: ApiId;
  puk: string;
  serialNumber: string;
  batchNumber: string;
  isPCI: boolean;
  status: string;
  statusDescription: string;
  sku: string;
  name: string;
  description: string;
  shelfType: ShelfTypeEnum;
  defaultPurchasePrice: string;
  productId: ApiId;
  internalNotes?: string;
  location?: IGuidName;
  contractId?: string;
  mainImage?: IMediaModel;
  zone?: string;
}

export interface WorkshopSummaryModel {
  id: ApiId;
  jobReference: string;
  type: WorkshopTypeEnum;
  status: WorkshopStatusEnum;
  completedDate: IsoDate;
  createdBy: string;
  puk: string;
  productName: string;
  dateCreated: IsoDate;
}

export interface WorkshopModel extends WorkshopSummaryModel {
  totalLabourTime: string; // 00:00:00
  productId: ApiId;
  stockId: ApiId;
  sku: string;
  productImage: IMediaModel | null;
  contractId: ApiId;
  contractName: string;
  locationId: ApiId;
  items: WorkshopItemModel[];
}

export interface WorkshopItemModel {
  id: ApiId;
  workShopId?: ApiId;
  itemReference?: string;
  sku: string;
  name: string;
  quantity: number;
}

export interface WorkshopCreateModel {
  stockId: ApiId;
  locationId: ApiId;
  type: WorkshopTypeEnum;
}

export enum WorkshopTypeEnum {
  Job = 1,
  Quote = 2
}

export enum WorkshopStatusEnum {
  Open = 1,
  Complete = 2,
  CancelledBer = 3,
  CancelledOther = 4,
  CancelledRaisedInError = 5,
  OnHoldAwaitingTrust = 6,
  OnHoldAwaitingParts = 7
}

export const WorkshopStatusDisplayName = {
  [WorkshopStatusEnum.Open]: 'Open',
  [WorkshopStatusEnum.Complete]: 'Complete',
  [WorkshopStatusEnum.CancelledBer]: 'Cancelled - BER',
  [WorkshopStatusEnum.CancelledRaisedInError]: 'Cancelled - Raised in error',
  [WorkshopStatusEnum.CancelledOther]: 'Cancelled - other',
  [WorkshopStatusEnum.OnHoldAwaitingParts]: 'On hold - awaiting parts',
  [WorkshopStatusEnum.OnHoldAwaitingTrust]: 'On hold - awaiting trust'
};

export const WorkshopJobStatusColour = (status: WorkshopStatusEnum): { button: ButtonTypes; colour: ColorType } => {
  return [
    WorkshopStatusEnum.Complete,
    WorkshopStatusEnum.CancelledBer,
    WorkshopStatusEnum.CancelledOther,
    WorkshopStatusEnum.CancelledRaisedInError
  ].some((x) => x === status)
    ? { button: 'finished', colour: 'edward30' }
    : [WorkshopStatusEnum.OnHoldAwaitingParts, WorkshopStatusEnum.OnHoldAwaitingTrust].some((x) => x === status)
    ? { button: 'hold', colour: 'error' }
    : { button: 'progress', colour: 'jungleGreen' };
};

export interface WorkshopItemAddModel {
  quantity: number;
  sku: string;
}

export interface WorkshopUpdateModel {
  id: ApiId;
  items: WorkshopItemAddModel[];
  status: WorkshopStatusEnum;
  totalLabourTime: string;
}


export interface RSPValidityCheckToolModel {
    message: string;
}

// Workshop filters models
export enum WorkshopSearchDateTypeEnum {
  NoDate = 0,
  CreateDate = 1,
  CloseDate = 2
}

export const workshopSearchDateTypeDisplayNames = {
  [WorkshopSearchDateTypeEnum.NoDate]: 'No date',
  [WorkshopSearchDateTypeEnum.CreateDate]: 'Date created',
  [WorkshopSearchDateTypeEnum.CloseDate]: 'Date closed'
};

export interface WorkshopJobSearch extends ISearchPagination {
  serviceCentreId?: ApiId;
  searchTerm?: string;
  dateSearchType?: WorkshopSearchDateTypeEnum;
  fromDate?: IsoDate;
  toDate?: IsoDate;

  // These are the side filters
  users?: ApiId[];
  statuses?: WorkshopStatusEnum[];
}

export interface WorkshopFiltersResponseModel {
  statuses: IFilterItemModel<WorkshopStatusEnum>[];
  users: IFilterItemModel<ApiId>[];
}

export class WorkshopSearchQueryString extends BaseSearchPaging {}

export type PagedWorkshopJobs = IApiPagination<WorkshopModel[]>;
