import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
  [ContractSectionEnum.LogoUpload]: 'Logo upload',
  [ContractSectionEnum.HelpSupport]: 'Help and support',
  [ContractSectionEnum.BenefitsRealisation]: 'Benefits realisation',
  [ContractSectionEnum.ProfessionalAssessment]: 'Professional assessment',
  [ContractSectionEnum.ContractConfig]: 'Contract specific configuration'
};

const route = ROUTES.CONTRACT.prescriberPortal;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavPrescriberPortalSteps = (contractId: ApiId): ContractSection[] => [
  {
    step: ContractSectionEnum.LogoUpload,
    name: stepDisplayName[ContractSectionEnum.LogoUpload],
    href: route.prescriberLogo(contractId)
  },
  {
    step: ContractSectionEnum.HelpSupport,
    name: stepDisplayName[ContractSectionEnum.HelpSupport],
    href: route.helpAndSupport(contractId)
  },
  {
    step: ContractSectionEnum.BenefitsRealisation,
    name: stepDisplayName[ContractSectionEnum.BenefitsRealisation],
    href: route.benefitsRealisation(contractId)
  },
  {
    step: ContractSectionEnum.ProfessionalAssessment,
    name: stepDisplayName[ContractSectionEnum.ProfessionalAssessment],
    href: route.professionalAssessment.root(contractId)
  },
  {
    step: ContractSectionEnum.ContractConfig,
    name: stepDisplayName[ContractSectionEnum.ContractConfig],
    href: route.contractConfig(contractId)
  }
];
