import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { isNil, omitBy } from 'lodash';
import {
  ActivityAlerts,
  ActivityDetailsModel,
  ActivityFromPeripheralStoreModel,
  ActivityFromPeripheralStoresSearchRequestModel,
  ActivityNote,
  ActivityReferenceLookupResponseModel,
  ActivitySearch,
  addActivityNote,
  ApiId,
  IApiPagination,
  IApiResponse,
  ISearchPagination,
  IsoDate,
  mapApiErrors,
  mapBasketItemsToActivityBasketItemModel,
  mapBasketItemsForSpecialReqActivity,
  PagedActivitiesResponse,
  ReasonCodeLevelEnum,
  selectActivityDetails,
  selectActivityLastReasonCode,
  selectReasonCodes,
  ServiceActionEnum,
  setActivityAlerts,
  setActivityDetails,
  snackbarUtils,
  unsetActivity,
  ServiceTypeCodeEnum,
  selectActivityStatus,
  ActivityStatus
} from 'millbrook-core';
import { serialize } from 'object-to-formdata';
import { NewActivityNoteFormData } from 'pages/Search/ActivityChargesPage/components/ChargeAttachmentsForm';
import qs from 'query-string';
import { getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import {
  ActivityChargeResponseModel,
  ActivityCorrectionRequestModel,
  ActivityCorrections,
  AttachmentNoteModel,
  CreditDebitAdjustmentRequestModel
} from './activity.types';

export type ActivityNoteRequest = Omit<ActivityNote, 'id' | 'dateCreated' | 'dateUpdated' | 'createdBy'>;
export type ActivityNoteResponse = IApiResponse<ActivityNote>;
/* types */
export type ActivityResponse = IApiResponse<ActivityDetailsModel>;

export const defaultActivityPaging: ISearchPagination = {
  pageSize: 5,
  pageIndex: 1,
  sortColumn: 'dateCreated',
  sortOrder: 'desc'
};

export const defaultPstoreActivityPaging: ISearchPagination = {
  pageSize: 5,
  pageIndex: 1,
  sortColumn: 'targetDate',
  sortOrder: 'desc'
};

/* state */
interface ActivityState {
  activityDetailsId?: ApiId;
  charges?: ActivityChargeResponseModel;
  corrections: { [key: string]: ActivityCorrections };
}

const initialState: ActivityState = {
  corrections: {}
};

/* slice */
const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivityCharges(state, action: PayloadAction<ActivityChargeResponseModel | undefined>) {
      state.charges = action.payload;
    },
    setActivityCorrections(state, action: PayloadAction<ActivityCorrections | undefined>) {
      const activityId = state.activityDetailsId;

      if (activityId) {
        // combine the object and remove and undefined
        state.corrections[activityId] = omitBy({ ...state.corrections[activityId], ...action?.payload }, isNil);
      }
    },
    clearActivityCorrections(state, action: PayloadAction<ApiId | undefined>) {
      const activityId = action.payload ?? state.activityDetailsId;

      if (activityId) {
        delete state.corrections[activityId];
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(setActivityDetails, (state, action: PayloadAction<ActivityDetailsModel | undefined>) => {
        state.activityDetailsId = action.payload?.id;
      })
      .addCase(unsetActivity, (state) => {
        state.activityDetailsId = undefined;
      });
  }
});

/* actions */
export const { setActivityCharges, setActivityCorrections, clearActivityCorrections } = activitySlice.actions;

/* thunks */
export const fetchActivityDetails =
  (activityId: ApiId, keepIndividualItems: boolean = true): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, ActivityResponse>(ENDPOINTS.ACTIVITY.DETAILS, activityId).then(
      (response) => {
        const activityDetails = response.result;

        if (activityDetails) {
          const { contractServiceSpeed: speed } = activityDetails;

          if (activityDetails?.basket.basketItems) {
            if (speed?.serviceTypeCode === ServiceTypeCodeEnum.SpecialRequisition) {
              activityDetails.basket.basketItems = mapBasketItemsForSpecialReqActivity(activityDetails);
            } else if (!keepIndividualItems) {
              // remap the BasketItems to ActivityBasketItemModel. This groups the basket items by reason code and sku
              // For activity corrections (and perhaps other areas), we don't want to do this.
              activityDetails.basket.basketItems = mapBasketItemsToActivityBasketItemModel(activityDetails);
            }
          }

          dispatch(setActivityDetails(activityDetails));
        }

        return activityDetails;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const amendActivityStatus =
    (activityId: ApiId): AppThunk =>
        async (dispatch) => {
            return putItem<ApiId, IApiResponse<boolean>>(ENDPOINTS.ACTIVITY.AMEND_ACTIVITY_STATUS(activityId), '').then(
                () => {
                    dispatch<any>(fetchAllActivityDetails(activityId));
                },
                (response) => {
                    return response;
                }
            ).catch(() => {
                    // error handled in global dialog
                });; 
        };

export const fetchActivityAlerts =
  (activityId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<ActivityAlerts>>(ENDPOINTS.ACTIVITY.ALERTS(activityId)).then(
      (response) => {
        dispatch(setActivityAlerts(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchActivities =
  (searchFilters: ActivitySearch = {}): AppThunk =>
  async (dispatch, getState) => {
    const queryFilters = searchFilters
      ? '?' +
        qs.stringify(
          {
            ...defaultActivityPaging,
            ...searchFilters
          },
          { skipEmptyString: true }
        )
      : '';

    const URL = ENDPOINTS.ACTIVITY.SEARCH + queryFilters;

    return getItems<PagedActivitiesResponse>(URL).then((response) => {
      return response.result;
    });
  };

export const fetchActivityGuidByReference =
  (activityReference: string): AppThunk =>
  async (dispatch, getState) => {
    // this is used for exact matches of activity reference. Only called from the activity search panel.
    // return a guid which can be used to go straight to the activity details page
    return getItem<string, IApiResponse<ActivityReferenceLookupResponseModel>>(
      ENDPOINTS.ACTIVITY.LOOKUP_BY_REFERENCE,
      activityReference
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchAllActivityDetails =
  (activityId: ApiId, keepIndividualItems?: boolean): AppThunk =>
  async (dispatch, getState) => {
    return Promise.all([
      dispatch<any>(fetchActivityDetails(activityId, keepIndividualItems)),
      dispatch<any>(fetchActivityAlerts(activityId))
    ]).catch((error) => {
      throw new Error(error);
    });
  };

export const fetchActivityCharges =
  (activityId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    // if there is no activity details WITH THE SAME ACTIVITYID we will need to get it here. This mainly because you might hit the page without going via the order details
    const activityDetails = selectActivityDetails(getState());
    setActivityCharges();

    try {
      const chargesResponse = await getItems<IApiResponse<ActivityChargeResponseModel>>(
        ENDPOINTS.ACTIVITY.CHARGES(activityId)
      );

      if (activityDetails?.id !== activityId) {
        dispatch(setActivityDetails(undefined));
        await dispatch<any>(fetchActivityDetails(activityId));
      }

      dispatch(setActivityCharges(chargesResponse.result));
    } catch (response: any) {
      // promise all throws the first error, not all errors
      const error = mapApiErrors(response);
      throw new Error(error);
    }
  };

export const createChargeAdjustment =
  (chargeAdjustmentModel: CreditDebitAdjustmentRequestModel): AppThunk =>
  async (dispatch) => {
    const { files, ...rest } = chargeAdjustmentModel;

    const formData = serialize(rest, { indices: false, allowEmptyArrays: false });

    if (files?.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
    }

    return postItems<FormData, any>(ENDPOINTS.ACTIVITY.ADJUST_CHARGE, formData).then(
      () => {
        dispatch<any>(fetchActivityCharges(chargeAdjustmentModel.activityId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchPStoreActivities =
  (searchFilters: ActivityFromPeripheralStoresSearchRequestModel = {}): AppThunk =>
  async (dispatch, getState) => {
    const URL = qs.stringifyUrl(
      {
        url: ENDPOINTS.ACTIVITY.PSTORE_SEARCH,
        query: {
          ...defaultPstoreActivityPaging,
          ...searchFilters
        }
      },
      { skipEmptyString: true }
    );

    return getItems<IApiResponse<IApiPagination<ActivityFromPeripheralStoreModel[]>>>(URL).then((response) => {
      return response.result;
    });
  };

export const updateActivityCompletionDate =
  (activityId: ApiId, date: IsoDate): AppThunk =>
  async (dispatch) => {
    return putItem<IsoDate, IApiResponse<boolean>>(ENDPOINTS.ACTIVITY.AMEND_COMPLETION_DATE(activityId, date), '').then(
      () => {
        snackbarUtils.success('Updated activity completion date');
        dispatch<any>(fetchAllActivityDetails(activityId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createActivityCorrections =
  (activityId: ApiId, IsCheckChangesOnly?: boolean): AppThunk =>
  async (dispatch, getState) => {
    const corrections = getState().activity.corrections[activityId];

    if (!corrections) {
      return Promise.reject({ message: 'No corrections found for this activity' });
    }

    // map the corrections to the request model
    const request: ActivityCorrectionRequestModel = {
      newActivityReasonCodeId: corrections.swapReasonCode?.id,
      IsCheckChangesOnly
    };

    return putItem<ActivityCorrectionRequestModel, IApiResponse<string[]>>(
      ENDPOINTS.ACTIVITY.CORRECT_ACTIVITY(activityId),
      request,
      ''
    ).then(
      (response) => {
        if (IsCheckChangesOnly) {
          return response.result || [];
        } else {
          snackbarUtils.success('Activity corrections completed');
          dispatch<any>(fetchAllActivityDetails(activityId));
        }
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/**
 * Selectors
 */
export const selectActivityCorrections = createSelector(
  [selectActivityDetails, (state: RootState) => state.activity.corrections],
  (activity, corrections) => {
    return activity?.id ? corrections[activity.id] : undefined;
  }
);


export const selectCanAmendStatus = createSelector(
    [selectActivityStatus],
    (status) => {
        return (
            status == ActivityStatus.Failed_SnapError ||
            status == ActivityStatus.Failed_StreamError
        );
    }
);

export const selectActivityCorrectionsSwapActivityReasonCodes = createSelector(
  [selectActivityLastReasonCode, selectReasonCodes],
  (lastReasonCode, reasonCodes) => {
    if (!lastReasonCode?.reasonCode) return [];

    const reasonCodeActionFilter =
      lastReasonCode.reasonCode.serviceActionId === ServiceActionEnum.Cancel
        ? ServiceActionEnum.None
        : ServiceActionEnum.Cancel;

    return reasonCodes.filter(
      (x) => x.reasonCodeLevelId === ReasonCodeLevelEnum.Service && x.serviceActionId === reasonCodeActionFilter
    );
  }
);

export const selectActivityCharges = (state: RootState) => state.activity.charges;

export const selectActivityChargeAttachments = createSelector([selectActivityCharges], (activityCharges) => {
  const adjustmentAttachments =
    activityCharges?.charges.reduce((attachments, item) => {
      item.adjustments.forEach((x) => {
        if (x.attachment) {
          attachments = attachments.concat(x.attachment);
        }
      });

      return attachments;
    }, [] as AttachmentNoteModel[]) || [];

  const allAttachments = adjustmentAttachments
    .concat(activityCharges?.attachments || [])
    .sort((a, b) => a.dateCreated.localeCompare(b.dateCreated) * -1);

  return allAttachments;
});

export const createActivityNotes =
  (activityNote: NewActivityNoteFormData): AppThunk =>
  async (dispatch) => {
    const { files, ...rest } = activityNote;

    const formData = serialize(rest, { indices: false, allowEmptyArrays: false });

    for (let i = 0; i < files.length; i++) {
      formData.append('files', files[i]);
    }

    return postItems<FormData, ActivityNoteResponse>('Activity/ActivityNotes', formData) // Hard coded for now, needs added to endpoints
      .then((response) => {
        if (response.result) {
          dispatch(addActivityNote(response.result));
        }
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

/* reducers */
export default activitySlice.reducer;
