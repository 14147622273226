import { ROUTES } from 'constants/routes';
import { sortBy } from 'lodash';
import { ApiId, ServiceSpeedTypeEnum, ServiceTypeCodeEnum } from 'millbrook-core';
import { ContractService } from '../../scope/scope.types';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
    [ContractSectionEnum.ContractServices]: 'Contract services',
    [ContractSectionEnum.ReviewTypes]: 'Review types',
    [ContractSectionEnum.PPMTypes]: 'PPM types',
    [ContractSectionEnum.ScrapLimit]: 'Scrap',
    [ContractSectionEnum.ProductConfiguration]: 'Product configuration',
    [ContractSectionEnum.CleanCodesCharges]: 'Equipment cleaning codes',
    [ContractSectionEnum.HireTrialEquipment]: 'Trial/Hire equipment',
    [ContractSectionEnum.SoundexSearch]: 'Soundex search'
};

const route = ROUTES.CONTRACT.scope;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavScopeSteps = (contractId: ApiId, services: ContractService[]): ContractSection[] => {
    let steps: ContractSection[] = [
        {
            step: ContractSectionEnum.ContractServices,
            name: stepDisplayName[ContractSectionEnum.ContractServices],
            href: route.contractServices(contractId)
        },
        // speeds get inserted here
        {
            step: ContractSectionEnum.ReviewTypes,
            name: stepDisplayName[ContractSectionEnum.ReviewTypes],
            href: route.reviewTypes(contractId)
        },
        {
            step: ContractSectionEnum.ScrapLimit,
            name: stepDisplayName[ContractSectionEnum.ScrapLimit],
            href: route.scrap(contractId)
        },
        {
            step: ContractSectionEnum.ProductConfiguration,
            name: stepDisplayName[ContractSectionEnum.ProductConfiguration],
            href: route.productConfiguration(contractId)
        },
        {
            step: ContractSectionEnum.HireTrialEquipment,
            name: stepDisplayName[ContractSectionEnum.HireTrialEquipment],
            href: route.hireTrialEquipment(contractId)
        },
        {
            step: ContractSectionEnum.SoundexSearch,
            name: stepDisplayName[ContractSectionEnum.SoundexSearch],
            href: route.soundexSearch(contractId)
        }
    ];

    /**
     * Dynamic scope nav
     */

    // speeds
    const dynamicScopeNav = services
        .filter((s) => s.serviceSpeedType !== ServiceSpeedTypeEnum.NoSpeeds)
        .map(
            (service, index): ContractSection => ({
                step: `Speed${index + 1}`,
                name: `${service.masterName} speeds`,
                href: route.contractSpeeds(contractId, service.id)
            })
        );

    if (services.find((x) => x.serviceTypeCode === ServiceTypeCodeEnum.PPM)) {
        dynamicScopeNav.push({
            step: ContractSectionEnum.PPMTypes,
            name: stepDisplayName[ContractSectionEnum.PPMTypes],
            href: route.ppmTypes(contractId)
        });
    }

    if (services.find((x) => x.serviceTypeCode === ServiceTypeCodeEnum.Equipment)) {
        dynamicScopeNav.push({
            step: ContractSectionEnum.CleanCodesCharges,
            name: stepDisplayName[ContractSectionEnum.CleanCodesCharges],
            href: route.cleaningCodesAndCharges(contractId)
        });
    }

    steps.splice(1, 0, ...sortBy(dynamicScopeNav, (o) => o.name));

    return steps;
};
