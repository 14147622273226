import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, mapApiErrors } from 'millbrook-core';
import { ContractReviewTypesFormData } from 'pages/Contract/Scope/ReviewTypes/components/ContractReviewTypesForm';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapContractReviewTypesFormToRequest } from '../mappers';
import { clearContractState } from '../overview/overview.slice';
import { ContractReviewType, ContractReviewTypesRequest, ContractReviewTypesResponse } from './scope.types';
/* state */
interface ReviewTypesState {
  reviewTypesList: ContractReviewType[];
}

const initialState: ReviewTypesState = { reviewTypesList: [] };

const contractReviewTypesSlice = createSlice({
  name: 'reviewTypes',
  initialState,
  reducers: {
    setContractReviewTypesList(state, action: PayloadAction<ContractReviewType[]>) {
      state.reviewTypesList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const fetchContractReviewTypes =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<ContractReviewTypesResponse>(ENDPOINTS.CONTRACT.SCOPE.REVIEW_TYPES_GET(contractId)).then(
      (response) => {
        dispatch<any>(setContractReviewTypesList(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const createContractReviewTypes =
  (data: ContractReviewTypesFormData, contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return postItems<ContractReviewTypesRequest, ContractReviewTypesResponse>(
      ENDPOINTS.CONTRACT.SCOPE.REVIEW_TYPES,
      mapContractReviewTypesFormToRequest(data, contractId)
    ).then(
      (response: any) => {
        dispatch<any>(fetchContractReviewTypes(contractId));
      },
      (response: any) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updateContractReviewTypes =
  (data: ContractReviewTypesFormData, contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return putItem<ContractReviewTypesRequest, ContractReviewTypesResponse>(
      ENDPOINTS.CONTRACT.SCOPE.REVIEW_TYPES,
      mapContractReviewTypesFormToRequest(data, contractId)
    ).then(
      (response) => {
        dispatch<any>(fetchContractReviewTypes(contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deleteContractReviewTypes =
  (data: ContractReviewType): AppThunk =>
  async (dispatch) => {
    return deleteItem<ApiId, ContractReviewTypesResponse>(ENDPOINTS.CONTRACT.SCOPE.REVIEW_TYPES, data.id).then(
      (response) => {
        dispatch<any>(fetchContractReviewTypes(data.contractId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractReviewTypesList } = contractReviewTypesSlice.actions;

/* selectors */
export const selectContractReviewTypesList = (state: RootState) => state.contract.scope.reviewTypes.reviewTypesList;

/* reducers */
export default contractReviewTypesSlice.reducer;
