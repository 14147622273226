import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  createHashFromArray,
  IApiResponse,
  mapApiErrors,
  PeripheralStoreRequest,
  PeripheralStoreResponseModel,
  PeripheralStoreStockItem,
  PeripheralStoreStockItemRequest,
  PeripheralStoreStockItemsResponse,
  showGenericErrorDialog
} from 'millbrook-core';
import { deleteItems, getItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { selectGroupProductList } from '../budgetPin/stock.slice';
import { fetchTeams } from '../budgetPin/teams.slice';
import { clearContractState } from '../overview/overview.slice';
import { ContractPeripheralStoreConfiguration } from '../scope/scope.types';

interface PeripheralStoresState {
  pstores: PeripheralStoreResponseModel[];
  pstoreStockList: PeripheralStoreStockItem[];
  contractPstoreConfig: ContractPeripheralStoreConfiguration;
}

const initialState: PeripheralStoresState = {
  pstores: [],
  pstoreStockList: [],
  contractPstoreConfig: {
    contractHasPstoreServices: false,
    contractHasPstoreSpeeds: false,
    pstoreCollectionService: undefined,
    pstoreDeliveryService: undefined
  }
};

const peripheralStoresSlice = createSlice({
  name: 'peripheralStores',
  initialState,
  reducers: {
    setPeripheralStores(state, action: PayloadAction<PeripheralStoreResponseModel[]>) {
      state.pstores = action.payload;
    },
    setPeripheralStoreStockList(state, action: PayloadAction<PeripheralStoreStockItem[]>) {
      state.pstoreStockList = action.payload;
    },
    setContractPstoreConfig(state, action: PayloadAction<ContractPeripheralStoreConfiguration>) {
      state.contractPstoreConfig = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */

export const initPeripheralStoreFormData =
  (contractId: ApiId, pstoreId?: ApiId): AppThunk =>
  async (dispatch) => {
    return Promise.all([
      dispatch<any>(fetchTeams(contractId)), // [0]
      pstoreId && dispatch<any>(fetchPeripheralStore(pstoreId)) // [1]
    ])
      .then((responses) => {
        return responses[1];
      })
      .catch((error) => {
        const errorMessage = mapApiErrors(error);
        dispatch(showGenericErrorDialog(errorMessage));
        throw new Error(error);
      });
  };

export const fetchPeripheralStores =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<IApiResponse<PeripheralStoreResponseModel[]>>(
      ENDPOINTS.CONTRACT.PERIPHERAL_STORES.BASE(contractId)
    ).then(
      (response) => {
        dispatch(setPeripheralStores(response.result || []));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const fetchPeripheralStore =
  (pstoreId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<PeripheralStoreResponseModel>>(
      ENDPOINTS.CONTRACT.PERIPHERAL_STORES.BASE(),
      pstoreId
    ).then(
      (response) => {
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// this handles post and put
export const updatePeripheralStore =
  (data: PeripheralStoreRequest): AppThunk =>
  async (dispatch) => {
    const request = data.id
      ? putItem<PeripheralStoreRequest, IApiResponse<PeripheralStoreResponseModel>>(
          ENDPOINTS.CONTRACT.PERIPHERAL_STORES.BASE(),
          data,
          data.id,
          {
            // SAMPLE: config parameters for clearing cache with update
            //        cacheName
          }
        )
      : postItems<PeripheralStoreRequest, IApiResponse<PeripheralStoreResponseModel>>(
          ENDPOINTS.CONTRACT.PERIPHERAL_STORES.BASE(),
          data,
          {
            //  cacheName
          }
        );

    return request.then(
      (response) => {
        //dispatch<any>(fetchHelpSections());
        return response.result;
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/**
 * STOCK
 */

export const fetchPeripheralStoreStock =
  (pstoreId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItems<PeripheralStoreStockItemsResponse>(`${ENDPOINTS.CONTRACT.PERIPHERAL_STORES.STOCK(pstoreId)}`, {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {
        dispatch(setPeripheralStoreStockList(response.result || []));
      },
      (response) => {
        // Handled in global error
        //      const error = mapApiErrors(response);
        //      throw new Error(error);
      }
    );
  };

export const addStockToPeripheralStore =
  (pstoreId: ApiId, contractProductId: ApiId, data: PeripheralStoreStockItemRequest): AppThunk =>
  async (dispatch) => {
    const { baseStockLevel } = data;

    return putItem<PeripheralStoreStockItemRequest, any>(
      ENDPOINTS.CONTRACT.PERIPHERAL_STORES.STOCK(pstoreId),
      { ...data, baseStockLevel },
      contractProductId
    ).then(
      () => {
        dispatch<any>(fetchPeripheralStoreStock(pstoreId));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deletePeripheralStoreStockItems =
  (pstoreId: ApiId, data: PeripheralStoreStockItem[]): AppThunk =>
  async (dispatch) => {
    const ids = data.map((d) => d.contractProductId);

    return deleteItems<ApiId[], null>(ENDPOINTS.CONTRACT.PERIPHERAL_STORES.STOCK(pstoreId), ids, {
      enableGlobalErrorDialog: true
    }).then(
      () => {
        dispatch<any>(fetchPeripheralStoreStock(pstoreId));
      },
      (response) => {
        // error handled in Global
        //const error = mapApiErrors(response);
        //throw new Error(error);
      }
    );
  };

export const fetchContractPstoreConfig =
  (contractId: ApiId): AppThunk =>
  async (dispatch) => {
    return getItem<ApiId, IApiResponse<ContractPeripheralStoreConfiguration>>(
      ENDPOINTS.CONTRACT.PERIPHERAL_STORES.CONTRACT_CONFIG(contractId)
    ).then(
      (response) => {
        if (response.result) dispatch(setContractPstoreConfig(response.result));
      },
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions, selector and reducers */

export const { setPeripheralStores, setPeripheralStoreStockList, setContractPstoreConfig } =
  peripheralStoresSlice.actions;

export const selectPeripheralStores = (state: RootState) => state.contract.peripheralStores.pstores;
export const selectPeripheralStoreStockList = (state: RootState) => state.contract.peripheralStores.pstoreStockList;
export const selectContractPstoreConfig = (state: RootState) => state.contract.peripheralStores.contractPstoreConfig;

const getPStoreStockHash = createSelector([selectPeripheralStoreStockList], (array) =>
  createHashFromArray<PeripheralStoreStockItem>(array || [], 'contractProductId')
);

export const getProductsForPeripheralStores = createSelector(
  [selectGroupProductList, getPStoreStockHash],
  (allProducts, groupHash) => {
    // BE: this might be better done on the BE?
    return allProducts.map((p) => ({ ...p, selected: !!groupHash[p.contractProductId] }));
  }
);

export const getPeripheralStoreSelectOptions = createSelector([selectPeripheralStores], (list) => {
  return list.map((item) => {
    return { label: item.name, value: item.id };
  });
});

export default peripheralStoresSlice.reducer;
