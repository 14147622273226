import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, ErrorResponse, IApiResponse, IsoDate, mapApiErrors, PartialBy } from 'millbrook-core';
import { PriceReviewDateFormData } from 'pages/Contract/KeyDates/PriceReviewDates/components/PriceReviewDateForm/PriceReviewDateForm';
import { deleteItem, getItems, postItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

// types
export interface PriceReviewDate {
  id: ApiId;
  contractId?: ApiId;
  reviewDate: IsoDate;
  wasApplied: boolean;
}

export type PriceReviewDateRequest = PartialBy<PriceReviewDate, 'id'>;

export type PriceReviewDatesResponse = IApiResponse<PriceReviewDate[]>;

interface PriceReviewDatesState {
  dates?: PriceReviewDate[];
}

const initialState: PriceReviewDatesState = {};

const contractPriceReviewDatesSlice = createSlice({
  name: 'priceReviewDates',
  initialState,
  reducers: {
    setPriceReviewDates(state, action: PayloadAction<PriceReviewDate[]>) {
      state.dates = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

export const { setPriceReviewDates } = contractPriceReviewDatesSlice.actions;

export const fetchPriceReviewDates =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<PriceReviewDatesResponse>(ENDPOINTS.CONTRACT.KEY_DATES.PRICE_REVIEW_DATES(contractId)).then(
      (response) => {
        dispatch(setPriceReviewDates(response.result || []));
      }
    );
  };

export const createPriceReviewDate =
  (priceReviewDate: PriceReviewDateFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return postItems<PriceReviewDateRequest, PriceReviewDatesResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.PRICE_REVIEW_DATES(contractId),
      { ...(priceReviewDate as any), contractId }
    ).then(
      () => {
        dispatch<any>(fetchPriceReviewDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const updatePriceReviewDate =
  (priceReviewDate: PriceReviewDateFormData, id: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<PriceReviewDateRequest, PriceReviewDatesResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.PRICE_REVIEW_DATES(),
      { ...(priceReviewDate as any), contractId, id },
      id
    ).then(
      () => {
        dispatch<any>(fetchPriceReviewDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

export const deletePriceReviewDate =
  (dateId: ApiId, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return deleteItem<ApiId, PriceReviewDatesResponse>(ENDPOINTS.CONTRACT.KEY_DATES.PRICE_REVIEW_DATES(), dateId).then(
      () => {
        dispatch<any>(fetchPriceReviewDates(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

// selectors
export const selectPriceReviewDates = (state: RootState) => state.contract.keyDates.priceReviewDates.dates;

export default contractPriceReviewDatesSlice.reducer;
