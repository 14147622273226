import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import EventIcon from '@material-ui/icons/Event';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import LoopOutlinedIcon from '@material-ui/icons/LoopOutlined';
import PersonPinOutlinedIcon from '@material-ui/icons/PersonPinOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import { ROUTES } from 'constants/routes';
import { ApiId, PointOfSaleIcon } from 'millbrook-core';
import { contractNavBudgetSteps } from './budgetPin.nav';
import { ContractSection } from './contractNav.types';
import { contractNavKeyDatesSteps } from './keyDates.nav';
import { contractNavPeripheralStoresSteps } from './peripheralStores.nav';
import { contractNavPrescriberPortalSteps } from './prescriberPortal.nav';
import { contractNavPrescriptionPortalSteps } from './prescriptionPortal.nav';
import { contractNavPricingSteps } from './pricing.nav';
import { contractNavRecycledSpecialProductsSteps } from './recycledSpecialProducts.nav';
import { contractNavServiceUserSteps } from './serviceUser.nav';

// this enum isn't (i.e. wasn't) coming from the BE. It is only front end.
// because this enum is different from the section nav steps (possibly coming from the BE), the values here must not match those coming from that enum
export enum ContractNavSection {
    KEYDATES = 'Key Dates',
    SCOPE = 'Scope',
    PRICING = 'Pricing',
    PRODUCTS = 'Products',
    PRESCRIBER_PORTAL = 'Prescriber portal',
    BUDGETS = 'Budget',
    PRESCRIPTION_PORTAL = 'Prescription portal',
    SERVICE_USER = 'SU',
    PERIPHERAL_STORES = 'Peripheral',
    RECYCLED_SPECIAL_PRODUCTS = 'RSP',
    AUDIT_HISTORY = 'Audit'
}

export const contractNavSectionIcons = {
    [ContractNavSection.KEYDATES]: EventIcon,
    [ContractNavSection.SCOPE]: WorkOutlineIcon,
    [ContractNavSection.PRICING]: PointOfSaleIcon,
    [ContractNavSection.PRODUCTS]: CategoryOutlinedIcon,
    [ContractNavSection.PRESCRIBER_PORTAL]: ExitToAppIcon,
    [ContractNavSection.BUDGETS]: PersonPinOutlinedIcon,
    [ContractNavSection.PRESCRIPTION_PORTAL]: PersonPinOutlinedIcon,
    [ContractNavSection.SERVICE_USER]: SupervisorAccountOutlinedIcon,
    [ContractNavSection.PERIPHERAL_STORES]: LocalMallOutlinedIcon,
    [ContractNavSection.RECYCLED_SPECIAL_PRODUCTS]: LoopOutlinedIcon,
    [ContractNavSection.AUDIT_HISTORY]: HistoryIcon
};

export const stepDisplayName = {
    [ContractNavSection.KEYDATES]: 'Key dates',
    [ContractNavSection.SCOPE]: 'Scope',
    [ContractNavSection.PRICING]: 'Pricing',
    [ContractNavSection.PRODUCTS]: 'Products',
    [ContractNavSection.PRESCRIBER_PORTAL]: 'Prescriber portal',
    [ContractNavSection.BUDGETS]: 'PIN/Budget',
    [ContractNavSection.PRESCRIPTION_PORTAL]: 'Prescription portal',
    [ContractNavSection.SERVICE_USER]: 'Service user',
    [ContractNavSection.PERIPHERAL_STORES]: 'Peripheral stores',
    [ContractNavSection.RECYCLED_SPECIAL_PRODUCTS]: 'Recycled special products',
    [ContractNavSection.AUDIT_HISTORY]: 'Audit history'
};

// Top level contract sections. This array represents the order rendered. There is no API lookup anymore, but keeping the setup the same
export const contractNavSectionSteps = (contractId: ApiId = ''): ContractSection[] => [
    {
        step: ContractNavSection.KEYDATES,
        name: stepDisplayName[ContractNavSection.KEYDATES],
        href: ROUTES.CONTRACT.keyDates.awardDate(contractId),
        iconColor: 'scienceBlue',
        subSections: contractNavKeyDatesSteps(contractId)
    },
    {
        step: ContractNavSection.SCOPE,
        name: stepDisplayName[ContractNavSection.SCOPE],
        href: ROUTES.CONTRACT.scope.contractServices(contractId),
        iconColor: 'curiousBlue'
        // scope subsection is dynamically generated in the contractNav slice using a extraReducer
    },
    {
        step: ContractNavSection.PRICING,
        name: stepDisplayName[ContractNavSection.PRICING],
        href: ROUTES.CONTRACT.pricing.serviceCharges(contractId),
        iconColor: 'mySin',
        subSections: contractNavPricingSteps(contractId)
    },
    {
        step: ContractNavSection.PRODUCTS,
        name: stepDisplayName[ContractNavSection.PRODUCTS],
        href: ROUTES.CONTRACT.products.catalogue(contractId, ''),
        iconColor: 'edward'
    },
    {
        step: ContractNavSection.PRESCRIBER_PORTAL,
        name: stepDisplayName[ContractNavSection.PRESCRIBER_PORTAL],
        href: ROUTES.CONTRACT.prescriberPortal.prescriberLogo(contractId),
        iconColor: 'hibiscus',
        subSections: contractNavPrescriberPortalSteps(contractId)
    },
    {
        step: ContractNavSection.BUDGETS,
        name: stepDisplayName[ContractNavSection.BUDGETS],
        href: ROUTES.CONTRACT.budgetPin.organisations(contractId),
        iconColor: 'jungleGreen',
        subSections: contractNavBudgetSteps(contractId)
    },
    {
        step: ContractNavSection.PRESCRIPTION_PORTAL,
        name: stepDisplayName[ContractNavSection.PRESCRIPTION_PORTAL],
        href: ROUTES.CONTRACT.prescriptionPortal.retailers(contractId),
        iconColor: 'edward',
        subSections: contractNavPrescriptionPortalSteps(contractId)
    },
    {
        step: ContractNavSection.SERVICE_USER,
        name: stepDisplayName[ContractNavSection.SERVICE_USER],
        href: ROUTES.CONTRACT.serviceUser.serviceUserGroups(contractId),
        iconColor: 'regalBlue',
        subSections: contractNavServiceUserSteps(contractId)
    },
    {
        step: ContractNavSection.PERIPHERAL_STORES,
        name: stepDisplayName[ContractNavSection.PERIPHERAL_STORES],
        href: ROUTES.CONTRACT.peripheralStores.stores(contractId),
        iconColor: 'edward170',
        subSections: contractNavPeripheralStoresSteps(contractId)
    },
    {
        step: ContractNavSection.RECYCLED_SPECIAL_PRODUCTS,
        name: stepDisplayName[ContractNavSection.RECYCLED_SPECIAL_PRODUCTS],
        href: ROUTES.CONTRACT.recycledSpecialProducts.holdTimes(contractId),
        iconColor: 'curiousBlue',
        subSections: contractNavRecycledSpecialProductsSteps(contractId)
    },
    {
        step: ContractNavSection.AUDIT_HISTORY,
        name: stepDisplayName[ContractNavSection.AUDIT_HISTORY],
        href: ROUTES.CONTRACT.auditHistory.auditHistory(contractId),
        iconColor: 'edward140'
    }
];
