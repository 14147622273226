import { ROUTES } from 'constants/routes';
import { ApiId } from 'millbrook-core';
import { ContractSection, ContractSectionEnum } from './contractNav.types';

const stepDisplayName = {
  [ContractSectionEnum.ContractAwardDate]: 'Contract award date',
  [ContractSectionEnum.ContractOperationalDates]: 'Contract operational dates',
  [ContractSectionEnum.ExtensionOptions]: 'Extension options',
  [ContractSectionEnum.PriceReviewDates]: 'Price review dates',
  [ContractSectionEnum.OperationDaysTime]: 'Operating days & time',
  [ContractSectionEnum.NonWorkingDays]: 'Non-working days',
  [ContractSectionEnum.MillbrookContactDetails]: 'Millbrook contact details'
};

const route = ROUTES.CONTRACT.keyDates;

// This is an object to make lookup easier. Assuming we will get an array from the API
export const contractNavKeyDatesSteps = (contractId: ApiId): ContractSection[] => [
  {
    step: ContractSectionEnum.ContractAwardDate,
    name: stepDisplayName[ContractSectionEnum.ContractAwardDate],
    href: route.awardDate(contractId)
  },
  {
    step: ContractSectionEnum.ContractOperationalDates,
    name: stepDisplayName[ContractSectionEnum.ContractOperationalDates],
    href: route.operationalDates(contractId)
  },
  {
    step: ContractSectionEnum.ExtensionOptions,
    name: stepDisplayName[ContractSectionEnum.ExtensionOptions],
    href: route.extensionDates(contractId)
  },
  {
    step: ContractSectionEnum.PriceReviewDates,
    name: stepDisplayName[ContractSectionEnum.PriceReviewDates],
    href: route.priceReviewDates(contractId)
  },
  {
    step: ContractSectionEnum.OperationDaysTime,
    name: stepDisplayName[ContractSectionEnum.OperationDaysTime],
    href: route.workingHours(contractId)
  },
  {
    step: ContractSectionEnum.NonWorkingDays,
    name: stepDisplayName[ContractSectionEnum.NonWorkingDays],
    href: route.nonWorkingDays(contractId)
  },
  {
    step: ContractSectionEnum.MillbrookContactDetails,
    name: stepDisplayName[ContractSectionEnum.MillbrookContactDetails],
    href: route.millbrookContact(contractId)
  }
];
