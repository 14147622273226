import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import { ApiId, IApiResponse, mapApiErrors } from 'millbrook-core';
import { deleteItems, getItems, postItems } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { clearContractState } from '../overview/overview.slice';

/* types */
export interface ContractCCG {
  id: ApiId; // this is the master id
  name: string;
  extension: string;
  addrLn1: string;
  addrLn2: string;
  addrLn3: string;
  addrLn4: string;
  town: string;
  county: string;
  postCode: string;
  country: string;
  contacts: ContractCCGContact[];
}

export interface ContractCCGContact {
  id: ApiId;
  nhsOrganisationId: ApiId;
  type: string;
  value: string;
}

export interface ContractCCGMap {
  contractId: ApiId;
  nhsOrganisationId: ApiId;
}

export interface ContractGPSearch {
  contractId: ApiId;
  outOfArea: boolean;
  name: string;
  extension: string;
  address: string;
  postCode: string;
}

export type ContractCCGResponse = IApiResponse<ContractCCG[]>;

/* state */
export interface ContractCCGsState {
  ccgs: ContractCCG[];
  unmappedCCGs: ContractCCG[];
  gpSearchResults: ContractCCG[];
}
const initialState: ContractCCGsState = { ccgs: [], unmappedCCGs: [], gpSearchResults: [] };

/* slice */
const contractContractCCGsSlice = createSlice({
  name: 'serviceUserAlerts',
  initialState,
  reducers: {
    setContractCCGs(state, action: PayloadAction<ContractCCG[]>) {
      state.ccgs = action.payload;
    },
    setContractCCGsUnmapped(state, action: PayloadAction<ContractCCG[]>) {
      state.unmappedCCGs = action.payload;
    },
    setGPSearchResults(state, action: PayloadAction<ContractCCG[]>) {
      state.gpSearchResults = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchContractCCGs =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ContractCCGResponse>(
      `${ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_CCGS}?contractId=${contractId}`
    ).then((response) => {
      dispatch(setContractCCGs(response.result || []));
    });
  };

export const fetchUnmappedContractCCGs =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<ContractCCGResponse>(
      `${ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_CCGS_UNMAPPED}?contractId=${contractId}`
    ).then((response) => {
      dispatch(setContractCCGsUnmapped(response.result || []));
    });
  };

export const createContractCCGMap =
  (map: ContractCCGMap): AppThunk =>
  async (dispatch, getState) => {
    return postItems<ContractCCGMap, ContractCCGResponse>(ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_CCGS, map, {
      enableGlobalErrorDialog: true
    })
      .then(() => {
        //dispatch(invalidateContractSearchResults());
      })
      .catch((response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      });
  };

export const fetchGPSearch =
  (
    contractId: ApiId,
    outOfArea: boolean,
    name?: string,
    extension?: string,
    address?: string,
    postCode?: string
  ): AppThunk =>
  async (dispatch, getState) => {
    var filters = ``;

    if (contractId) {
      filters += `contractId=${contractId}`;
    }

    if (outOfArea) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `outOfArea=${outOfArea}`;
    }

    if (name) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `name=${name}`;
    }

    if (extension) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `extension=${extension}`;
    }

    if (address) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `address=${address}`;
    }

    if (postCode) {
      if (filters.length > 0) {
        filters += `&`;
      }
      filters += `postCode=${postCode}`;
    }

    const url = `${ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_CCGS_GP_SEARCH}?` + filters;

    return getItems<ContractCCGResponse>(url).then((response) => {
      dispatch(setGPSearchResults(response.result || []));
    });
  };

export interface ActivityDeleteRequest {}

export const deleteContractCCGMap =
  (map: ContractCCGMap): AppThunk =>
  async (dispatch, getState) => {
    return deleteItems<ActivityDeleteRequest, null>(ENDPOINTS.CONTRACT.SERVICE_USER.CONTRACT_CCGS, map, {
      enableGlobalErrorDialog: true
    }).then(
      (response) => {},
      (response) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setContractCCGs, setContractCCGsUnmapped, setGPSearchResults } = contractContractCCGsSlice.actions;

/* selectors */
export const selectContractCCGsList = (state: RootState) => state.contract.serviceUser.defineGPsByCCG.ccgs;

export const selectContractCCGsUnmappedList = (state: RootState) =>
  state.contract.serviceUser.defineGPsByCCG.unmappedCCGs;

export const selectGpSearchResultsList = (state: RootState) =>
  state.contract.serviceUser.defineGPsByCCG.gpSearchResults;

/* reducers */
export default contractContractCCGsSlice.reducer;
