import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ENDPOINTS } from 'constants/api';
import {
  ApiId,
  DaysLabels,
  ErrorResponse,
  IApiResponse,
  ICheckboxOption,
  mapApiErrors,
  PartialBy,
  WorkingHoursModel
} from 'millbrook-core';
import { WorkingHoursFormData } from 'pages/Contract/KeyDates/WorkingHours/components/WorkingDaysForm/WorkingHoursForm.validation';
import { getItems, putItem } from 'services/api.service';
import { AppThunk, RootState } from 'store/store';
import { mapWorkingHoursFormToRequest } from '../mappers';
import { clearContractState } from '../overview/overview.slice';

/* types */

export type WorkingHourRequest = PartialBy<WorkingHoursModel, 'id'>;

export type WorkingHoursRequest = WorkingHourRequest[];
export type WorkingHoursResponse = IApiResponse<WorkingHoursModel[]>;

/* state */
interface WorkingHourState {
  days?: WorkingHoursModel[];
}

const initialState: WorkingHourState = {};

/* slice */
const contractWorkingHoursSlice = createSlice({
  name: 'WorkingHours',
  initialState,
  reducers: {
    setWorkingHours(state, action: PayloadAction<WorkingHoursModel[]>) {
      state.days = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(clearContractState, () => {
      return initialState;
    });
  }
});

/* thunks */
export const fetchWorkingHours =
  (contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return getItems<WorkingHoursResponse>(ENDPOINTS.CONTRACT.KEY_DATES.WORKING_HOURS(contractId)).then((response) => {
      dispatch(setWorkingHours(response.result || []));
    });
  };

export const updateWorkingHours =
  (workingHours: WorkingHoursFormData, contractId: ApiId): AppThunk =>
  async (dispatch, getState) => {
    return putItem<WorkingHoursRequest, WorkingHoursResponse>(
      ENDPOINTS.CONTRACT.KEY_DATES.WORKING_HOURS(contractId),
      mapWorkingHoursFormToRequest(workingHours, contractId)
    ).then(
      () => {
        dispatch<any>(fetchWorkingHours(contractId));
      },
      (response: ErrorResponse) => {
        const error = mapApiErrors(response);
        throw new Error(error);
      }
    );
  };

/* actions */
export const { setWorkingHours } = contractWorkingHoursSlice.actions;

/* selectors */
export const selectWorkingHours = (state: RootState) => state.contract.keyDates.workingHours.days;

export const selectEnabledWorkingDayOptions = createSelector([selectWorkingHours], (workingHours = []) => {
  return workingHours.reduce<ICheckboxOption[]>((enabledDays, day) => {
    if (day.isWorking) {
      enabledDays.push({ value: day.day, label: DaysLabels[day.day] });
    }

    return enabledDays;
  }, []);
});

/* reducers */
export default contractWorkingHoursSlice.reducer;
